import Moment from 'moment';
import { Row, Stack } from 'rsuite';

interface Props {
    candidate: any;
}

const CandidateCertificationHistory = ({ candidate }: Props) => {
    const certifications = candidate?.certifications

    if (certifications !== null) {
        return (<>
            {certifications.map((certification: any, index: number) => (
                <Row key={index}>
                  <div className="profile-section-card__contents" style={{marginLeft: '6px', marginBottom: '20px'}}>
                  <span className="fw-normal fs-6">{certification.title}</span><br/>
                    <Stack wrap spacing={10} direction='row' alignItems='center' justifyContent='space-between'>
                      <Stack.Item>
                        <span className="fw-light fs-6">{certification.organization}</span><br/>
                      </Stack.Item>
                      <Stack.Item>
                        <div className="fw-light">
                          {certification.issue_date && Moment(certification.issue_date).format('MMM Y')}
                        </div>
                      </Stack.Item>
                    </Stack>
                    {certification.location && (<span className="fw-light">{certification.location}</span>)}
                  </div>
                </Row>
              ))}            
        </>);
    } else {
        return (<>No known certifications</>)        
    }
};

export default CandidateCertificationHistory;
