import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { useNavigate } from "react-router";

import { JobCandidateListResponse , JobCandidateResponse } from "utils/types";
import CandidateAvatar from 'components/candidate/CandidateAvatar'

import {
  Input, InputGroup, Table, Checkbox, Stack, CellProps, Loader, Whisper, Popover, Dropdown, IconButton, Grid, Row, Col
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from '@rsuite/icons/legacy/More';
import { sortJsonObject, capitalizeAll } from 'utils/utils';

const { Column, HeaderCell, Cell } = Table;

type Props = {
  showMenu?: boolean
  enableScroll?: boolean
  filter?: string
  candidateCounts: { filtered: number, total: number }
  setCandidateCounts: ( jobCounts: any  ) => void
}

function ScheduledJobCandidateList( { showMenu, enableScroll, filter, candidateCounts, setCandidateCounts } : Props ) {
  if (enableScroll === undefined) {
    enableScroll = true
  }  
  if (showMenu === undefined) {
    showMenu = true
  }  
  if (filter === undefined) {
    filter = ''
  }
  const navigate = useNavigate();
  
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');

  const jobCandidateFetcher = useSWR<JobCandidateListResponse>(`/candidates/${filter}`, fetcher)
  const jobCandidateListResponse = jobCandidateFetcher.data as JobCandidateListResponse

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 300);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
  })

  if (!jobCandidateListResponse)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching candidates..."/>
      </div>
    );

  var jobCandidates: JobCandidateResponse [] = jobCandidateListResponse.results;
  
  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === jobCandidates.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < jobCandidates.length) {
    indeterminate = true;
  }

  function handleCheckAll(_value: any, checked: boolean) {
    const keys = checked ? jobCandidates.map(item => item.id) : [];
    setCheckedKeys(keys);
  };

  function handleCheck(value: any, checked: any) {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(keys);
  };

  function handleSortColumn(sortColumn: any, sortType: any) {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  const filteredData = () => {
    const filtered = jobCandidates.filter(jobCandidate => {
      if ((jobCandidate.candidate.location && !jobCandidate.candidate.location.toLowerCase().includes(searchKeyword.toLowerCase())) 
        && (jobCandidate.candidate.full_name && !jobCandidate.candidate.full_name.toLowerCase().includes(searchKeyword.toLowerCase()))) {
        return false;
      } else {
        return true;
      }
    });

    if (filtered.length != candidateCounts.filtered) {
      setCandidateCounts( { filtered: filtered.length, total: jobCandidates.length } )
    }
    return sortJsonObject(filtered, sortColumn, sortType)
  };

  return (
    <>
      {showMenu && (
      <Stack justifyContent="flex-start" spacing={10} style={{paddingBottom: '10px'}}>
        <InputGroup inside>
          <Input placeholder="Search" value={searchKeyword} onChange={setSearchKeyword} />
          <InputGroup.Addon>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>
      </Stack>
      )}

      <Table 
        wordWrap="break-word"
        height={windowHeight - 300}
        data={filteredData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        bordered
        autoHeight={!enableScroll}
        rowHeight={45}
        style={{fontSize: '14px'}}   
      >
        <Column width={50} fixed>
          <HeaderCell style={{ padding: 0 }}>
              <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
          </HeaderCell>
          <Cell dataKey="id" style={{ padding: 0 }}>{(rowData: any) => (
             <Checkbox
              value={rowData.id}
              inline
              onChange={handleCheck}
              checked={checkedKeys.some(item => item === rowData.id)}
              style={{height: '10px'}}
              />
          )}
          </Cell>
        </Column>

        <Column minWidth={100} flexGrow={2} sortable>
          <HeaderCell>Name</HeaderCell>
          <NameCell dataKey="full_name" />
        </Column>

        <Column width={50} align="center">
          <HeaderCell> </HeaderCell>
          <Cell dataKey='image'>{(rowData: any) => (
            <div style={{height: '10px'}}>
              <CandidateAvatar size="md" candidate={rowData.candidate}/>
            </div>              
            )}
          </Cell>
        </Column>  

        <Column minWidth={50} sortable>
          <HeaderCell>Country</HeaderCell>
          <Cell dataKey="candidate.geo_location.country_iso"/>
        </Column>
        
        <Column minWidth={50} sortable>
          <HeaderCell>State</HeaderCell>
          <Cell dataKey="candidate.geo_location.state_iso"></Cell>
        </Column>
        
        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>City</HeaderCell>
          <Cell dataKey="candidate.geo_location.city"></Cell>
        </Column>

        <Column minWidth={150} sortable flexGrow={1}>
          <HeaderCell>Meeting Status</HeaderCell>
          <StatusCell dataKey="calendly_candidate_meeting.status" />
        </Column>       

        <Column minWidth={150} sortable flexGrow={1}>
          <HeaderCell>Meeting Start</HeaderCell>
          <Cell dataKey="calendly_candidate_meeting.start_date">{(rowData: any) => `${new Date(rowData.calendly_candidate_meeting.start_time).toLocaleString()}`}</Cell>
        </Column>       

        <Column minWidth={120} flexGrow={1}>
          <HeaderCell>
            <MoreIcon />
          </HeaderCell>
          <ActionCell />
        </Column>
      </Table>
    </>
  );
};

export default ScheduledJobCandidateList;

const NameCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate()

  const speaker = (
    <Popover title="Candidate">
      <Grid style={{width: '400px'}}>
        <Row>
          <Col lg={4}>Role:</Col>
          <Col lg={20}>{rowData.candidate.role}</Col>
        </Row>        
        <Row>
          <Col lg={4}>Degree:</Col>
          <Col lg={20}>{rowData.candidate.degree}</Col>
        </Row>       
        <Row>
          <Col lg={4}>Company:</Col>
          <Col lg={20}>{rowData.candidate.company}</Col>
        </Row>        
        <Row>
          <Col lg={4}>Summary:</Col>
          <Col lg={20}>{rowData.candidate.summary}</Col>
        </Row>     
      </Grid>   
    </Popover>
  );

  return (
    <Cell {...props}>
      <div  onClick={() => {navigate(`/jobs/${rowData.job_id}/candidates/${rowData.candidate.id}/?tab=${rowData.state}`)}}>
        <Whisper placement="top" speaker={speaker}>
          <a>{rowData.candidate.full_name}</a>
        </Whisper>
      </div>
    </Cell>
  );
};  

const StatusCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate();
  
  var speaker = (<Popover title="Click to go to related job"/>)

  return (
    <Cell {...props}>
      <div onClick={() => {navigate(`/jobs/${rowData.job_id}?tab=${rowData.state}#${rowData.candidate.id}`)}}>
        <Whisper placement="top" speaker={speaker}>
          <a>{capitalizeAll(rowData.calendly_candidate_meeting.status)}</a>
        </Whisper>
      </div>
    </Cell>
  );
};  

const ActionCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate();

  function handleSelect(eventKey: any) {
    switch (eventKey) {
      case 'candidate':
        navigate(`/jobs/${rowData.job_id}/candidates/${rowData.candidate.id}/?tab=${rowData.state}`);
        break;
      case 'job':
        navigate(`/jobs/${rowData.job_id}?tab=${rowData.state}#${rowData.candidate.id}`)
      }
  };
    
  return (
    <Cell {...props} className="link-group">
      <div style={{height: '10px'}}>
      <Whisper placement="autoVerticalEnd" trigger="click" speaker={
          <Popover full>
            <Dropdown.Menu onSelect={handleSelect}>
              <Dropdown.Item eventKey='job'>Related Job</Dropdown.Item>
              <Dropdown.Item eventKey='candidate'>Candidate Details</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
      }>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
      </div>
    </Cell>
  );
};