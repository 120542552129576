import React, { useEffect } from "react";
import { useParams, useNavigate } from 'react-router';
import {useSelector} from "react-redux";
import {RootState} from "store";

const Candidate = () => {
  const params = useParams()
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    navigate(`/jobs/${user?.user_id}/candidates/${params.candidateId}`)
  })
  return <></>
};

export default Candidate;