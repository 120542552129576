import React, { useState } from "react";

import { Modal, Button, Rate, Form } from 'rsuite';
import TextArea from 'components/elements/Textarea'
import { postRequest } from "utils/axios";

interface Props {
    open: boolean
    handleClose: () => void
}

export function FeedbackModal({ open, handleClose }: Props) {
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");

    const handleSubmit = () => {
        const feedbackData = {
            rating,
            feedback,
            url: window.location.href
        };
        postRequest(`/feedback/`, feedbackData).finally(() => {
            handleClose();
          })        
    };
  
    return (
      <Modal size="xs"
        onEnter={() => setRating(0)}
        open={open}
        onClose={handleClose}>
        <Modal.Header>
          <Modal.Title><strong>Feedback</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Rate onChange={setRating} value={rating} color="yellow"/>
            </Form.Group>
            <Form.Group>
              <Form.Control
                maxLength={5000}
                accepter={TextArea}
                rows={5}
                name="textarea"
                placeholder="We'd love to hear from you! Share your thoughts on what you love and what could be improved. Your constructive feedback helps us make TalentWell even better for you!"
                onChange={setFeedback}
              />
            </Form.Group>
            <p><strong>Reference URL this feedback may apply to:</strong></p>
            <p style={{ fontSize: "10px", border: 'none', background: 'transparent', color: '#888' }}>{window.location.href}</p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} appearance="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  