import Moment from 'moment';
import { Row, Stack } from 'rsuite';
import { timeSpentAtPlace } from 'utils/utils';

interface Props {
    candidate: any;
}

const CandidateEducationHistory = ({ candidate }: Props) => {
    const educations = candidate?.educations

    if (educations !== null) {
        return (<>
            {educations.map((education: any, index: number) => (
                <Row key={index}>
                  <div className="profile-section-card__contents" style={{marginLeft: '6px', marginBottom: '20px'}}>
                    <span className="fw-normal fs-6">{education.degree}</span><br/>
                    <Stack wrap spacing={10} direction='row' alignItems='center' justifyContent='space-between'>
                      <Stack.Item>
                        {education.linkedin_url ? (
                          <a className="fw-light fs-6" href={education.linkedin_url} target="_blank">{education.institution ? education.institution : 'Institution'}</a>
                        ) : (
                          <span className="fw-light fs-6">{education.institution ? education.institution : 'Institution'}</span>
                        )}
                        <br/>
                      </Stack.Item>
                      <Stack.Item>
                        <div className="fw-light">
                          {education.start_date && Moment(education.start_date).format('MMM Y') + ' - '}{education.end_date ? Moment(education.end_date).format('MMM Y') : "Present"} <span>&middot; {timeSpentAtPlace(education.start_date, education.end_date)}</span>
                        </div>
                      </Stack.Item>
                    </Stack>
                    {education.location && (<span className="fw-light">{education.location}</span>)}
                  </div>
                </Row>
              ))}
        </>);
    } else {
        return (<>No known educations</>)        
    }
};

export default CandidateEducationHistory;
