import React, { useState, useEffect }  from "react";
import { useParams, useNavigate } from 'react-router';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { Panel, Breadcrumb, Stack, Button, Loader, useToaster } from 'rsuite';

import { patchRequest } from "utils/axios";
import JobEditContent from './JobEditContent'
import { JobResponse } from 'utils/types'

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

const JobEdit = () => {
  const params = useParams()
  const navigate = useNavigate()
  const toaster = useToaster();  
  const [jobStatus, setJobStatus] = useState('open');

  const { data: job, trigger } = useSWRMutation<JobResponse>(`/jobs/${params.jobId}/`, fetcher)
  
  useEffect(() => {
    if (job === undefined)
      trigger()
    else
      setJobStatus(job.status)
  })

  function changeJobStatus(status: any, jobId: any) {
    const jobUpdateRequest = {
      status: status
    };

    let text_status: any = null
    if (status === 'closed')
      text_status = 'closed'
    else
      text_status = 'opened'

    const patchPromise = patchRequest(`/jobs/${jobId}/`, jobUpdateRequest)      
    patchPromise.then((response) => {
      toaster.push( <RequestSuccessMessage message={'Job successfully ' + text_status} toaster={toaster}/> )
      setJobStatus(status)
      if (status === 'closed')
        navigate('/jobs')
      else
        trigger()
    }).catch((error) => {
      toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })
  }   

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <Stack.Item>
          <h3 className="title">Job Edit {job ? '- ' + job.name : ''} {job?.status === 'closed' && '(Closed)'}</h3>
        </Stack.Item>    
        <Stack.Item>
          <Stack spacing={10} direction='row' justifyContent="space-between">
          
          <Button appearance="primary" onClick={() => {navigate('/jobs/' + params.jobId)}} >Back to Job Search</Button>
          {jobStatus === 'closed' && <Button appearance="primary" onClick={() => {changeJobStatus('open', params.jobId)}} >Reopen Job</Button>}          
          {jobStatus === 'open' && <Button appearance="primary" onClick={() => {changeJobStatus('closed', params.jobId)}} color='red'>Close Job</Button>}
          </Stack>
        </Stack.Item>                
      </Stack>

      <Breadcrumb style={{color: '#1675e0'}}>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/jobs')}}>Jobs</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/jobs/' + params.jobId)}}>Job</Breadcrumb.Item>
      </Breadcrumb>
    </>}>
    </Panel>

    <Panel className='body-panel' shaded>
      {job !== undefined ? <JobEditContent readOnly={jobStatus !== 'open'} job={job}/> : <div className='text-center m-3'><Loader size="md" content="Fetching job details..."/></div>}
    </Panel>
</>)};

export default JobEdit;