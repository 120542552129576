import React, { useState, useEffect } from "react";

import { AccessTrackerEvent } from 'utils/types'
import { trackCandidate } from 'utils/utils'

type props = {
  candidateId: string
  update: boolean  
  setUpdate: (updated: boolean) => void
}

const CandidateAccessTrackerLongView = ({ candidateId, update, setUpdate }: props) => {
  const [initialized, setInitialized] = useState(false);
  const [accessTrackerEvent, setAccessTrackerEvent] = useState<AccessTrackerEvent>()

  useEffect(() => {
    if (candidateId && (!initialized || (initialized && update))) {
      setUpdate(false)
      setInitialized(true)
      const patchPromise = trackCandidate(candidateId) 
      patchPromise.then((response) => {
        if (response.status < 300) {
          setAccessTrackerEvent(response.data)
        }
      })
    }
  })

  if (accessTrackerEvent) {
    if (accessTrackerEvent.previous_access_time) {
      return <div style={{fontSize: '12px'}}>Last viewed on {new Date(accessTrackerEvent.previous_access_time).toLocaleString()}. Viewed {accessTrackerEvent.access_count} times since {new Date(accessTrackerEvent.created).toLocaleString()}.</div>
    } else {
      return <div style={{fontSize: '12px'}}>Last viewed on {new Date(accessTrackerEvent.created).toLocaleString()}. Viewed {accessTrackerEvent.access_count} times since {new Date(accessTrackerEvent.created).toLocaleString()}.</div>
    }
  } else {
    return <></>
  }
}
export default CandidateAccessTrackerLongView;