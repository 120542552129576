import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { patchRequest, postRequest } from "utils/axios";

import { Panel, Breadcrumb, Stack, Loader, Button, useToaster } from 'rsuite';

import JobContent from './JobContent'
import JobAccessTrackerLongView from 'components/tracker/JobAccessTrackerLongView'

import { JobResponse } from 'utils/types'

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

import DripCampaignDrawerView from 'components/campaign/DripCampaignDrawerView'

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import sideMenuSlice from "store/slices/sidemenu";

const Job = () => {
  const params = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [jobReset, setJobReset] = useState(false);

  const sideMenu = useSelector((state: RootState) => state.sideMenu);
  
  const { data: job, trigger } = useSWRMutation<JobResponse>(`/jobs/${params.jobId}/`, fetcher)
  const user = useSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    if (job === undefined)
      trigger()
      dispatch(sideMenuSlice.actions.setActiveItem('/jobs'));
  })

  if (job === undefined) {
    return <Panel className='body-panel' shaded><div className='text-center m-3'><Loader size="md" content="Fetching job details..."/></div></Panel>
  }

  function openDrawer() {
    setDrawerOpen(true)
  }

  function closeDrawer() {
    setDrawerOpen(false)
  }  

  function changeJobStatus(status: any, jobId: any) {
    const jobUpdateRequest = {
      status: status
    };

    let text_status: any = null
    if (status === 'closed')
      text_status = 'closed'
    else
      text_status = 'opened'

    const patchPromise = patchRequest(`/jobs/${jobId}/`, jobUpdateRequest)      
    patchPromise.then((response) => {
      toaster.push( <RequestSuccessMessage message={'Job successfully ' + text_status} toaster={toaster}/> )
      if (status === 'closed')
        navigate('/jobs')
        trigger()
    }).catch((error) => {
      toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })
  } 

  function resetJob() {
    postRequest(`/jobs/${job?.id}/chat-messages/reset/`).then(
      (response) => {
        setJobReset(true)
    }).catch((error) => {
      toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })
  }

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <Stack.Item>
          {job?.is_search_job ?
            <h3 className="title">Search</h3>
            :
            <h3 className="title">Job {job ? '- ' + job.name : ''} {job?.status === 'closed' && '(Closed)'}</h3>
          }
        </Stack.Item>
        <Stack.Item>
          <Stack spacing={10} direction='row' justifyContent="space-between">
            {(job?.status === 'open' || job?.status === 'hidden') && <Button appearance="primary" onClick={openDrawer}>Manage Campaign</Button>}
            {job?.status === 'open' && <Button appearance="primary" onClick={() => {navigate('/jobs/' + params.jobId + '/edit/')}}>Edit Job Details</Button>}
            {job?.status === 'closed' && <Button appearance="primary" onClick={() => {navigate('/jobs/' + params.jobId + '/edit/')}}>View Job Details</Button>}
            {job?.status === 'closed' && <Button appearance="primary" onClick={() => {changeJobStatus('open', params.jobId)}}>Reopen Job</Button>}
            {user?.is_superuser && <Button appearance="primary" onClick={resetJob} color='red'>Reset</Button>}
          </Stack>
        </Stack.Item>
      </Stack>

      <Stack direction='row' justifyContent="space-between">
      {job?.is_search_job ? 
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
        :
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/jobs')}}>Jobs</Breadcrumb.Item>
          <Breadcrumb.Item active>Job</Breadcrumb.Item>
        </Breadcrumb>
      }
      {job?.status === 'open' && <JobAccessTrackerLongView jobId={job?.id}/>}
      </Stack>
    </>}>
    </Panel>
      <JobContent job={job} jobReset={jobReset} setJobReset={setJobReset}/> 
      
      <DripCampaignDrawerView job={job} open={drawerOpen} onClose={closeDrawer} />
</>)};

export default Job;