import React, { useRef, useState, useEffect } from 'react';

import { patchRequest, postRequest, getRequest } from "utils/axios";

import { Form, Loader, useToaster, Schema, Stack, Button } from 'rsuite';
import Textarea from 'components/elements/Textarea';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

type Props = {
  businessOnBehalfId: string | undefined
}

const BusinessOnBehalfEditContent = ( { businessOnBehalfId } : Props ) => {
  if (businessOnBehalfId === 'add') {
    businessOnBehalfId = undefined
  } 

  const toaster = useToaster();
  const formRef: any = useRef()

  const [initialized, setInitialized] = useState(false);
  const [formError, setFormError] = useState({});    

  const [formValue, setFormValue] = useState({
    name: '',
    name_short: '',
    description: '',
    type: '',
    industry: '',
    address: '',
    country_iso: '',
    state_iso: '',
    city: '',
    postal: '',
    website: '',
    news_url: '',
    blog_url: '',
    medium: '',
    twitter: '',
    facebook: '',
    linkedin: '',
    contact_name: '',
    contact_email: '',
    contact_phone: ''
  })  

  useEffect(() => {
    if (businessOnBehalfId && !initialized) {
      getRequest('/businesses/onbehalfs/' + businessOnBehalfId + '/').then((res) => {
        setFormValue(res.data)
      })
    }
    setInitialized(true)
  })  

  if (businessOnBehalfId && !initialized)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching company details..."/>
      </div>
    );

  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    name: StringType().isRequired("This field is required"),
    name_short: StringType().isRequired("This field is required"),
    description: StringType().isRequired("This field is required"),
    contact_email: StringType().isEmail(),
    website: StringType().isURL(),
    news_url: StringType().isURL(),
    blog_url: StringType().isURL(),
    medium: StringType().isURL(),
    twitter: StringType().isURL(),
    facebook: StringType().isURL(),
    linkedin: StringType().isURL()
  })     

  const handleSubmit = (event: any) => {
    if (formRef.current.check()) {
      const businessUpdateRequest = {
        name: formValue.name,
        name_short: formValue.name_short,
        description: formValue.description,
        type: formValue.type,
        industry: formValue.industry,
        address: formValue.address,
        country_iso: formValue.country_iso,
        state_iso: formValue.state_iso,
        city: formValue.city,
        postal: formValue.postal,
        website: formValue.website,
        news_url: formValue.news_url,
        blog_url: formValue.blog_url,
        medium: formValue.medium,
        twitter: formValue.twitter,
        facebook: formValue.facebook,
        linkedin: formValue.linkedin,
        contact_name: formValue.contact_name,
        contact_email: formValue.contact_email,
        contact_phone: formValue.contact_phone
      };
  
      if (businessOnBehalfId) {
        const patchPromise = patchRequest(`/businesses/onbehalfs/${businessOnBehalfId}/`, businessUpdateRequest)      
        patchPromise.then((response) => {
          toaster.push( <RequestSuccessMessage message='Company successfully updated' toaster={toaster}/> )
        }).catch((error) => {
          toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
        })
      } else {
        const postPromise = postRequest(`/businesses/onbehalfs/`, businessUpdateRequest)      
        postPromise.then((response) => {
          businessOnBehalfId = response.data.id
          toaster.push( <RequestSuccessMessage message='Company successfully created' toaster={toaster}/> )
        }).catch((error) => {
          toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
        })
      }
    }
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      name: record.name,
      name_short: record.name_short,
      description: record.description,
      type: record.type,
      industry: record.industry,
      address: record.address,
      country_iso: record.country_iso,
      state_iso: record.state_iso,
      city: record.city,
      postal: record.postal,
      website: record.website,
      news_url: record.news_url,
      blog_url: record.blog_url,
      medium: record.medium,
      twitter: record.twitter,
      facebook: record.facebook,
      linkedin: record.linkedin,
      contact_name: record.contact_name,
      contact_email: record.contact_email,
      contact_phone: record.contact_phone
    })
  }  

  const formFieldSize = 400

  return (
    <Form style={{width: '100%'}}
        ref={formRef}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}    
        fluid>
      <Stack spacing={10} direction='column' alignItems='flex-end' justify-content='flex-end'>
        {businessOnBehalfId ? 
          <Button appearance="primary" type='submit'>Update Company</Button>
          :
          <Button appearance="primary" type='submit'>Create Company</Button>
        }
      </Stack>          

      <Form.Group controlId="name">
        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='flex-end' wrap={true}>
         <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Name<sup>*</sup></Form.ControlLabel>
            <Form.Control name="name" />
            <Form.HelpText>
            The official company name.
            </Form.HelpText>
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Short Name<sup>*</sup></Form.ControlLabel>
            <Form.Control name="name_short" />
            <Form.HelpText>
            The company’s Doing Business As (DBA) name that candidates  will see in email communications.
            </Form.HelpText>
          </Stack.Item>
        </Stack>          

        <Form.ControlLabel>Description<sup>*</sup></Form.ControlLabel>
        <Form.Control name="description" rows={5} accepter={Textarea} />
        <Form.HelpText>
        Enter a concise overview of the company for potential candidates.
        </Form.HelpText>
      </Form.Group>

      <Form.Group controlId="type">
        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='space-between' wrap={true}>
          <Stack.Item alignSelf='stretch' basis={200}>
            <Form.ControlLabel>Type of Business</Form.ControlLabel>
            <Form.Control name="type" />
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={200}>
            <Form.ControlLabel>Industry</Form.ControlLabel>
            <Form.Control name="industry" />
          </Stack.Item>
        </Stack>
      </Form.Group>

      <Form.Group controlId="news">
        <h4>Social Media</h4>
        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='space-between' wrap={true}>    
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Website</Form.ControlLabel>
            <Form.Control name="website" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>News</Form.ControlLabel>
            <Form.Control name="news_url" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Blog</Form.ControlLabel>
            <Form.Control name="blog_url" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Medium</Form.ControlLabel>
            <Form.Control name="medium" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>X (Twitter)</Form.ControlLabel>
            <Form.Control name="twitter" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Facebook</Form.ControlLabel>
            <Form.Control name="facebook" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>LinkedIn</Form.ControlLabel>
            <Form.Control name="linkedin" />
          </Stack.Item>    
        </Stack>
      </Form.Group>

      <Form.Group controlId="address">
        <h4>Address</h4>
        <Form.ControlLabel>Street</Form.ControlLabel>
        <Form.Control name="address" />

        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='space-between' wrap={true}> 
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>City</Form.ControlLabel>
            <Form.Control name="city" />
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>State</Form.ControlLabel>
            <Form.Control name="state_iso" />
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Country</Form.ControlLabel>
            <Form.Control name="country_iso" />
          </Stack.Item>
        </Stack>
      </Form.Group>      

      <Form.Group controlId="contact">
        <h4>Contact</h4>
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="contact_name" />

        <Form.ControlLabel>Phone</Form.ControlLabel>
        <Form.Control name="contact_phone" />

        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="contact_email" />
      </Form.Group>
     
      <Stack spacing={10} direction='column' alignItems='flex-end' justify-content='flex-end'>
        {businessOnBehalfId ? 
          <Button appearance="primary" type='submit'>Update Company</Button>
          :
          <Button appearance="primary" type='submit'>Create Company</Button>
        }
      </Stack>          
    </Form>
  );
};

export default BusinessOnBehalfEditContent;
