import React from "react";

import 'styles/styles.css';

import { IconButton } from 'rsuite';

import ArrowUp from '@rsuite/icons/ArrowUpLine';
import ArrowDown from '@rsuite/icons/ArrowDownLine';

export const ScrollTopButton = () => {
  return (
    <div className="scroll_to_top" >
      <IconButton icon={<ArrowUp />}href='#top'/>
    </div>
)};

export const ScrollBottomButton = () => {
    return (
      <div className="scroll_to_bottom" >
        <IconButton icon={<ArrowDown/>}href='#bottom'/>
      </div>
)};