import React, { useEffect, useState } from "react";
import authSlice from "store/slices/auth";

import { useSearchParams } from "react-router-dom";
import { postRequest, getRequest } from "utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";

import { Modal, useToaster, Message } from 'rsuite';
import SubscriptionOptions from "./SubscriptionOptions";

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import { setSessionData, getSessionData, removeSessionData } from "utils/utils";


type props = {
  subscriptionModalOpen: boolean
  setSubscriptionModalOpen: (value: boolean) => void
}

export function SubscriptionModal({ subscriptionModalOpen, setSubscriptionModalOpen }: props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const toaster = useToaster();
    const user = useSelector((state: RootState) => state.auth.user)
    const user_url = `/users/${user?.id}/`

    const message = (
      <Message showIcon type="info" closable>
        Congratulations! We successfully processed your payment.
      </Message>
    );

    useEffect(() => {
      if (searchParams.has('success') && searchParams.has('session_id')) {
        if (searchParams.get('success') === 'true') {
            setSearchParams('');
            postRequest("integrations/stripe/subscriptions/", {
                session_id: searchParams.get('session_id')
            })
            .then((res) => {
              toaster.push(message, { placement: "topCenter", duration: 5000 })
            })
            .catch((error) => {
                toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
            }).finally(() => {
              removeSessionData('subscription_options_shown');
              getRequest(user_url).then((res) => {
                dispatch(authSlice.actions.setUser(res.data));
              })
            })
        }
      }

      const subscription_options_shown = getSessionData('subscription_options_shown');
      if ((!subscription_options_shown && user && user.product_subscription_name === 'free')) {
        setSubscriptionModalOpen(true);
      } else if (subscriptionModalOpen === true) {
          setSubscriptionModalOpen(false);
      }
  })    

  const handleModalClose = () => {
    setSessionData('subscription_options_shown', true)
    setSubscriptionModalOpen(false)
  }

    return (
      <Modal size="lg"
        open={subscriptionModalOpen}
        onClose={handleModalClose}
        onExit={handleModalClose}>
        <Modal.Header>
          <strong>Upgrade below or continue on a Free plan (Only one job per month; no custom avatar).</strong>
        </Modal.Header>
        <Modal.Body className="no-scroll">
          <div style={{ transform: 'scale(0.85)', transformOrigin: 'center top' }}>
            <SubscriptionOptions isSignedIn={true}/>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  