import React, { useRef, useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import { getRequest } from "utils/axios";
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { RootState } from "store";

import Image from 'react-bootstrap/Image';

import { Dropdown, Whisper, Avatar, IconButton, WhisperInstance, Popover, Stack, Button } from 'rsuite';

import HelpIcon from '@rsuite/icons/legacy/HelpO';

import {
  MDBNavbarBrand
} from 'mdb-react-ui-kit';

import Logo from "assets/images/login_talentwell_icon_with_white_text_large.png";
import LogoGreenTestMode from "assets/images/login_talentwell_icon_with_green_text_large.png";
import HeaderBackground from "assets/images/header_background.png";
import FeedbackButton from "assets/images/feedback-button.png";

import { setSessionData } from "utils/utils";
import { SubscriptionModal } from 'components/tiers/SubscriptionModal';

import { User } from 'types';
import { FeedbackModal } from "components/workspace/FeedbackModal";
import { notInitialized } from "react-redux/es/utils/useSyncExternalStore";

const AltHeader = () => {
  const trigger = useRef<WhisperInstance>(null);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [continuousUpdateEnabled, setContinuousUpdateEnabled] = useState<any>(null);

  const login_user = useSelector((state: RootState) => state.auth.user);
  const { data, error, mutate } = useSWR<any>(`/users/${login_user?.id}/`, fetcher)
  const user: User = data

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!profileImage && user?.image) {
      setProfileImage(user.image);
    }

    // Call user and configuration updates every 60 seconds (60000 milliseconds)
    if (!continuousUpdateEnabled && !initialized) {
      setInitialized(true);
      console.log('Setting interval to update user and configuration...');
      setContinuousUpdateEnabled(setInterval(updateUserAndConfiguration, 10 * 60000))
    }
  }, [user]);

  function handleNavigation(event: any) {
    navigate(event.currentTarget.getAttribute('value'));
  }

  function updateUserAndConfiguration(): void {
    console.log('Updating user and configuration for user...');
    if (login_user && login_user.id) {
      getRequest(`/users/${login_user?.id}/`).then((res) => {
        dispatch(authSlice.actions.setUser(res.data));
      }).catch((error) => {
        if (error.response.status === 401) {
          console.log('User is not authenticated. Clearing interval...');
          clearInterval(continuousUpdateEnabled);
        }
      })
      getRequest('/system/configurations/ui/').then((response: any) => {
        dispatch(configurationSlice.actions.setConfiguration(response.data));
      })   
    }
  }

  const renderAdminSpeaker = ({ onClose, left, top, className }: any, ref: any) => {
    function handleSelect(eventKey: any) {
      switch (eventKey) {
        case 'profile':
          onClose();
          navigate('/profile');
          break;
        case 'signout':
          dispatch(authSlice.actions.setLogout());
          navigate('/signin');
          break;
      }
    };

    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item panel style={{ padding: 10, width: 270 }}>
            <p>Signed in as: <strong>{user?.full_name}</strong></p>

            {user?.last_signin_time ?
              (<p>Last sign in:<strong> {new Date(user?.last_signin_time).toLocaleString()}</strong></p>)
              :
              (<p>Never signed in before</p>)
            }
          </Dropdown.Item>
          <Dropdown.Item divider />
          <Dropdown.Item eventKey='profile'>Profile</Dropdown.Item>
          <Dropdown.Item divider />
          <Dropdown.Item eventKey='signout'>Sign Out</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  function upgradeSubscription() {
    setSessionData('subscription_options_shown', false)
    setSubscriptionModalOpen(true)
  }    

  return (
      <header style={{ display: 'flex', backgroundPosition: '50% 50%', backgroundSize: 'cover', backgroundImage: `url(${HeaderBackground})`, padding: '5px 5px 5px 0px' }}>
        <Stack spacing={10} direction='row' alignItems='center' justifyContent='space-between' style={{ width: '100%' }}>
          <Stack.Item alignSelf="center" style={{ flexBasis: '20%' }}>
            <MDBNavbarBrand value="/" onClick={handleNavigation}>
              {user && user.is_live ?
                <Image style={{ width: "300px" }} src={Logo} loading="lazy" />
              :
                <Image style={{ width: "300px" }} src={LogoGreenTestMode} loading="lazy" />
              }
            </MDBNavbarBrand>
          </Stack.Item>

          {user && !user.email_provider ?
            <Stack.Item alignSelf="center" style={{ flexGrow: 1, textAlign: 'center' }}>
              <span style={{ color: "yellow", padding: "10px", fontSize: "16px", fontWeight: "bold" }}>
                <span>No emails will be sent to any candidates until you connect your email provider with our platform: </span>
                <Button appearance="primary" style={{boxShadow: "0 0 8px #007CFF", fontSize: "12px", width: "98px", height: "25px",  backgroundColor: "yellow", color: "black" }} onClick={() => navigate('/profile')}>
                  Go to Profile
                </Button>
              </span>
            </Stack.Item>
            :
            <Stack.Item alignSelf="center" style={{ flexGrow: 1, textAlign: 'center' }}></Stack.Item>
          }

          {user && user.product_subscription_name === 'free' &&
          <Stack.Item>
            <Button onClick={upgradeSubscription} style={{boxShadow: "0 0 8px #007CFF", fontSize: "12px", width: "98px", height: "25px", backgroundColor: "#007BFF"}} appearance='primary'><strong>UPGRADE NOW</strong></Button>
            <SubscriptionModal subscriptionModalOpen={subscriptionModalOpen} setSubscriptionModalOpen={setSubscriptionModalOpen} />
          </Stack.Item>
      }
          
          <Stack.Item>
            <IconButton
              onClick={() => navigate('/help')}
              style={{
                boxShadow: "6 3 8px #007CFF",
                width: "25px", // Set the width to match the height for a square button
                height: "25px", // Set height for the square shape
                backgroundColor: "#007BFF",
                color: "white",
                padding: 0, // Ensures no extra padding around the icon
                display: "flex", // Aligns the icon properly within the button
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1" // Ensures the icon aligns well vertically
              }}
              appearance="link"
              icon={<HelpIcon style={{ fontSize: "16px" }} />} // Adjust the size of the icon here
            />
          </Stack.Item>

          <Stack.Item>
            <IconButton onClick={() => setFeedbackModalOpen(true)} style={{width: "98px", height: "25px", background: "white", color: "white"}}
                appearance='link'
                icon={ <Image style={{ width: "100px" }} src={FeedbackButton} loading="lazy" />}
              />
            <FeedbackModal open={feedbackModalOpen} handleClose={() => setFeedbackModalOpen(false)}/>
          </Stack.Item>

          <Stack.Item alignSelf="center" style={{ display: 'flex', alignItems: 'center' }}>
            <Whisper placement="bottomEnd" trigger="click" ref={trigger} speaker={renderAdminSpeaker}>
              <IconButton
                appearance='link'
                icon={
                  <Avatar
                    size="md"
                    circle
                    src={profileImage}
                    style={{ marginLeft: 20 }}
                  />
                }
              />
            </Whisper>
          </Stack.Item>
        </Stack>

      </header>
  );
};

export default AltHeader;