import React, { useEffect } from "react";
import { useLocation } from 'react-router';

import { Loader, Row } from 'rsuite';

import SelectableCandidateCard from "components/candidate/SelectableCandidateCard";

type Props = {
  jobId: string
  activeTab: string
  loading: boolean
  searchParams: URLSearchParams
  jobCandidates: any
  setActiveTab: (activeTab: string) => void
  likeCandidate: (candidate_id: string) => void
  hideCandidate: (candidate_id: string) => void  
  contactCandidate: (candidate_id: string) => void  
}

function JobCandidateSearchTab( { jobId, jobCandidates, loading, searchParams, likeCandidate, hideCandidate, contactCandidate, activeTab, setActiveTab } : Props ) {
  const { hash } = useLocation();
  useEffect(() => {
    const anchorElement = document.getElementById(hash.substring(1));
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (window.location.hash) {
        window.history.replaceState("", document.title, window.location.pathname);
      }
    }
  })

  if (jobCandidates === undefined || loading) {
    return (
      <div className='text-center m-3'><Loader size="md" content="Fetching candidate details..."/></div>
    );
  }

  if (jobCandidates.results.length === 0) {
      return (
        <div className='text-center m-3'>
          <strong>No candidates found. Please adjust your search filter settings.</strong>
        </div>
      )
  }

  return (
    <div>
      {jobCandidates.results.map((jobCandidate: any, index: number) => (
        <Row className="mt-3" id={jobCandidate.candidate.id}>
          <SelectableCandidateCard 
            jobId={jobId}
            searchContext='jobs'
            searchParams={searchParams.toString()}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            likeCandidate={() => likeCandidate(jobCandidate.candidate.id)} 
            hideCandidate={() => hideCandidate(jobCandidate.candidate.id)} 
            contactCandidate={() => contactCandidate(jobCandidate.candidate.id)} 
            jobCandidate={jobCandidate} />
        </Row>
      ))}
    </div>
  );
}

export default JobCandidateSearchTab;