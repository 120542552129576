import { TagGroup, Tag } from 'rsuite';

interface Props {
    candidate: any;
}

const CandidateCoreSkills = ({ candidate }: Props) => {
    const core_skills = candidate?.core_skills

    if (core_skills !== null) {
        return (
            <TagGroup>
            {core_skills.map((skill: any, index: number) => (
              <Tag key={index} color="blue">{skill}</Tag>
            ))}
            </TagGroup>         
        );
    } else {
        return (<>No known skills</>)        
    }
};

export default CandidateCoreSkills;
