import React, { useState } from 'react';

const summaryDigestSize = 200

type Props = {
  summary: string  
};

const CandidateSummary = ({ summary }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

const truncatedSummary = summary ? (summary.length > summaryDigestSize ? summary.substring(0, summaryDigestSize) + '...' : summary) : '';

  return (
    <>
      <div>
        {isExpanded ? summary : truncatedSummary}
      </div>
      {summary && summary.length > summaryDigestSize && (
        <button onClick={toggleExpand} className="more_less_toggle_button">
          {isExpanded ? 'less...' : 'more...'}
        </button>
      )}
    </>
  );
};

export default CandidateSummary;
