import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';

import { patchRequest, getRequest } from "utils/axios";

import { Form, Schema, Stack, Button, useToaster, SelectPicker } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { VscLock, VscWorkspaceTrusted } from 'react-icons/vsc';
import RadioTile from 'components/elements/RadioTile';

import { JobResponse } from 'utils/types'

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'


type Props = {
  readOnly: boolean
  job: JobResponse
}

const JobEditContent = ( { job, readOnly} : Props ) => {
  const params = useParams()
  const toaster = useToaster();
  const formRef: any = useRef()
  const editorRef: any = useRef();

  const [initialized, setInitialized] = useState(false);
  const [formError, setFormError] = useState({});  

  const [visibility, setVisibility] = useState<any>();
  const [description, setDescription] = useState<string>()
  const [onBehalfOfBusinesses, setOnBehalfOfBusinesses] = useState<any>()

  const [formValue, setFormValue] = useState<any>({
    name: '',
    role: '',
    on_behalf_of_business_id: null
  })

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      setVisibility(job.visibility)
      setDescription(job.description)
      getRequest('/businesses/onbehalfs/').then((res) => {
        setOnBehalfOfBusinesses([
          { label: 'Self', value: null },
          ...res.data.results.map((business: any) => ({
              label: business.name,
              value: business.id
          }))
        ]);
      })
      setFormValue({
        name: job.name,
        role: job.role,
        on_behalf_of_business_id: job.on_behalf_of_business_id
      })
    }
  })

  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    name: StringType() 
        .isRequired("This field is required"),
    role: StringType() 
  }) 

  const handleSubmit = (event: any) => {

    if (formRef.current.check()) {
      const jobUpdateRequest = {
        name: formValue.name,
        visibility: visibility,
        on_behalf_of_business_id: formValue.on_behalf_of_business_id
      };
  
      const patchPromise = patchRequest(`/jobs/${job?.id}/`, jobUpdateRequest)      
      patchPromise.then((response) => {
        toaster.push( <RequestSuccessMessage message='Job successfully updated' toaster={toaster}/> )
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      })
    }
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      name: record.name,
      on_behalf_of_business_id: record.on_behalf_of_business_id
    })
  }

  const handleVisibility = (value: any) => {
    setVisibility(value)
  }

  return (<>
    <Form 
        ref={formRef}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}    
        fluid>
      <Stack spacing={10} direction='column' alignItems='flex-end' justify-content='flex-end'>
        <Button appearance="primary" type='submit' disabled={readOnly}>Update</Button>
      </Stack>
      <Form.Group controlId="url">
        Job Description Source:&nbsp;&nbsp;&nbsp;{job.description_url ? <a href={job.description_url} target='_blank'>{job.description_url}</a> : 'Not Specified'}
      </Form.Group>

      <Form.Group controlId="name">
        <Form.ControlLabel>Job Name</Form.ControlLabel>
        <Form.Control name="name" readOnly={readOnly}/>
      </Form.Group>

      <Form.Group controlId="role">
        <Form.ControlLabel>Job Role/Title</Form.ControlLabel>
        <Form.Control name="role" readOnly={true}/>
      </Form.Group>    

      <Form.Group controlId="onbehalf">
        <Form.ControlLabel>Company Hired For</Form.ControlLabel>
        <Form.Control name="on_behalf_of_business_id" accepter={SelectPicker}
            data={onBehalfOfBusinesses}
            searchable={false}
            cleanable={false}
            style={{ width: 500, border: '1px solid #ccc', borderRadius: '7px' }}/>
      </Form.Group>    

      <Form.Group controlId="visibility">
        <Form.ControlLabel>Visibility Level</Form.ControlLabel>
        <Stack spacing={10} direction="row" alignItems='stretch'>
          <RadioTile
            icon={<Icon as={VscLock} />}
            title="User"
            value={visibility}
            name="user"
            onChange={handleVisibility}
            disabled={readOnly}
          >
            Only you will be able to work on this job.
          </RadioTile>
          <RadioTile
            icon={<Icon as={VscWorkspaceTrusted} />}
            title="Business"
            value={visibility}
            name="business"
            onChange={handleVisibility}
            disabled={readOnly}
          >
            The job will be accessible to everyone in your business.
          </RadioTile>
        </Stack>
      </Form.Group>

      <Stack spacing={10} direction='column' alignItems='flex-end' justify-content='flex-end'>
        <Button appearance="primary" type='submit' disabled={readOnly}>Update</Button>
      </Stack>    
    </Form>
</>);
};

export default JobEditContent;
