import React from 'react';

import { capitalize } from 'utils/utils'

import { JobCandidateResponse } from 'utils/types'

type Props = {
  jobCandidate: JobCandidateResponse
};

const CandidateMeetingInfo = ( { jobCandidate }: Props) => {
  const cellStyles = {
    fontWeight: '300'
  }

  const meetings = jobCandidate.calendly_candidate_meeting?.meetings
  if (!meetings || meetings.length === 0)
    return <div>No meetings scheduled.</div>

  function buildHeader(meeting: any) {
    if (meeting.change_reason) 
      return capitalize(meeting.status) + ' (' + meeting.change_reason + ')'
    else
      return capitalize(meeting.status)
  }

  return (<>
    {meetings.map((meeting, index) => 
      <p key={index.toString()}>
        <a style={{fontWeight: 500}} href={meeting.calendly_url} target="_blank">{buildHeader(meeting)}</a>
        <div style={{marginLeft: 10, fontWeight: 300}}>
          {new Date(meeting.start_time).toLocaleDateString()}<br/>
          {new Date(meeting.start_time).toLocaleTimeString() + " - " + new Date(meeting.end_time).toLocaleTimeString()}
        </div>
      </p>
    )}
  </>);
};

export default CandidateMeetingInfo;