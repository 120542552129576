import React from 'react';
import { Checkbox, Table, CellProps, Avatar } from 'rsuite';
import { capitalize } from 'utils/utils'

const { Cell } = Table;

export const StringCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  return (
    <Cell {...props}>{dataKey ? rowData[dataKey] : null}</Cell>
  );
};

export const CapitalizedStringCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  return (
    <Cell {...props}>{dataKey ? capitalize(rowData[dataKey]) : null}</Cell>
  );
};

export const NumberCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  return (
    <Cell {...props}>{dataKey ? rowData[dataKey] : null}</Cell>
  );
};

export const DateCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  return (
    <Cell {...props}>{dataKey && rowData[dataKey]? new Date(rowData[dataKey]).toLocaleString() : null}</Cell>
  );
};

export const ImageCell = ({ rowData, dataKey, ...props }: CellProps<any>) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div
      style={{
        width: 40,
        height: 40,
        background: '#f5f5f5',
        borderRadius: 6,
        marginTop: 2,
        overflow: 'hidden',
        display: 'inline-circle'
      }}
    >
      <Avatar size="md" src={rowData[dataKey!]}/>
    </div>
  </Cell>
);

export const CheckCell = ({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  ...props
}: CellProps<any> & {
  checkedKeys: string[];
  onChange: (value: any, checked: boolean) => void;
}) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey!]}
        inline
        onChange={() => onChange('1', true)}
        checked={checkedKeys.some(item => item === rowData[dataKey!])}
      />
    </div>
  </Cell>
);