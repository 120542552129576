import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import BusinessOnBehalfList from "pages/protected/account/businessOnBehalf/BusinessOnBehalfList"

import { Panel, Breadcrumb, Stack, Button } from 'rsuite';
import { ScrollTopButton, ScrollBottomButton } from 'components/elements/ScrollButtons'

const BusinessOnBehalf = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  })

  return (<>
    <Panel className='header-panel' shaded
      header={<>
        <Stack direction='row' justifyContent="space-between">
          <Stack.Item>
            <h3 className="title">Companies</h3>
          </Stack.Item>
          <Stack.Item>
            <Button appearance="primary" onClick={() => {navigate('/companies/add/')}} >Add Company</Button>
          </Stack.Item>                
        </Stack>

        <Breadcrumb>
        <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Companies</Breadcrumb.Item>
        </Breadcrumb>

      </>}>
    </Panel>

    <ScrollBottomButton />    
    <Panel className='body-panel' shaded style={{minHeight: windowHeight - 200}}>
      <BusinessOnBehalfList />
    </Panel>
    <ScrollTopButton />
</>)};

export default BusinessOnBehalf;