import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRequest, postRequest, putRequest } from "utils/axios";
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { RootState } from "store";

import { Panel, Breadcrumb, Stack, Loader, IconButton, Grid, Row, Col, useToaster, Whisper, Dropdown, Popover } from 'rsuite';

import CandidateDetailContent from 'components/candidate/CandidateDetailContent'
import CandidateAccessTrackerLongView from 'components/tracker/CandidateAccessTrackerLongView'
import CandidateCommentModal from 'components/candidate/CandidateCommentModal'

import ResolveJobCandidateModal from "components/candidate/ResolveJobCandidateModal";
import InitialOutreachEmailModal from "components/email/InitialOutreachEmailModal";

import { JobCandidateResponse, CandidateResponse, CandidateCommentResponse } from 'utils/types'

import { ScrollTopButton, ScrollBottomButton } from 'components/elements/ScrollButtons'

import { capitalize } from 'utils/utils'

import PreviousIcon from '@rsuite/icons/legacy/PagePrevious';
import NextIcon from '@rsuite/icons/legacy/PageNext';
import WatchIcon from '@rsuite/icons/legacy/Star';
import CommentIcon from '@rsuite/icons/legacy/Commenting';
import LikeIcon from '@rsuite/icons/legacy/ThumbsUp';
import HideIcon from '@rsuite/icons/legacy/ThumbsDown';
import ContactIcon from '@rsuite/icons/PhoneFill';
import ResolveIcon from '@rsuite/icons/legacy/Check2';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestConflictMessage from 'components/toasts/RequestConflictMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

import {useDispatch} from "react-redux";
import sideMenuSlice from "store/slices/sidemenu";

const JobCandidate = () => {
  const toaster = useToaster();
  const params = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const configuration = useSelector((state: RootState) => state.configuration);

  const [updateAccessTracker, setUpdateAccessTracker] = useState(true);

  const [isCandidateCommentModalOpen, setCandidateCommentModalOpen] = React.useState(false);  
  
  const [updateEngagementHistory, setUpdateEngagementHistory] = useState(true);

  const [candidateComment, setCandidateComment] = React.useState<any>();  
  const [jobCandidate, setJobCandidate] = React.useState<any>();  
  const handleOpenCandidateCommentModal = () => setCandidateCommentModalOpen(true);  
  const handleCloseCandidateCommentModal = (updatedCandidateComment: CandidateCommentResponse | null) => {
    setCandidateCommentModalOpen(false);
    if (updatedCandidateComment)
      setCandidateComment(updatedCandidateComment)
  }  

  var jobCandidateUrl: any = null
  const state = searchParams.get('tab')
  if (state) {
    jobCandidateUrl = `/jobs/${params.jobId}/candidates/${params.candidateId}/?states=${state}`
  } else {
    jobCandidateUrl = `/jobs/${params.jobId}/candidates/${params.candidateId}/`
  }

  useSWR<JobCandidateResponse>(jobCandidateUrl, fetcher, { onSuccess: 
    (data, key, config) => {
      if (data) {
        setJobCandidate(data as JobCandidateResponse)
      } else {
        const candidatePromise = getRequest(`/candidates/${params.candidateId}/`)
        candidatePromise.then((response: any) => {
          setJobCandidate({
            id: '',
            previous_candidate_id: '',
            next_candidate_id: '',
            job_id: params.jobId,
            state: 'new',
            candidate: response.data as CandidateResponse,
            calendly_candidate_meeting: null,
            resolution_code: null,
            resolution_comment: null,
            is_search_job: true
          })
        })
      }
    }
  })

  const [isResolveCandidateModalOpen, setResolveCandidateModalOpen] = React.useState(false);  
  const handleOpenResolveCandidateModal = () => setResolveCandidateModalOpen(true);  
  const handleCloseResolveCandidateModal = () => {
    setResolveCandidateModalOpen(false)
  }
 
  const [isCandidateEmailModalOpen, setCandidateEmailModalOpen] = React.useState(false);  
  const [emailAttachment, setEmailAttachment] = useState<any>(null);
  
  const handleOpenCandidateEmailModal = (emailAttachment: any) => {
    if (emailAttachment === 'personalized video' && !configuration.features.generated_video_message_support) {
      toaster.push( <RequestSuccessMessage message='Upgrade to a paid plan to enable personalized video generation.' toaster={toaster}/> )
      return
    }
    if (emailAttachment === 'video' && !configuration.features.uploaded_video_message_support) {
      toaster.push( <RequestSuccessMessage message='Upgrade to a paid plan to enable video message upload.' toaster={toaster}/> )
      return
    }
    setEmailAttachment(emailAttachment)
    setCandidateEmailModalOpen(true);  
  }

   const handleCloseCandidateEmailModal = () => {
    setCandidateEmailModalOpen(false)
    setUpdateEngagementHistory(true)
  }  

  useEffect(() => {
    if (!candidateComment)
      loadCandidateComments(params.candidateId)

    if (!jobCandidate) {
      dispatch(sideMenuSlice.actions.setActiveItem('/jobs'));
    }
  })

  if (!jobCandidate) {
    return (<Panel className='body-panel' shaded><div className='text-center m-3'><Loader size="md" content="Fetching candidate details..."/></div></Panel>)
  }

  function loadCandidateComments(candidateId: any) {
    const candidateCommentPromise = getRequest(`/comments/candidates/${candidateId}/`)
    candidateCommentPromise.then((response: any) => {
      setCandidateComment(response.data)
    }).catch(() => {
      setCandidateComment({
        text: '',
        recruiter_id: null,
        modified: null
      })
    })
  }

  function getActiveTab() {
    const activeTab: any = searchParams.get('tab')
    var jobSearchParams = ''
    if (activeTab) {
      jobSearchParams = '?tab=' + activeTab
    }
    return jobSearchParams
  }

  function navigateToCandidate(candidateId: string) {
    setUpdateAccessTracker(true)
    navigate(`/jobs/${params.jobId}/candidates/${candidateId}${getActiveTab()}`)
    loadCandidateComments(candidateId)
  }

  function navigateToJob(candidateId: string) {
    navigate(`/jobs/${params.jobId}${getActiveTab()}#${candidateId}`)
  }

  function likeCandidate(candidateId: string) {
    updateJobCandidate(candidateId, 'liked', 'Liked')
  }

  function hideCandidate(candidateId: string) {
    updateJobCandidate(candidateId, 'hidden', 'Hid')
  }
  
  function contactCandidate(candidateId: string) {
    updateJobCandidate(candidateId, 'engaged', 'Engaged')
  }

  function updateJobCandidate(candidateId: string, state: string, displayState: string) {
    const jobCandidateUpdateRequest = {
      "candidate_id": candidateId,
      "state": state
    };

    const message = displayState + ' candidate'
    const responsePromise = postRequest(`/jobs/${params.jobId}/candidates/`, jobCandidateUpdateRequest)    
    responsePromise.then(
      (response) => {
        jobCandidate.state = response.data.state
        jobCandidate.id = response.data.id
        setJobCandidate({...jobCandidate})
        if (state === 'engaged') {
          jobCandidate.id = response.data.id
          triggerCampaign(jobCandidateUpdateRequest)
        } else {
          toaster.push( <RequestSuccessMessage message={message} toaster={toaster}/> )
        }
    }).catch((error) => {
      if (error.response.status === 409) {
        toaster.push( <RequestConflictMessage message={error.response.data.message} toaster={toaster}/> )  
      } else {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }
    })
  }    

  function triggerCampaign(jobCandidateUpdateRequest: any) {
    const responsePromise = postRequest(`/jobs/${params.jobId}/candidates/${jobCandidate.id}/campaigns/`, jobCandidateUpdateRequest)    
    responsePromise.then((response) => {
      toaster.push( <RequestSuccessMessage message='Started drip campaign for candidate' toaster={toaster}/> )
      setUpdateEngagementHistory(true)
    }).catch((error) => {
      if (error.response.status === 409) {
        toaster.push( <RequestConflictMessage message={error.response.data.message} toaster={toaster}/> )  
      } else {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }
    })          

  }

  function watchCandidate(candidateId: string) {
    putRequest(`/watches/candidates/${candidateId}/?toggle=true`).then(
      (response) => {
        const action = response.data.action
        var message = null
        if (action === 'added')
          message = 'Added candidate to watch list'
        else
          message = 'Removed candidate from watch list'
        jobCandidate.candidate.is_on_watchlist = !jobCandidate.candidate.is_on_watchlist
        setJobCandidate({...jobCandidate})
        toaster.push( <RequestSuccessMessage message={message} toaster={toaster}/> )
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })
  }

  const contactRenderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu
          onSelect={(emailAttachment: any) => {
            handleOpenCandidateEmailModal(emailAttachment); // Call modal only after selecting an item
            onClose(); // Close the menu after selection
          }}
          trigger="click"
          className="rounded-5"
          title="Draft Email"
        >
          <Dropdown.Item eventKey="personalized video">Text + Video</Dropdown.Item>
          {/* TODO: Coming soon <Dropdown.Item eventKey="video">Email + Generic Video</Dropdown.Item> */}
          <Dropdown.Item eventKey="none">Text Only</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Grid fluid style={{marginLeft: '-6px'}}>
        <Row>
          <Col md={12}>
            <Stack spacing={10} direction='row' justifyContent="flex-start" alignItems='flex-start'>
              <h3 className="title">Candidate - {jobCandidate.candidate?.full_name}</h3>
              {capitalize(jobCandidate.state)}
            </Stack>
          </Col>
          <Col md={6} mdPush={6}>
            <Stack spacing={10} direction='row' justifyContent="flex-end" alignItems='flex-end'>
                <div title="Previous Candidate"><IconButton icon={<PreviousIcon />} disabled={!jobCandidate?.previous_candidate_id} appearance="primary" onClick={() => navigateToCandidate(jobCandidate?.previous_candidate_id)}/></div>
                <div title="Next Candidate"><IconButton icon={<NextIcon />} disabled={!jobCandidate?.next_candidate_id} appearance="primary" onClick={() => navigateToCandidate(jobCandidate?.next_candidate_id)}/></div>
                <div title="Like Candidate"><IconButton icon={<LikeIcon />} appearance="primary" onClick={() => likeCandidate(jobCandidate.candidate?.id)} disabled={jobCandidate?.state !== 'new' && jobCandidate?.state !== 'hidden'}/></div>
                <div title="Hide Candidate"><IconButton icon={<HideIcon />} appearance="primary" onClick={() => hideCandidate(jobCandidate.candidate?.id)} disabled={jobCandidate?.state !== 'new' && jobCandidate?.state !== 'liked'}/></div>
                <div title="Contact Candidate">
                  <Whisper
                    placement="bottomStart"
                    trigger="click"
                    speaker={contactRenderMenu}
                  >
                    <IconButton appearance="primary" icon={<ContactIcon />} disabled={!(jobCandidate.state === 'new' || jobCandidate.state === 'liked')} />
                  </Whisper>
                </div>
                <div title="Resolve Candidate"><IconButton icon={<ResolveIcon/>} disabled={jobCandidate?.state === 'resolved'} appearance="primary" onClick={handleOpenResolveCandidateModal}/></div>
                <div title="Add to Favorites"><IconButton icon={<WatchIcon />} appearance="primary" onClick={() => watchCandidate(jobCandidate.candidate?.id)}/></div>
                <div title="Comment on Candidate"><IconButton icon={<CommentIcon />} appearance="primary" onClick={handleOpenCandidateCommentModal}/></div>
            </Stack>
          </Col>
        </Row>
      </Grid>

      <InitialOutreachEmailModal emailAttachment={emailAttachment} jobCandidate={jobCandidate} open={isCandidateEmailModalOpen} handleClose={handleCloseCandidateEmailModal}/>
      <ResolveJobCandidateModal jobCandidate={jobCandidate} open={isResolveCandidateModalOpen} handleClose={handleCloseResolveCandidateModal}/>

      <Stack direction='row' justifyContent="space-between">
      {jobCandidate.is_search_job ?
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigateToJob(jobCandidate.candidate?.id)}>Search</Breadcrumb.Item>
          <Breadcrumb.Item active>Candidate</Breadcrumb.Item>        
        </Breadcrumb>
        :
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => {navigate('/jobs')}}>Jobs</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigateToJob(jobCandidate.candidate?.id)}>Job</Breadcrumb.Item>
          <Breadcrumb.Item active>Candidate</Breadcrumb.Item>        
        </Breadcrumb>
      }
        <CandidateAccessTrackerLongView candidateId={jobCandidate.candidate?.id} update={updateAccessTracker} setUpdate={setUpdateAccessTracker}/>
      </Stack>
      <CandidateCommentModal candidateId={jobCandidate.candidate?.id} candidateComment={candidateComment} setCandidateComment={setCandidateComment} open={isCandidateCommentModalOpen} handleClose={handleCloseCandidateCommentModal}/>                    
    </>}>
    </Panel>

    <ScrollBottomButton />
      <CandidateDetailContent 
        updateEngagementHistory={updateEngagementHistory} 
        updatedEngagementHistory={() => setUpdateEngagementHistory(false)} 
        jobCandidate={jobCandidate} 
        candidateComment={candidateComment}
        editDraft={() => handleOpenCandidateEmailModal(null)}/>
    <ScrollTopButton />    
</>)};

export default JobCandidate;