import StarIcon from '@rsuite/icons/legacy/Star';

interface Props {
    candidate: any
    linkText?: string
}

const CandidateGithubStar = ({ candidate, linkText }: Props) => {
    const isGithubStar = candidate.github?.is_github_star

    if (isGithubStar) {
        const githubStarUrl = 'https://stars.github.com/profiles/' + candidate.github.login
        if (!linkText) {
            linkText = 'Yes'
        }
        return (<>
            <StarIcon color='orange' /> <a href={githubStarUrl} target="_blank">{linkText}</a>
            </>);
    } else {
        if (!linkText) {
            linkText = 'No'
        } else {
            linkText = ''
        }
        return (<>{linkText}</>)        
    }
};

export default CandidateGithubStar;
