import React, { useEffect } from 'react';
import { Panel, Form, InputNumber, SelectPicker, Stack, IconButton, ButtonToolbar, Button } from 'rsuite';

import DeleteIcon from '@rsuite/icons/legacy/Close';

const timeUnitOptions = [
  { label: "days", value: "days" },
  { label: "hours", value: "hours" },
  { label: "minutes", value: "minutes" }
]

type Props = {
  activeTabChanged: boolean
  setActiveTabChanged: (value: boolean) => void  
  handleSubmit: (formBoolean: any, event: any) => void  
  handleStepAdd: () => void  
  campaignStepTemplates: any[]
  setCampaignStepTemplates: (campaignStepTemplates: any[]) => void
};

const ScheduleTab = ({ handleSubmit, handleStepAdd, activeTabChanged, setActiveTabChanged, campaignStepTemplates, setCampaignStepTemplates }: Props) => {

  useEffect(() => {
    if (activeTabChanged) {
      setActiveTabChanged(false)
    }
  });  

  const handleStepDelete = (index: number) => {
    const campaignStepTemplatesCopy = [...campaignStepTemplates]
    campaignStepTemplatesCopy.splice(index, 1)
    campaignStepTemplatesCopy.map((stepTemplate: any, index: number) => {
      stepTemplate.step_number = index
    })
    setCampaignStepTemplates(campaignStepTemplatesCopy)
  }  

  const handleStepChange = (index: number, name: string, value: any) => {
    const campaignStepTemplatesCopy = [...campaignStepTemplates]
    const stepTemplate = campaignStepTemplatesCopy[index]
    if (name === "time_after_previous_email_delivered_value") {
      const numberValue = parseInt(value)
      if (numberValue > 0)
        stepTemplate.trigger_criteria.time_after_previous_email_delivered.value = numberValue
    } else if (name === "time_after_previous_email_delivered_unit") {
      stepTemplate.trigger_criteria.time_after_previous_email_delivered.unit = value
    } else if (name === "time_after_previous_email_opened_value") {
      const numberValue = parseInt(value)
      if (numberValue > 0)
        stepTemplate.trigger_criteria.time_after_previous_email_opened.value = numberValue
    } else if (name === "time_after_previous_email_opened_unit") {
      stepTemplate.trigger_criteria.time_after_previous_email_opened.unit = value
    } else if (name === "emailTone") {
      stepTemplate.candidate_email_template.tone = value
    }
    setCampaignStepTemplates(campaignStepTemplatesCopy)
  }  

  const buildHeader = (index: number) => {
    var header_message = "Initial Email"
    if (index > 0) {
      if (index < campaignStepTemplates.length - 1) {
        header_message = index + ". Follow Up Email"
      } else {
        header_message = "Campaign End"
      }
    }
    return (
      <Stack direction='row' justifyContent="space-between">
        <Stack.Item>
          <strong>{header_message}</strong>
        </Stack.Item>
        {index > 0 && index < campaignStepTemplates.length - 1 &&
          <Stack.Item>
           <IconButton icon={<DeleteIcon />} appearance="subtle" onClick={() => handleStepDelete(index)}/>
          </Stack.Item>
        }
      </Stack>)
  }

  return (
  <Form id="campaignTemplateForm" onSubmit={(formBoolean, event) => handleSubmit(formBoolean, event)} fluid>
      <Stack direction="row" justifyContent="flex-end" style={{marginTop: "10px"}}>
        <ButtonToolbar>
          <Button appearance="primary"  onClick={handleStepAdd}>Add Follow Up</Button>
          <Button appearance="primary" form='campaignTemplateForm' type='submit' >Update</Button>
        </ButtonToolbar>
      </Stack>
      <div style={{marginBottom: "100px"}}>
      {campaignStepTemplates.map((stepTemplate: any, index: number) => {
        if (index > 0) 
          return (
            <Panel id={index.toString()} shaded
              header={buildHeader(index)} style={{marginTop: "10px"}}>
              
              <Form.Group controlId={ "time_after_previous_email_delivered_value" }>
                <Stack direction="row" spacing={10}>
                  <Stack.Item>
                    {index < campaignStepTemplates.length - 1 ? "Follow up" : "End"}
                  </Stack.Item>
                  <Stack.Item>
                    <Form.Control 
                      value={stepTemplate?.trigger_criteria?.time_after_previous_email_delivered?.value} 
                      name="time_after_previous_email_delivered_value" 
                      accepter={InputNumber} 
                      onChange={(value: number) => handleStepChange(index, "time_after_previous_email_delivered_value", value)} 
                      style={{ width: "80px" }}/>
                  </Stack.Item>
                  <Stack.Item>
                    <Form.Control 
                      onChange={(value: any) => handleStepChange(index, "time_after_previous_email_delivered_unit", value)}
                      value={stepTemplate.trigger_criteria?.time_after_previous_email_delivered?.unit} 
                      name="time_after_previous_email_delivered_unit" 
                      accepter={SelectPicker} 
                      data={timeUnitOptions}
                      searchable={false}
                      style={{ width: "100px" }}
                      placeholder="Select" />
                  </Stack.Item>
                  <Stack.Item>
                    {index < campaignStepTemplates.length - 1 ? "after previous email delivered" : "after last email delivered"}
                  </Stack.Item>
                </Stack>                
              </Form.Group>

              <Form.Group controlId={ "time_after_previous_email_opened_value" }>
                <Stack direction="row" spacing={10}>
                  <Stack.Item>
                    {index < campaignStepTemplates.length - 1 ? "Follow up" : "End"}
                  </Stack.Item>
                  <Stack.Item>
                    <Form.Control 
                      value={stepTemplate.trigger_criteria?.time_after_previous_email_opened?.value} 
                      name="time_after_previous_email_opened_value" 
                      accepter={InputNumber} 
                      onChange={(value: number) => handleStepChange(index, "time_after_previous_email_opened_value", value)} 
                      style={{ width: "80px" }}/>
                  </Stack.Item>
                  <Stack.Item>
                    <Form.Control 
                      onChange={(value: any) => handleStepChange(index, "time_after_previous_email_opened_unit", value)}
                      value={stepTemplate.trigger_criteria?.time_after_previous_email_opened?.unit} 
                      name="time_after_previous_email_opened_unit" 
                      accepter={SelectPicker} 
                      data={timeUnitOptions}
                      searchable={false}
                      style={{ width: "100px" }}
                      placeholder="Select" />
                  </Stack.Item>
                  <Stack.Item>
                    {index < campaignStepTemplates.length - 1 ? "after previous email opened" : "after last email opened"}
                  </Stack.Item>
                </Stack>                
              </Form.Group>
            </Panel>)
        }
    )}</div>
  </Form>);
};

export default ScheduleTab;
