import React, { useRef, useState } from 'react';
import { Form, Schema, Stack, InputGroup, Divider, useToaster, Loader } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { VscLock, VscWorkspaceTrusted } from 'react-icons/vsc';
import RadioTile from 'components/elements/RadioTile';
import FormHeader from 'components/elements/FormHeader';
import { postRequest } from "utils/axios";

import { JobRequest } from 'utils/types'
import WizardButtonGroup from 'pages/protected/jobs/wizard/WizardButtonGroup';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'

type Props = {
  jobRequest: JobRequest
  setJobRequest: (job: JobRequest) => void
  numberOfSteps: number
  currentStep: number
  setNextStep: (step: number) => void
};

const JobExternalImportForm = ({ jobRequest, setJobRequest, numberOfSteps, currentStep, setNextStep } : Props) => {
  const [visibility, setVisibility] = useState(jobRequest.visibility)
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    url: jobRequest.description_url
  })

  const toaster = useToaster();

  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    url: StringType() 
        .isURL("Please enter a valid url address") 
        .isRequired("This field is required")
  })

  const formRef: any = useRef()
  const handleSubmit = (event: any) => {
    if (formRef.current.check())  {
      setLoading(true);
      const jobPromise = postRequest(`/jobs/?evaluate_description=url`, jobRequest)    
      jobPromise.then((response) => {
        updateFields(response.data)
        setNextStep(Math.min(currentStep + 1, 2))
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  function updateFields(response: any) {
    jobRequest.id = response.id
    jobRequest.name = response.name
    jobRequest.description = response.description
    jobRequest.role = response.role
    setJobRequest(jobRequest)
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      url: record.url
    })

    setJobRequest({ ...jobRequest,
      description_url: record.url
    })
  }

  const handleVisibility = (value: any) => {
    setVisibility(value)
    setJobRequest({ ...jobRequest,
      visibility: value
    })
  }

  return (<>
    <Form 
        ref={formRef}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        onSubmit={(data: any) => handleSubmit(data)}
        fluid>
      <FormHeader
        title="Importing From External Job Website"
        description="We will attempt to extract all relevant information from the website where your job description is posted."
      />
      <Form.Group controlId="url">
        <Form.ControlLabel>Website</Form.ControlLabel>

        <InputGroup style={{ width: '100%' }}>
          <Form.Control name="url" placeholder="https://your-job-description" />
        </InputGroup>
        <Form.HelpText>
          Enter the website url where your job description is currently hosted.
        </Form.HelpText>
      </Form.Group>

      <Form.Group controlId="visibility">
        <Form.ControlLabel>Visibility Level</Form.ControlLabel>
        <Stack spacing={10} direction="row" alignItems={'stretch'}>
          <RadioTile
            icon={<Icon as={VscLock} />}
            title="User"
            value={visibility}
            name="user"
            onChange={handleVisibility}
          >
            Only you will be able to work on this job.
          </RadioTile>
          <RadioTile
            icon={<Icon as={VscWorkspaceTrusted} />}
            title="Business"
            value={visibility}
            name="business"
            onChange={handleVisibility}
          >
            This job will be accessible to everyone in your business.
          </RadioTile>
        </Stack>
      </Form.Group>

      <Divider />
      {loading && <div className='text-center m-3'>
        <Loader size="lg" content="Crafting your job search..."/>
      </div>}

      <WizardButtonGroup nextStepLabel='Build' numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} />
    </Form>
</>);
};

export default JobExternalImportForm;
