import React, { useState, useEffect } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { useNavigate } from "react-router";

import { BusinessOnBehalfListResponse , BusinessOnBehalfResponse } from "utils/types";

import {
 Table, Loader
} from 'rsuite';
import { CapitalizedStringCell, NumberCell } from 'components/utils/Cells';
import { sortJsonObject } from 'utils/utils';


const { Column, HeaderCell, Cell } = Table;


function BusinessOnBehalfOfList() {
  const navigate = useNavigate();

  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { data: businessListResponse, trigger } = useSWRMutation<BusinessOnBehalfListResponse>(`/businesses/onbehalfs/`, fetcher)

  useEffect(() => {
    if (businessListResponse === undefined) {
      trigger()
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [businessListResponse, trigger]);

  if (!businessListResponse) {
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching companies..."/>
      </div>
    )
  }

  var businessesOnBehalf: BusinessOnBehalfResponse [] = businessListResponse.results;
  
  function handleSortColumn(sortColumn: any, sortType: any) {
    setSortColumn(sortColumn);
    setSortType(sortType);
  }

  const filteredData = () => {
    const filtered = businessesOnBehalf.filter(businessOnBehalf => {
      if (!businessOnBehalf.name.toLowerCase().includes(searchKeyword.toLowerCase()) && !businessOnBehalf.description.toLowerCase().includes(searchKeyword.toLowerCase())) {
        return false;
      }
      return true;
    });

    return sortJsonObject(filtered, sortColumn, sortType)
  }

  return (
    <>
      <Table 
        wordWrap="break-word"
        height={windowHeight - 300}
        data={filteredData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        bordered
        rowHeight={45}
        style={{fontSize: '14px'}}        
      >

        <Column minWidth={300} flexGrow={3} sortable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name">{(rowData: any) => { return (
            <div  onClick={() => {navigate('/companies/' + rowData.id)}}>
              {rowData.name}
            </div>
            )}}
          </Cell>
        </Column>

        <Column minWidth={200} sortable flexGrow={1}>
          <HeaderCell>Contact Name</HeaderCell>
          <CapitalizedStringCell dataKey="contact_name" />
        </Column>

        <Column minWidth={200} sortable flexGrow={1}>
          <HeaderCell>Contact Email</HeaderCell>
          <NumberCell dataKey="contact_email"/>
        </Column>

        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>Contact Phone</HeaderCell>
          <NumberCell dataKey="contact_phone" />
        </Column>       
      </Table>
    </>
  );
};

export default BusinessOnBehalfOfList;