import React from 'react';

import {
  Drawer,
  Timeline,
  Divider,
  Stack,
  Form,
  TagInput
} from 'rsuite';

import EmailIcon from '@rsuite/icons/Email';

import BundledEditor from 'BundledEditor.js'

import { TrackingEmail, TrackingEmailLink } from 'utils/types'

type Props = {
  open: boolean
  onClose: () => void
  email: TrackingEmail
};


const EmailDrawerView = ({ open, onClose, email }: Props) => {
  function timelineIcon() {
    return <EmailIcon style={{ background: '#15b215', color: '#fff' }} />
  }

  const labelFieldSize = "60px"
  const headerFontSize = "14px"

  return (
    <Drawer backdrop={true} size="md" placement="right" open={open} onClose={onClose}>
      <Drawer.Header>
        <Drawer.Title><h3>Email Details</h3></Drawer.Title>
      </Drawer.Header>

      <Drawer.Body>
      <Stack direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={60}>
        
        <Stack.Item><h4>Delivery Timeline</h4>
          <Timeline className='custom-timeline' style={{fontSize: headerFontSize}}>
            {(email.status === 'scheduled' || email.status === 'queued') && 
            <Timeline.Item dot={timelineIcon()}>
                {(email.desired_sent_time === undefined || email.desired_sent_time === null) ? 
                  <p>Sent</p>
                  :
                  <p>Scheduled for {new Date(email.desired_sent_time).toLocaleString()}</p>
                }
            </Timeline.Item>}
            {email.status === 'sent' && 
            <Timeline.Item dot={timelineIcon()}>
                {new Date(email.sent_time).toLocaleString()}
                <p>Sent</p> 
            </Timeline.Item>}
            {email.status === 'failed' && 
            <Timeline.Item dot={timelineIcon()}>
              {new Date(email.processed_time).toLocaleString()}
              <p>Failed</p> 
            </Timeline.Item>}

            {email.status === 'delivered' && 
            <Timeline.Item dot={timelineIcon()}>
                {new Date(email.delivered_time).toLocaleString()}
                <p>Delivered</p> 
            </Timeline.Item>}

            {email.status === 'bounced' && 
            <Timeline.Item dot={timelineIcon()}>
                {new Date(email.delivered_time).toLocaleString()}
                <p>Bounced</p> 
            </Timeline.Item>}

            {email.opened_time && 
            <Timeline.Item dot={timelineIcon()}>
                {new Date(email.opened_time).toLocaleString()}
                <p>Opened</p> 
            </Timeline.Item>}
          </Timeline>
        </Stack.Item>
        <Stack.Item><h4>Click Tracking</h4>
        {email.clicked_links.length === 0 && (email.status === 'scheduled' || email.status === 'queued' || email.status === 'sent') ?
         <p style={{fontSize: '12px'}}></p>
         : email.clicked_links.length === 0 &&
            (<p style={{fontSize: '12px'}}>Waiting for candidate to click on links in email.</p>)
        }
        {email.clicked_links.map((link: TrackingEmailLink, index: number) => <>
            {index > 0 && <Divider/>}
            <p style={{fontSize: '13px'}}>Candidate clicked <a href={link.link} target="_blank">{link.link}</a> {link.click_count} times<br></br>
            First time was on {new Date(link.first_time_clicked).toLocaleString()}<br></br>
            Last time was on {new Date(link.last_time_clicked).toLocaleString()}</p>
          </>
          )}
        </Stack.Item>
        </Stack>
        <Divider />
        
        <Form fluid>
        <div style={{fontSize: headerFontSize}}>
              <Form.Group controlId="headers">
              <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
                <Stack.Item alignSelf='stretch' basis="100%">
                  <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
                    <Stack.Item basis={labelFieldSize}>
                      <Form.ControlLabel>TO</Form.ControlLabel>
                    </Stack.Item>
                    <Stack.Item grow={2} alignSelf='stretch'>
                      <Form.Control name="to" readOnly value={email.recipient_to} style={{fontSize: headerFontSize, width: '100%'}}/>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>

                <Stack.Item alignSelf='stretch' basis="100%">
                  <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
                    <Stack.Item basis={labelFieldSize}>   
                      <Form.ControlLabel>CC</Form.ControlLabel>
                    </Stack.Item>
                    <Stack.Item grow={1} alignSelf='stretch'>         
                      <Form.Control name="cc" readOnly accepter={TagInput} style={{fontSize: headerFontSize, width: '100%'}}
                        value={email.recipients_cc} 
                      />             
                    </Stack.Item>
                  </Stack>                      
                </Stack.Item>

                <Stack.Item alignSelf='stretch' basis="100%">
                  <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
                    <Stack.Item basis={labelFieldSize}>
                      <Form.ControlLabel>BCC</Form.ControlLabel>
                    </Stack.Item>
                    <Stack.Item grow={1} alignSelf='stretch'>
                    <Form.Control name="bcc" readOnly accepter={TagInput} style={{fontSize: headerFontSize, width: '100%'}}
                        value={email.recipients_bcc} 
                    />
                  </Stack.Item>
                  </Stack>
                </Stack.Item>
                
                <Stack.Item alignSelf='stretch' basis="100%">
                  <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>  
                    <Stack.Item basis={labelFieldSize}>              
                      <Form.ControlLabel>From</Form.ControlLabel>
                    </Stack.Item>
                    <Stack.Item grow={1} alignSelf='stretch'>        
                      <Form.Control name="from" readOnly value={email.sender} style={{fontSize: headerFontSize, width: '100%'}}/>             
                    </Stack.Item>
                  </Stack>  
                </Stack.Item>

                <Stack.Item alignSelf='stretch' basis="100%">
                  <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>  
                    <Stack.Item basis={labelFieldSize}>           
                      <Form.ControlLabel>Subject</Form.ControlLabel>
                    </Stack.Item>
                    <Stack.Item grow={1} alignSelf='stretch'>         
                      <Form.Control name="subject" readOnly value={email.subject} style={{fontSize: headerFontSize, width: '100%'}}/>
                    </Stack.Item>
                  </Stack>  
                </Stack.Item>

              </Stack>                    
              </Form.Group>
              </div>

            <Divider/>

            <Form.Group controlId="content" style={{marginTop: 20}}>
              <BundledEditor
                    initialValue={email.body}
                    disabled={true}
                    init={{
                      inline: true,
                      promotion: false,
                      branding: false,
                      height: 400,
                      resize: true,
                      menubar: false,
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                    />                  
            </Form.Group>    
        </Form>


      </Drawer.Body>
    </Drawer>
  );
};

export default EmailDrawerView;
