import React from 'react';
import { useLocation, useNavigate } from 'react-router';


import { Breadcrumb, Panel, Stack, Button } from 'rsuite';

import WizardForm from './WizardForm';


const JobWizard = () => {
  const navigate = useNavigate()
  const location = useLocation()

  function determineParameterValue(name: string, defaultValue: any) {
    var parameterValue = defaultValue
    const parameterString = location?.search;
    if (parameterString && parameterString.length > 1) {
      const parameterList = location.search.substring(1).split('&')
      for (let index in parameterList) {
        const keyValue = parameterList[index].split('=')
        if (keyValue[0] === name) {
          parameterValue = keyValue[1]
        }
      }
    }
    return parameterValue
  }    

  return (<>
    <Panel className='header-panel' shaded
      header={<>
          <Stack direction='row' justifyContent="space-between">
            <h3 className="title">Job Wizard</h3>
            <Button appearance="primary" onClick={() => {navigate('/jobs/')}} >Jobs</Button>
          </Stack>
          <Breadcrumb>
            <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#" onClick={() => {navigate('/jobs')}}>Jobs</Breadcrumb.Item>
            <Breadcrumb.Item active>Job Wizard</Breadcrumb.Item>
          </Breadcrumb>
        </>}>
    </Panel>
    
    <Panel className='body-panel' shaded>
      <WizardForm 
        state={determineParameterValue('state', '0')} 
        cloneId={determineParameterValue('cloneId', null)}
        type={determineParameterValue('type', 'external')}/>
    </Panel>      
</>)};

export default JobWizard;
