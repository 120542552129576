import React, { useState } from "react";

import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import store, { persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import ProtectedRoute from "routes/ProtectedRoute";
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import Chatbot from "react-chatbot-kit"
import 'react-chatbot-kit/build/main.css'
import ChatBotNavigate from "components/chatbot/Navigate"

import config from "components/chatbot/config";
import MessageParser from "components/chatbot/MessageParser";
import ActionProvider from "components/chatbot/ActionProvider";

import { Billing, Profile, Business, BusinessOnBehalf, BusinessOnBehalfEdit, Support, Help, Calendar, AdminAccounts, AdminDeployment, AdminSystem, Candidates, Jobs, Search, Assistant, AdminAnalytics, Analytics, Candidate, JobCandidate, Job, JobEdit, JobWizard } from "pages/protected";

import {
    MDBContainer, MDBRow, MDBCol
} from 'mdb-react-ui-kit';



import Header from "components/workspace/Header"
import SideNavBar from "components/workspace/SideNavBar"

import { SignIn, SignUp, ResetPassword, GoogleSignIn, Subscriptions } from "pages";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/rsuite.css';
import ReactGA from 'react-ga4';
import GoogleAnalyticsTracker from 'components/tracker/GoogleAnalyticsTracker';
import SharedLink from 'pages/SharedLinks';


export default function App() {
    const [menuExpanded, setMenuExpand] = useState(true);
    const [chatBotEnabled, enableChatBot] = useState(false);

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    }

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <Router>
                    <GoogleAnalyticsTracker />
                    <Routes>
                        <Route path='/signin' element={<SignIn />} />
                        <Route path='/signup' element={<SignUp />} />
                        <Route path='/resetpassword' element={<ResetPassword />} />
                        <Route path='/google/signin' element={<GoogleSignIn />} />
                        <Route path='/subscriptions' element={<Subscriptions />} />
                        <Route path='/productdemo' element={<SharedLink name="Product Demo" url="https://www.youtube.com/watch?v=q7wKrXsFKmQ" />} />
                        <Route path='/teamintro' element={<SharedLink name="Team Intro" url="https://www.youtube.com/watch?v=s85mf1-ZOvA" />} />
                        <Route path='/pitchdeck' element={<SharedLink name="Pitch Deck" url="https://docsend.com/view/czbt4hw4q34d8u5t" />} />

                        <Route element={
                            <ProtectedRoute>
                                <ThemeProvider
                                    breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                                    minBreakpoint="xxs">
                                    <div className="ro-page-default">
                                        <div className="ro-header">
                                            <Header />
                                        </div>

                                        {/* top and bottom ids are used to quickly navigate to top and bottom of longer lists */}
                                        <div className="ro-body" id='top'>
                                            <MDBContainer fluid>
                                                <MDBRow>
                                                <MDBCol
                                                    className="ro-side-navbar"
                                                    style={{
                                                        width: menuExpanded ? '210px' : '70px',
                                                        height: '100vh', // Ensure it takes the full height of the viewport
                                                        display: 'flex', // Use flexbox to handle child elements properly
                                                        flexDirection: 'column', // Stack children vertically
                                                        overflowY: 'auto', // Allow vertical scrolling
                                                        overflowX: 'hidden', // Prevent horizontal scrolling
                                                      }}
                                                    >
                                                    <SideNavBar menuExpanded={menuExpanded} setMenuExpand={setMenuExpand} />
                                                </MDBCol>
                                                    <MDBCol className="ro-workspace" style={{ paddingLeft: menuExpanded ? '170px' : '40px' }}>
                                                        <MDBContainer fluid>
                                                            <Outlet />
                                                            <div className="appChatbotContainer">
                                                                {chatBotEnabled &&
                                                                    <Chatbot
                                                                        config={config}
                                                                        messageParser={MessageParser}
                                                                        actionProvider={ActionProvider}
                                                                    />
                                                                }
                                                                {
                                                                    // Temporarily disabled until implemented: <button onClick={() => enableChatBot(!chatBotEnabled)} className="appChatbotButton"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="appChatbotButtonIcon"><path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path></svg></button> 
                                                                }
                                                            </div>
                                                        </MDBContainer>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBContainer>
                                        </div>

                                        <div className="ro-bottom" id='bottom' />
                                    </div>
                                </ThemeProvider>
                            </ProtectedRoute>
                        }>

                            <Route path="/" element={
                                <ProtectedRoute>
                                    <Assistant />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/profile" element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/business" element={
                                <ProtectedRoute>
                                    <Business />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/companies" element={
                                <ProtectedRoute>
                                    <BusinessOnBehalf />
                                </ProtectedRoute>
                            }
                            />
                             <Route path="/companies/:companyId" element={
                                <ProtectedRoute>
                                    <BusinessOnBehalfEdit />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/billing" element={
                                <ProtectedRoute>
                                    <Billing />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/support" element={
                                <ProtectedRoute>
                                    <Support />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/help" element={
                                <ProtectedRoute>
                                    <Help />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/jobs" element={
                                <ProtectedRoute>
                                    <Jobs />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/candidates" element={
                                <ProtectedRoute>
                                    <Candidates />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/jobs/wizard" element={
                                <ProtectedRoute>
                                    <JobWizard />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/jobs/:jobId/edit" element={
                                <ProtectedRoute>
                                    <JobEdit />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/jobs/:jobId" element={
                                <ProtectedRoute>
                                    <Job />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/jobs/:jobId/candidates/:candidateId" element={
                                <ProtectedRoute>
                                    <JobCandidate />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/candidates/:candidateId" element={
                                <ProtectedRoute>
                                    <Candidate />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/search" element={
                                <ProtectedRoute>
                                    <Search />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/navigate/*" element={
                                <ProtectedRoute>
                                    <ChatBotNavigate />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/calendar" element={
                                <ProtectedRoute>
                                    <Calendar />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/analytics" element={
                                <ProtectedRoute>
                                    <Analytics />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/analytics" element={
                                <ProtectedRoute>
                                    <AdminAnalytics />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/accounts" element={
                                <ProtectedRoute>
                                    <AdminAccounts />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/system" element={
                                <ProtectedRoute>
                                    <AdminSystem />
                                </ProtectedRoute>
                            }
                            />
                            <Route path="/admin/deployments" element={
                                <ProtectedRoute>
                                    <AdminDeployment />
                                </ProtectedRoute>
                            }
                            />
                            <Route />
                        </Route>

                        <Route path='*' element={<Navigate to="/" />} />
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
    );
}