import React, { useEffect } from "react";

import logo from "assets/images/talentwell-logo-with-text.png";
import splash from "assets/images/login_splash.png";
import background from "assets/images/login_splash_background.png";

import Image from 'react-bootstrap/Image';
import { Grid, Row, Col, Loader } from 'rsuite';

import { sendGAEvent } from 'components/tracker/GoogleAnalyticsTracker';

type Props = {
  name: string;
  url: string;
};

export default function SharedLink({name, url}: Props) {
  useEffect(() => {
    sendGAEvent("Shared Link", name, url)
    window.location.href = url;
  }, []);

  return (
    <Grid fluid>
      <Row>
        <Col xsHidden={true} smHidden={true} mdHidden={true} lg={12} xl={12} xxl={12} style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
          <div className="center-container">
            <Image src={splash} fluid loading="lazy" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
          <div className="center-container">
              <div className="signin-container">
                <a href="https://talentwell.ai">
                  <Image src={logo} fluid loading="lazy" style={{ width: "300px", marginBottom: "20px" }}/>
                </a>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                  <Loader size="md" content="Redirecting..."/>
                </div>
              </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};