import React from 'react';
import Chart from 'react-apexcharts';
import { Panel } from 'rsuite';

interface HorizontalBarChart {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  data: any;
  type?: any
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  options?: any;
  labels?: string[];
}

const HorizontalBarChart = ({ title, actions, data, type, labels, options }: HorizontalBarChart) => {
  const defaultOptions = {
    chart: {
      fontFamily: 'inherit',
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%'
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    grid: {
      padding: {
        top: -30,
        right: 0,
        left: 0,
        bottom: -15
      },    
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: labels,
    },
    colors: ["#FFCC00", "#66CC00"],
    legend: {
      show: false
    }
  };
  
  return (<Panel className="card" header={title} style={{height: 300}}>
    <Chart
      series={data}
      type={type}
      height={200}
      options={Object.assign({}, defaultOptions, options, { labels })}
    />
  </Panel>)
};

export default HorizontalBarChart;
