import React, { useState, useEffect } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { useNavigate } from "react-router";

import { CandidateListResponse , CandidateResponse } from "utils/types";

import {
  Input, InputGroup, Table, Checkbox, Stack, Avatar, Loader, Whisper, Popover, Dropdown, IconButton, Grid, Row, Col, CheckPicker, CellProps, CellProps as TableCellProps
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from '@rsuite/icons/legacy/More';
import { sortJsonObject, capitalizeAll } from 'utils/utils';
import CandidateAvatar from 'components/candidate/CandidateAvatar'

const { Column, HeaderCell, Cell } = Table;

const statusOptions = [
  {
    value: 'new',
    label: 'New'
  },
  {
    value: 'liked',
    label: 'Liked'
  },
  {
    value: 'engaged',
    label: 'Engaged'
  },
  {
    value: 'scheduled',
    label: 'Scheduled'
  },
  {
    value: 'hidden',
    label: 'Hidden'
  },
  {
    value: 'resolved',
    label: 'Resolved'
  }
]

type Props = {
  showMenu?: boolean
  enableScroll?: boolean
  candidateCounts: { filtered: number, total: number }
  setCandidateCounts: ( jobCounts: any  ) => void
}

function CandidateList( { showMenu, enableScroll, candidateCounts, setCandidateCounts } : Props ) {
  if (enableScroll === undefined) {
    enableScroll = true
  }  
  if (showMenu === undefined) {
    showMenu = true
  }  
  const searchParams = new URLSearchParams()
  searchParams.append('limit', '100')
  searchParams.append('states', 'new,liked,engaged,scheduled')

  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState(['new','liked','engaged','scheduled'])
  const [candidateFilter, setCandidateFilter] = useState<URLSearchParams>(searchParams)
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const [sortColumn, setSortColumn] = useState()
  const [sortType, setSortType] = useState()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [watchedChecked, setWatchChecked] = useState(false)
  const [criteraChanged, setCriteraChanged] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { data: candidateListResponse, trigger } = useSWRMutation<CandidateListResponse>(`/candidates/?${candidateFilter}`, fetcher)

  useEffect(() => {
    if (candidateListResponse === undefined || criteraChanged) {
      setCriteraChanged(false)
      trigger()
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
  })

  if (!candidateListResponse)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching candidates..."/>
      </div>
    );

  var candidates: CandidateResponse [] = candidateListResponse.results;
  
  let candidateAllChecked = false;
  let candidateAllIndeterminate = false;

  if (checkedKeys.length === candidates.length) {
    candidateAllChecked = true;
  } else if (checkedKeys.length === 0) {
    candidateAllChecked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < candidates.length) {
    candidateAllIndeterminate = true;
  }

  function handleClearStatusFilter(value: any) {
    setStatusFilter([])
    const newSearchParams = getNewSearchParams()
    newSearchParams.delete('states')
    setCandidateFilter(newSearchParams)
    setCriteraChanged(true)
  }

  function handleStatusFilter(value: any) {
    const newSearchParams = getNewSearchParams()

    setStatusFilter(value)
    if (value !== null && value.length > 0) {
      newSearchParams.set('states', value)
    } else {
      newSearchParams.set('states', 'new,liked,engaged,scheduled')
    }

    setCandidateFilter(newSearchParams)
    setCriteraChanged(true)
  }

  function handleCandidateCheckAll(_value: any, checked: boolean) {
    const keys = checked ? candidates.map(item => item.id) : [];
    setCheckedKeys(keys);
  };

  function handleCandidateChecked(value: any, checked: any) {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(keys);
  };
  
  function getNewSearchParams() {
    const newSearchParams = new URLSearchParams()

    const states = candidateFilter.get('states')
    if (states) {
      newSearchParams.append('states', states)
    }
    const watched = candidateFilter.get('watched')
    if (watched) {
      newSearchParams.append('watched', watched)
    }
    const limit = candidateFilter.get('limit')
    if (limit) {
      newSearchParams.append('limit', limit)
    }

    return newSearchParams    
  }
  function handleWatchedChecked(value: any, checked: any) {
    const newSearchParams = getNewSearchParams()

    setWatchChecked(checked)
    if (checked) {
      newSearchParams.set('watched', 'true')
    } else {
      newSearchParams.delete('watched')
    }

    setCandidateFilter(newSearchParams)
    setCriteraChanged(true)      
  };

  function handleSortColumn(sortColumn: any, sortType: any) {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  const filteredData = () => {
    const filtered = candidates.filter(candidate => {
      if ((candidate.location && !candidate.location.toLowerCase().includes(searchKeyword.toLowerCase())) 
        && (candidate.full_name && !candidate.full_name.toLowerCase().includes(searchKeyword.toLowerCase()))) {
        return false;
      } else {
        return true;
      }
    });

    if (filtered.length != candidateCounts.filtered) {
      setCandidateCounts( { filtered: filtered.length, total: candidates.length } )
    }
    return sortJsonObject(filtered, sortColumn, sortType)
  };

  return (
    <>
      {showMenu && (
      <Stack justifyContent="flex-start" spacing={10} style={{paddingBottom: '10px'}}>
        <CheckPicker style={{width: '300px'}}
          countable={false}
          label="Status"
          data={statusOptions}
          defaultValue={statusFilter}
          searchable={false}
          onSelect={handleStatusFilter}
          onClean={handleClearStatusFilter}
        />        
        <InputGroup inside>
          <Input placeholder="Search" value={searchKeyword} onChange={setSearchKeyword} />
          <InputGroup.Addon>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>
        <Checkbox 
          checked={watchedChecked}
          onChange={handleWatchedChecked}>Favorites</Checkbox>
      </Stack>
      )}

      <Table 
        wordWrap="break-word"
        height={windowHeight - 300}
        data={filteredData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        bordered
        autoHeight={!enableScroll}
        rowHeight={45}
        style={{fontSize: '14px'}}   
      >
        <Column width={50} fixed>
          <HeaderCell style={{ padding: 0 }}>
              <Checkbox
                inline
                checked={candidateAllChecked}
                indeterminate={candidateAllIndeterminate}
                onChange={handleCandidateCheckAll}
              />
          </HeaderCell>
          <Cell dataKey="id" style={{ padding: 0 }}>{(rowData: any) => (
             <Checkbox
              value={rowData.id}
              inline
              onChange={handleCandidateChecked}
              checked={checkedKeys.some(item => item === rowData.id)}
              style={{height: '10px'}}
              />
          )}
          </Cell>
        </Column>

        <Column minWidth={100} flexGrow={2} sortable>
          <HeaderCell>Name</HeaderCell>
          <NameCell dataKey="full_name" />
        </Column>

        <Column width={50} align="center">
          <HeaderCell> </HeaderCell>
          <Cell dataKey='image'>{(rowData: any) => (
              <CandidateAvatar size="md" candidate={rowData}/>
            )}
          </Cell>
        </Column>  

        <Column width={110} sortable>
          <HeaderCell>Last Viewed</HeaderCell>
          <Cell dataKey="access_tracker_event.modified">{(rowData: any) => `${rowData.access_tracker_event ? new Date(rowData.access_tracker_event.modified).toLocaleDateString() : 'Never Accessed'}`}</Cell>
        </Column>
        
        <Column width={110} sortable>
          <HeaderCell>View Count</HeaderCell>
          <Cell dataKey="access_tracker_event.access_count">{(rowData: any) => `${rowData.access_tracker_event ? rowData.access_tracker_event.access_count : 0}`}</Cell>
        </Column>
        
        <Column minWidth={50} sortable>
          <HeaderCell>Country</HeaderCell>
          <Cell dataKey="geo_location.country_iso"/>
        </Column>
        
        <Column minWidth={50} sortable>
          <HeaderCell>State</HeaderCell>
          <Cell dataKey="geo_location.state_iso"></Cell>
        </Column>
        
        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>City</HeaderCell>
          <Cell dataKey="geo_location.city"></Cell>
        </Column>

        <Column minWidth={150} sortable flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <StatusCell dataKey="job_status.state" />
        </Column>       

        <Column minWidth={150} sortable flexGrow={1}>
          <HeaderCell>Outreach</HeaderCell>
          <OutreachCell dataKey="email_outreach_status.status" />
        </Column>              

        <Column minWidth={120} flexGrow={1}>
          <HeaderCell>
            <MoreIcon />
          </HeaderCell>
          <ActionCell dataKey="id" refresh={() => setCriteraChanged(true)} />
        </Column>
      </Table>
    </>
  );
};

export default CandidateList;

const NameCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate()

  const speaker = (
    <Popover title="Candidate">
      <Grid style={{width: '400px'}}>
      <Row>
          <Col lg={4}>Role:</Col>
          <Col lg={20}>{rowData.role}</Col>
        </Row>        
        <Row>
          <Col lg={4}>Degree:</Col>
          <Col lg={20}>{rowData.degree}</Col>
        </Row>         
        <Row>
          <Col lg={4}>Company:</Col>
          <Col lg={20}>{rowData.company}</Col>
        </Row>        
        <Row>
          <Col lg={4}>Summary:</Col>
          <Col lg={20}>{rowData.summary}</Col>
        </Row>     
      </Grid>   
    </Popover>
  );

  return (
    <Cell {...props}>
      <div  onClick={() => {navigate(`/jobs/${rowData.job_status.job_id}/candidates/${rowData.id}/?tab=${rowData.job_status.state}`)}}>
        <Whisper placement="top" speaker={speaker}>
          <a>{rowData.full_name}</a>
        </Whisper>
      </div>
    </Cell>
  );
};  

const StatusCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  const navigate = useNavigate();
  
  var speaker = (<></>)

  if (rowData.job_status.state !== 'new') {
    speaker = <Popover title="Click to go to related job"/>
  }

  return (
    <Cell {...props}>
      {rowData.job_status.state !== 'new' ? (
      <div onClick={() => {navigate(`/jobs/${rowData.job_status.job_id}?tab=${rowData.job_status.state}#${rowData.id}`)}}>
        <Whisper placement="top" speaker={speaker}>
          <a>{capitalizeAll(rowData.job_status.state)}</a>
        </Whisper>
      </div>) : (
        <>{capitalizeAll(rowData.job_status.state)}</>
      )}
    </Cell>
  );
};  

const OutreachCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
  var speaker = (<></>)

  if (rowData.email_outreach_status.type) {
    speaker = (
        <Popover title={capitalizeAll(rowData.email_outreach_status.type + ' email')}>
          {rowData.email_outreach_status.status === 'draft' && rowData.email_outreach_status.modified_time && <p><b>Drafted:</b> {new Date(rowData.email_outreach_status.modified_time).toLocaleString()}</p>}
          {rowData.email_outreach_status.delivered_time && <p><b>Delivered:</b> {new Date(rowData.email_outreach_status.delivered_time).toLocaleString()}</p>}
          {rowData.email_outreach_status.opened_time && <p><b>Opened:</b> {new Date(rowData.email_outreach_status.opened_time).toLocaleString()}</p>}
          {rowData.email_outreach_status.status === 'bounced' && rowData.email_outreach_status.modified_time  && <p><b>Bounced:</b> {new Date(rowData.email_outreach_status.modified_time).toLocaleString()}</p>}
        </Popover>
      );
    }

  return (
    <Cell { ...props}>
      {rowData.email_outreach_status.type ? (
      <Whisper placement="top" speaker={speaker}>
        <a>{capitalizeAll(rowData.email_outreach_status.status)}</a>
      </Whisper>) : (
        <>{capitalizeAll(rowData.email_outreach_status.status)}</>
      )}
    </Cell>
  );
};  

interface ActionProps<T = any> extends Omit<TableCellProps<any>, 'rowData' | 'dataKey'> {
  dataKey?: string | keyof T;
  rowData?: T;
  refresh: () => void  
}

const ActionCell = ({ rowData, dataKey, refresh, ...props }: ActionProps) => {
  const navigate = useNavigate();

  function handleSelect(eventKey: any) {
    switch (eventKey) {
      case 'candidate':
        navigate(`/jobs/${rowData.job_status.job_id}/candidates/${rowData.id}/?tab=${rowData.job_status.state}`);
        break;
      case 'job':
        navigate(`/jobs/${rowData.job_status.job_id}?tab=${rowData.job_status.state}#${rowData.id}`)
      }
  };
    
  return (
    <Cell {...props} className="link-group">
      <div style={{height: '10px'}}>
      <Whisper placement="autoVerticalEnd" trigger="click" speaker={
          <Popover full>
            <Dropdown.Menu onSelect={handleSelect}>
              {rowData.job_status.state !== 'new' && <Dropdown.Item eventKey='job'>Related Job</Dropdown.Item>}
              <Dropdown.Item eventKey='candidate'>Candidate Details</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
      }>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
      </div>
    </Cell>
  );
};