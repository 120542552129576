// in ActionProvider.jsx
import React from 'react';

import { useNavigate } from "react-router";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const navigate = useNavigate();

  const navigateToSearch = () => {
    const botMessage = createChatBotMessage('Have fun searching ...');
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
    navigate('/navigate/search')
};

  const navigateToJob = () => {
    const botMessage = createChatBotMessage('Let me go to jobs ...');
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
    navigate('/navigate/jobs/b1a804de-ee80-41e7-b7ad-5ec2fc828cdc?tab=new')
};

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            navigateToSearch, navigateToJob
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;