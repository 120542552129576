import React from 'react';
import { Form, Stack, Divider } from 'rsuite';
import RadioTile from 'components/elements/RadioTile';
import { Icon } from '@rsuite/icons';
import { VscNotebookTemplate, VscFile } from 'react-icons/vsc';
import FormHeader from 'components/elements/FormHeader';
import WizardButtonGroup from 'pages/protected/jobs/wizard/WizardButtonGroup';

type Props = {
  importType: string,
  setImportType: (type: string) => void
  numberOfSteps: number
  currentStep: number
  setNextStep: (step: number) => void  
};

const JobTypeForm = ({ importType, setImportType, numberOfSteps, currentStep, setNextStep } : Props) => {

  const handleSubmit = (event: any) => {
    setNextStep(Math.min(currentStep + 1, 4))
  }

  return (<>
    <Form
        onSubmit={handleSubmit}
        fluid
      >
      <FormHeader
        title="Create New Job"
        description="Choose one of the three methods listed below to creat a new job. Although you can always create a new job without a job description, it is highly recommended to import existing job description or write them in detail manually. Job descriptions are key data points to match relevant candidates and write outreach emails that will get people's attention."
      />

      <Stack spacing={30} style={{ margin: '60px 0' }}>
        <RadioTile
          icon={<Icon as={VscNotebookTemplate} />}
          title="Create Job From External Web Resource"
          value={importType}
          name="external"
          onChange={setImportType}
        >
          Provide an external website link with your job description and let the system fill out all the details to get you started quickly.
        </RadioTile>
        <RadioTile
          icon={<Icon as={VscNotebookTemplate} />}
          title="Create Job From Job Description Documents"
          value={importType}
          name="document"
          onChange={setImportType}
        >
          Cut and paste your job description from external documents or websites and let the system fill out all the details to get you started quickly.
        </RadioTile>        
        <RadioTile
          icon={<Icon as={VscFile} />}
          title="Create Job From Scratch"
          value={importType}
          name="manual"
          onChange={setImportType}
        >
          Create a blank job and either cut and paste or type in all your job details manually.
        </RadioTile>      
      </Stack>

      <Divider />

      <WizardButtonGroup numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={handleSubmit} />
    </Form>
</>);
};
export default JobTypeForm;
