import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { connectWebsocket } from 'utils/websockets';

import { Loader, Row } from 'rsuite';

import SelectableCandidateCard from "components/candidate/SelectableCandidateCard";

type Props = {
  jobId: string
  state: string
  loading: boolean
  loaded: () => void
  searchParams: URLSearchParams
  activeTab: string
  setActiveTab: (activeTab: string) => void
  likeCandidate: (candidate_id: string, deleteCandidate: () => void) => void
  hideCandidate: (candidate_id: string, deleteCandidate: () => void) => void  
  scheduleCandidate: (candidate_id: string, deleteCandidate: () => void) => void  
}

function JobCandidateTab( { jobId, state, loaded, loading, searchParams, likeCandidate, hideCandidate, scheduleCandidate, activeTab, setActiveTab } : Props ) {
  const [websocketTracker, setWebsocketTracker] = useState<any>();    
  const [updateCandidateCard, setUpdateCandidateCard] = useState(true);    

  var jobCandidateUrl: any = null
  if (state) {
    jobCandidateUrl = `/jobs/${jobId}/candidates/?states=${state}`
  } else {
    jobCandidateUrl = `/jobs/${jobId}/candidates/`
  }
  const { data: jobCandidates, trigger } = useSWRMutation<any>(jobCandidateUrl, fetcher, { onSuccess: 
    (data, key, config) => {
      loaded()
    }
  })

  const { hash } = useLocation();
  useEffect(() => {
    if (jobCandidates === undefined) {
      trigger()
    } else {
      var tracker:any = websocketTracker
      if (!tracker) {
        tracker = connectWebsocket(`${process.env.REACT_APP_WEBSOCKET_URL}/emails/engagements/jobs/${jobId}/`)
        tracker.onmessage = function (e:any) {
          setUpdateCandidateCard(true)
        }
        setWebsocketTracker(tracker)
      }      
    }

    const anchorElement = document.getElementById(hash.substring(1));
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (window.location.hash) {
        window.history.replaceState("", document.title, window.location.pathname);
      }
    }
  })

   if (jobCandidates === undefined || loading) {
    return (
      <div className='text-center m-3'><Loader size="md" content="Fetching candidate details..."/></div>
    );
  }
  if (jobCandidates.results.length === 0) {
    if (activeTab === 'new') {
      return (
        <div className='text-center m-3'>
          <strong>No candidates found. Please adjust your search filter settings.</strong>
        </div>
    )} else {
      return (
        <div className='text-center m-3'>
          <strong>No candidates selected.</strong>
        </div>      
    )}
  }  

  function deleteCandidate(candidateId: string) {
    trigger()
  }  

  return (
    <div>
      {jobCandidates.results.map((jobCandidate: any, index: number) => (
        <Row id={jobCandidate.candidate.id}>
          <SelectableCandidateCard 
            jobId={jobId}
            searchContext='jobs'
            searchParams={searchParams.toString()}
            activeTab={activeTab}
            updateCandidateCard={updateCandidateCard}
            setUpdateCandidateCard={setUpdateCandidateCard}
            setActiveTab={setActiveTab}
            likeCandidate={() => likeCandidate(jobCandidate.candidate.id, () => deleteCandidate(jobCandidate.candidate.id))} 
            hideCandidate={() => hideCandidate(jobCandidate.candidate.id, () => deleteCandidate(jobCandidate.candidate.id))} 
            scheduleCandidate={() => scheduleCandidate(jobCandidate.candidate.id, () => deleteCandidate(jobCandidate.candidate.id))} 
            resolveCandidate={() => deleteCandidate(jobCandidate.candidate.id)} 
            jobCandidate={jobCandidate} />
        </Row>
      ))}
    </div>
  );
}

export default JobCandidateTab;