import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import authSlice from "store/slices/auth";
import configurationSlice from "store/slices/configuration";
import { getRequest, postRequest } from "utils/axios";
import { useNavigate, Link } from "react-router-dom";

import logo from "assets/images/talentwell-logo-with-text.png";
import splash from "assets/images/hiring-manager-mobile-candidate.png";

import Image from 'react-bootstrap/Image';
import { Grid, Row, Col, Button, Stack, Header } from 'rsuite';

import { sendGAUserSignIn } from 'components/tracker/GoogleAnalyticsTracker';


export default function SignIn() {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleAuthorizationUrl = process.env.REACT_APP_API_URL + '/auth/social/google/login/'

  const handleLogin = (username: any, password: any) => {
      postRequest('/auth/login/', { username, password, type: 'login' })
      .then((res) => {
        sessionStorage.removeItem('subscription_options_shown');
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setUser(res.data.user));
        const configurationPromise = getRequest('/system/configurations/ui/')
        configurationPromise.then((response) => {
          dispatch(configurationSlice.actions.setConfiguration(response.data));
        })   
        sendGAUserSignIn(res.data, "account")
        navigate("/", {
          state: {
            userId: res.data.id
          }
        });
      })
      .catch((err) => {
        setMessage("Wrong combination of username and password");
      });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      handleLogin(values.username, values.password);
    },
    validationSchema: Yup.object({
      username: Yup.string().trim().required("Username is required"),
      password: Yup.string().trim().required("Password is required"),
    }),
  });

  return (
    <Grid fluid>
        <Header style={{padding: "20px 80px 0px 80px"}}>
        <Row>
          <Col xsHidden={true} smHidden={true} mdHidden={true} lg={16} xl={18} xxl={19}>
            <a href="https://talentwell.ai">
              <Image src={logo} fluid loading="lazy" style={{ width: "350px", marginBottom: "20px" }}/>
            </a>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={5} >
            <div className="center-buttons">
              <Stack spacing={1} justifyContent="flex-start" style={{paddingRight: "25px"}}>
                <Button href='https://talentwell.ai' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px"}}>Home</Button>
                <Button href='https://talentwell.ai/about' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px"}}>About</Button>
                <Button href='https://talentwell.ai/pricing' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px"}}>Pricing</Button>
                <Button href='https://talentwell.ai/careers' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px"}}>Careers</Button>
                <Button href='https://talentwell.ai/contact' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px"}}>Support</Button>
                <Button href='https://app.talentwell.ai/signin' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px"}}>Sign In</Button>
              </Stack>
            </div>
          </Col>
        </Row>
      </Header>        
      <Row>
        <Col xsHidden={true} smHidden={true} mdHidden={true} lg={12} xl={12} xxl={12}>
          <div className="center-container" style={{paddingLeft: "80px"}}>
            <Image src={splash} fluid loading="lazy" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
          <div className="center-container">
            <form onSubmit={formik.handleSubmit}>
              <div className="signin-container">
                <a href="https://talentwell.ai">
                  <Image src={logo} fluid loading="lazy" style={{ width: "300px", marginBottom: "20px" }}/>
                </a>
                <input
                  name="username" 
                  type="text" 
                  id="username" 
                  placeholder="Email" 
                  autoComplete="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username} />
              
                <input
                  name="password" 
                  type="password" 
                  id="password" 
                  placeholder="Password" 
                  autoComplete="current-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password} />

                <div className="d-grid">
                  <Button type="submit">Sign In</Button>
                </div>

                <div style={{ margin: "1em", color: "red" }}>{message}</div>
                {formik.touched.username && formik.errors.username ? <div style={{ margin: "1em", color: "red" }}>{formik.errors.username}</div> : null}
                {formik.touched.password && formik.errors.password ? <div style={{ margin: "1em", color: "red" }}>{formik.errors.password}</div> : null}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                  Don't have an account?&nbsp;<strong><Link to="/signup">Sign&nbsp;Up</Link></strong>
                </div>
                <div style={{ fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                  <strong><Link to="/resetpassword">Forgot&nbsp;Password?</Link></strong>
                </div>
              </div>
            </form>

            {process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "TRUE" &&
              <div style={{ marginTop: "1em", textAlign: "center"}}>
                <a href={googleAuthorizationUrl} className="google-sign-in-button-image" style={{ boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1"}}/>
                  {/* https://developers.google.com/identity/branding-guidelines
                      https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid */ }
              </div>
            }
          </div>
        </Col>
      </Row>
      <Stack spacing={80} justifyContent="center">
        <Button href='https://talentwell.ai/terms-and-conditions' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Terms & Conditions</Button>
        <Button href='https://talentwell.ai/privacy-policy' appearance='link' style={{ fontFamily: "Poppins, sansSerif", fontWeight: "600", color: "#263c98", fontSize: "16px", textDecoration: "underline"}}>Privacy Policy</Button>
      </Stack>
    </Grid>
  );
}