import React from 'react';
import { Modal, Button, Divider } from 'rsuite';
import BundledEditor from 'BundledEditor.js';

type Props = {
  open: boolean;
  onClose: () => void;
  emailBody: string;
  isVideoMessage: boolean;
};

const EmailPreviewModal = ({ open, onClose, emailBody, isVideoMessage }: Props) => {
  // Define the tag you want to replace
  const tagToReplace = '{VIDEO_MESSAGE}';
  var replacementSnippet = ''

  if (isVideoMessage) {
    replacementSnippet = `
      <a href="https://rightone-prod-public.s3.us-west-2.amazonaws.com/static/common/avatars/templates/sample_preview.mp4?autoplay=1" target="_blank">
        <img src="https://rightone-prod-public.s3.us-west-2.amazonaws.com/static/common/avatars/templates/sample_preview.png" alt="Personalized video message" style="border: none; display: block; width: 200px; height: 112px;">
      </a>`
  }
  
  const modifiedEmailBody = emailBody.replace(tagToReplace, replacementSnippet);
  return (
    <Modal
      open={open}
      onClose={onClose}
      size="md"
      style={{
        zIndex: 1050,
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)', // More pronounced shadow
        backgroundColor: 'white', // Ensure the modal has a solid background
        borderRadius: '8px', // Optional: Add some border-radius for visual appeal
      }}
    >
      <Modal.Header>
        <Modal.Title>Email Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {replacementSnippet && <span style={{fontSize: "12px"}}>The video in this email is only a generic example. When sent to candidates, the final email will include your avatar, voice, and layout.</span>}
        <Divider />
        <BundledEditor
          initialValue={modifiedEmailBody}
          disabled={true}
          init={{
            inline: true,
            promotion: false,
            branding: false,
            height: 400,
            resize: true,
            menubar: false,
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="primary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailPreviewModal;
