import React, { useState, useEffect } from 'react';

import { MDBCardImage, MDBBadge } from 'mdb-react-ui-kit';
import { Avatar } from 'rsuite';
import { type Size } from 'rsuite/esm/AvatarGroup/AvatarGroup';

import { setActiveImageUrl } from 'utils/candidate_data_access';

interface Props {
    onClick?: () => void;
    size: Size
    candidate: any;
}

const CandidateAvatar = ({ onClick, size, candidate }: Props) => {
    const [avatarImage, setAvatarImage] = useState<any>(undefined);

    useEffect(() => { 
        setActiveImageUrl(candidate?.profile_images, setAvatarImage)
    })

    return (
        <div className='position-relative d-inline-block'>
            {candidate.is_on_watchlist && (
                <MDBBadge className='position-absolute top-0 start-0 translate-middle p-1 bg-success border border-light rounded-circle'>
                    <span className='visually-hidden'>Favorites</span>
                </MDBBadge>
            )}
            <Avatar size={size} onClick={onClick} circle src={avatarImage}/>
          </div>            
  );
};

export default CandidateAvatar;
