import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import { getRequest } from "utils/axios";

import { Steps, Divider, Panel } from 'rsuite';

import JobTypeForm from './JobTypeForm';
import JobExternalImportForm from './JobExternalImportForm';
import JobCutAndPasteImportForm from './JobCutAndPasteImportForm';
import JobManualImportForm from './JobManualImportForm';

import { JobRequest, JobResponse } from 'utils/types'

type props = {
  state: string
  type: string
  cloneId: string | undefined
}

const WizardForm = ({ state, type, cloneId }: props) => {
  const navigate = useNavigate();

  const numberOfSteps = 4
  const [currentStep, setNextStep] = useState(parseInt(state) <= 1 ? parseInt(state) : 0);
  const [importType, setImportType] = useState(type);
  const [jobRequest, setJobRequest] = useState<JobRequest>({
    id: '',
    name: '',
    description: '',
    description_url: '',
    role: '',
    visibility: 'business',
    template_id: undefined    
  });

  useEffect(() => {
    if (cloneId && jobRequest.template_id === undefined) {
      const cloneJobPromise = getRequest(`/jobs/${cloneId}/`)
      cloneJobPromise.then((response: any) => {
        const cloneJob = response.data as JobResponse
        setJobRequest({
          ...jobRequest,
          name: cloneJob.name + ' (COPY)',
          role: cloneJob.role,
          template_id: cloneJob.template_id
        });
      })
    }
  })

  function setParameterString(importType: string, currentStep: number, cloneId: any) {
    var parameterString = 'type=' + importType + '&state=' + currentStep
    if (cloneId)
      parameterString = parameterString + '&cloneId=' + cloneId
    window.history.replaceState(null, "Page Title", window.location.href.split('?')[0] + '?' + parameterString)
  }

  function chooseForm() {
    switch (currentStep) {
      case 0:
        setParameterString(importType, currentStep, cloneId)
        return <JobTypeForm numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} importType={importType} setImportType={setImportType}/>
      case 1:
        switch (importType) {
          case 'manual':
            setParameterString('manual', currentStep, cloneId)
            return <JobManualImportForm numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} jobRequest={jobRequest} setJobRequest={setJobRequest}/>
          case 'document':
            setParameterString('document', currentStep, cloneId)
            return <JobCutAndPasteImportForm numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} jobRequest={jobRequest} setJobRequest={setJobRequest}/>
          case 'external':
            setParameterString('external', currentStep, cloneId)
            return <JobExternalImportForm numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} jobRequest={jobRequest} setJobRequest={setJobRequest}/>
        }
        break;
      case 2:
        navigate('/jobs/' + jobRequest.id);
    }
  }

  return (
    <Panel style={{ background: '#fff' }}>
      <Steps current={currentStep}>
        <Steps.Item title="Creation Type" />
        <Steps.Item title="Job Creation" />
        <Steps.Item title="Completed" />
      </Steps>

      <Divider />
      <div className="wizard-form">
        {chooseForm()}
      </div>
    </Panel>
  );
};

export default WizardForm;
