import React from 'react';
import { Table, Panel } from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

const data = [
  {
    id: 1,
    url: '/jobs/270cbd6a-2235-49bf-acc0-071c4649d2e0',
    visits: '145',
    unique: '23',
    bounce: '1%',
    name: 'Senior Software Engineer'
  },
  {
    id: 2,
    url: '/jobs/270cbd6a-2235-49bf-acc0-071c4649d2e0',
    visits: '135',
    unique: '13',
    bounce: '1%',
    name: 'Senior Software Engineer'
  },
  {
    id: 3,
    url: '/jobs/270cbd6a-2235-49bf-acc0-071c4649d2e0',
    visits: '113',
    unique: '3',
    bounce: '1%',
    name: 'Data Scientist (LLM)'
  },
  {
    id: 4,
    url: '/jobs/270cbd6a-2235-49bf-acc0-071c4649d2e0',
    visits: '105',
    unique: '23',
    bounce: '1%',
    name: 'Machine Learning Engineer'
  },
  {
    id: 5,
    url: '/jobs/270cbd6a-2235-49bf-acc0-071c4649d2e0',
    visits: '75',
    unique: '2',
    bounce: '4%',
    name: 'Senior Product Designer'
  },
  {
    id: 6,
    url: '/jobs/270cbd6a-2235-49bf-acc0-071c4649d2e0',
    visits: '35',
    unique: '3',
    bounce: '11%',
    name: 'Frontend Developer'
  },
];

const DataTable = () => {
  return (
    <Panel className="card" header="Most Engaged Jobs">
      <Table height={300} data={data} rowKey="id">
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell>Job</HeaderCell>
          <Cell>
            {(rowData: any) => {
              return (
                <a href={rowData.url} target="_blank" rel="noreferrer">
                  {rowData.name}
                </a>
              );
            }}
          </Cell>
        </Column>

        <Column width={100}>
          <HeaderCell>Contacted</HeaderCell>
          <Cell dataKey="visits" />
        </Column>

        <Column width={100}>
          <HeaderCell>Responded</HeaderCell>
          <Cell dataKey="unique" />
        </Column>

        <Column width={100}>
          <HeaderCell>Scheduled</HeaderCell>
          <Cell dataKey="bounce" />
        </Column>
      </Table>
    </Panel>
  );
};

export default DataTable;
