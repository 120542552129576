import React, { useState, useEffect } from 'react';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import axios from 'axios';
import { postRequest } from "utils/axios";
import { connectWebsocket } from 'utils/websockets';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import 'styles/markdown.css'

import { Modal, Panel, Stack, Col, Row, Grid, IconButton, Form, useToaster, Loader, ButtonToolbar, Button } from 'rsuite';

import AIIcon from '@rsuite/icons/legacy/Gear';
import UserIcon from '@rsuite/icons/legacy/User';
import ClipboardCopyIcon from '@rsuite/icons/legacy/Copy';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import ChatInput from 'components/aichat/chat_input';

import {useSelector} from "react-redux";
import {RootState} from "store";
import { AnyNaptrRecord } from 'dns';

const DashboardContent = () => {
  const { data: chat_history, trigger: triggerHistoryUpdate } = useSWRMutation<any>(`/assistant/messages/`, fetcher)
  const user = useSelector((state: RootState) => state.auth.user)

  const toaster = useToaster();
  const [showIntroVideoModal, setShowIntroVideoModal] = useState(false);

  const [browserData, setBrowserData] = useState({
    time: new Date().toISOString(),
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ip: null,
    lan: navigator.language,
  });

  const [isUserChatMessageEnabled, setIsUserChatMessageEnabled] = useState<boolean>(true);
  const [chatLoaderMessage, setChatLoaderMessage] = useState<string>("Thinking ...");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [websocketTracker, setWebsocketTracker] = useState<any>();

  useEffect(() => {
    connectToChatChannel(false)

    if (chat_history === undefined) {
      triggerHistoryUpdate()
    } else {
      let drawerBody = document.getElementById('historyPanel');
      if (drawerBody) {
        drawerBody.scrollTop = drawerBody.scrollHeight
      }
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    const fetchIPDetails = async () => {
      if (!browserData.ip) {
        try {
          const ipResponse = await axios.get('https://api.ipify.org?format=json');
          const ip = ipResponse.data.ip;
          setBrowserData({ ...browserData, ip });
        } catch (error) {
          console.error('Error fetching IP details:', error);
        }
      }
    };

    fetchIPDetails();
  })

  // Handle link clicks and trigger modal
  const handleIntroVideoLinkClick = (event: any) => {
    if (event.currentTarget.getAttribute('href') === '#open-intro-video-modal') {
      event.preventDefault();
      setShowIntroVideoModal(true);
    }
  };  

  function connectToChatChannel(reconnect: boolean) {
    var tracker: any = websocketTracker
    if (!tracker || reconnect || tracker.readyState === WebSocket.CLOSED) {
      disconnectFromChatChannel()
      tracker = connectWebsocket(`${process.env.REACT_APP_WEBSOCKET_URL}/chats/users/`)
      setWebsocketTracker(tracker)
      tracker.onmessage = function (e: any) {
        const message = JSON.parse(e.data)
        if (message.action === "show_progress") {
          setChatLoaderMessage(message.message)
        }
      }
    }
  }

  function disconnectFromChatChannel() {
    var tracker: any = websocketTracker
    if (tracker) {
      tracker.close()
      setWebsocketTracker(null)
    }
  }

  function resetChat() {
    setIsUserChatMessageEnabled(false)
    postRequest(`/assistant/messages/reset/`).then(
      (response) => {
        triggerHistoryUpdate()
      }).catch(
        (error) => {
          toaster.push(<RequestErrorMessage error={error} toaster={toaster} />)
        }).finally(() => {
          setIsUserChatMessageEnabled(true)
        })
  }

  function renderMessageContent(content: any) {
    return <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[[remarkGfm, { singleTilde: false }]]}components={{
      a: ({ node, ...props }) => (
        <a {...props} onClick={handleIntroVideoLinkClick}>
          {props.children}
        </a>
      ),
    }}
  >{content}</Markdown>
  }

  function renderMessageDate(time: any) {
    return new Date(time).toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric', year: 'numeric' })
  }

  function renderMessage(message: any) {
    if (message.role === 'assistant') {
      return (<>
        <Row>
          <Col xs={2}><AIIcon /></Col>
          <Col xs={22}>
            <Stack direction='row' alignItems="stretch" justifyContent="space-between">
              <Stack.Item>
                <strong>Tally</strong>
              </Stack.Item>
              <Stack.Item>
                <span style={{ fontSize: "12px" }}>{renderMessageDate(message.created)}</span>
              </Stack.Item>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>{renderMessageContent(message.content)}</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>
            <IconButton circle icon={<ClipboardCopyIcon />} appearance="subtle" onClick={() => { navigator.clipboard.writeText(message.content) }} />
          </Col>
        </Row>
      </>)
    } else if (message.role === 'user') {
      return (<>
        <Row>
          <Col xs={2}><UserIcon /></Col>
          <Col xs={22}>
            <Stack direction='row' alignItems="stretch" justifyContent="space-between">
              <Stack.Item>
                <strong>You</strong>
              </Stack.Item>
              <Stack.Item>
                <span style={{ fontSize: "12px" }}>{renderMessageDate(message.created)}</span>
              </Stack.Item>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>{renderMessageContent(message.content)}</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>
            <IconButton circle icon={<ClipboardCopyIcon />} appearance="subtle" onClick={() => { navigator.clipboard.writeText(message.content) }} />
          </Col>
        </Row>
      </>)
    }
  }

  return (
    <Grid fluid>
      {user?.is_superuser && 
        <Row style={{ marginTop: "-50px", marginBottom: "15px", marginRight: "10px" }}>
          <Stack direction='row' justifyContent="flex-end" alignItems='flex-end'>
            <Stack.Item>
              <ButtonToolbar>
                <Button loading={!isUserChatMessageEnabled} appearance="primary" color="red" onClick={resetChat}>Reset</Button>
              </ButtonToolbar>
            </Stack.Item>
          </Stack>
        </Row>
      }
      <Row>
        <Col md={24}>
          <Panel
            id="chatPanel"
            className="body-panel"
            shaded
            style={{ marginTop: "10px", marginBottom: "10px", width: "100%", height: windowHeight - 200 }}>
            <Panel
              id="historyPanel"
              className="overflow-auto"
              style={{ height: windowHeight - 270 }}>
              {chat_history === undefined ? (
                <div className='text-center m-3'>
                  <Loader size="md" content="Gathering your chat history..." />
                </div>
              ) : (
                <Grid fluid>
                  {chat_history.map((message: any, index: number) => (
                    <div style={{ marginBottom: "40px" }}>{renderMessage(message)}</div>
                  ))}
                </Grid>
              )}
              {!isUserChatMessageEnabled &&
                <div className='text-center m-3'>
                  <Loader size="sm" content={<i>{chatLoaderMessage}</i>} />
                </div>
              }
            </Panel>
            <ChatInput 
              chatUrl="/assistant/messages/"
              triggerHistoryUpdate={triggerHistoryUpdate} 
              connectToChatChannel={() => connectToChatChannel(true)} 
              setIsChatMessageEnabled={setIsUserChatMessageEnabled}
              isChatMessageEnabled={isUserChatMessageEnabled} />
          </Panel>
        </Col>
      </Row>

      <Modal open={showIntroVideoModal} onClose={() => setShowIntroVideoModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title>TalentWell Intro Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <video
              width="100%"
              controls
              autoPlay
              style={{ display: 'block', maxHeight: '70vh', margin: '0 auto' }}
            >
              <source src="https://rightone-prod-public.s3.amazonaws.com/static/common/TalentWell_Intro_Video.mp4" type="video/webm" />
              Your browser does not support the video tag.
            </video>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowIntroVideoModal(false)} appearance="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>  
    </Grid>
  );
};

export default DashboardContent;
