import React, { useRef, useState, useEffect } from 'react';
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import authSlice from "store/slices/auth";
import { getRequest, postRequest, patchFileRequest } from "utils/axios";

import { MDBCardImage } from 'mdb-react-ui-kit';

import { Form, Loader, useToaster, Schema, Stack, Button, Checkbox, Nav, InputGroup, Grid, Row, Divider, Avatar, IconButton } from 'rsuite';
import CalendlyImage from 'assets/images/calendly-logo.png';
import GoogleImage from 'assets/images/web_light_sq_na.svg';
import PencilIcon from '@rsuite/icons/legacy/Pencil';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import AvatarBuilderDrawerView from 'components/video/AvatarBuilderDrawerView';

import BundledEditor from 'BundledEditor.js';

import { User } from 'types';

const ProfileContent = () => {
  const googleAuthorizationUrl = process.env.REACT_APP_API_URL + '/auth/social/google/email/';
  const googleRevokeAuthorizationUrl = process.env.REACT_APP_API_URL + '/auth/social/google/email/revoke/';
  const googleCallbackUrl = process.env.REACT_APP_API_URL + '/auth/social/google/callbacks/email/';

  const configuration = useSelector((state: RootState) => state.configuration);
  const navigate = useNavigate();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const location = useLocation();
  const formRef: any = useRef();
  const editorRef: any = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const authorizationUrl = process.env.REACT_APP_API_URL + '/integrations/calendly/auth/';
  const authenticationUrl = '/integrations/calendly/callbacks/auth/';
  const revokeAuthorizationUrl = '/integrations/calendly/revoke/';

  const user = useSelector((state: RootState) => state.auth.user);
  const user_url = `/users/${user?.id}/`;
  const { data, error, mutate } = useSWR<any>(user_url, fetcher);  
  const profile: User = data;

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [characterUrl, setCharacterUrl] = useState<string | null>(null);
  const [characterId, setCharacterId] = useState<string | null>(null);
  const [templateId, setTemplateId] = useState<string | null>(null);
  const [voiceId, setVoiceId] = useState<string | null>(null);

  const [initialSignature, setInitialSignature] = useState('');

  const [initialized, setInitialized] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    username: '',
    first_name: '',
    last_name: '',
    role: '',
    country_iso: '',
    state_iso: '',
    city: '',
    email: '',
    mobile: '',
    calendly_calendar: '',
    html_email_signature: ''
  });

  const [fileInfo, setFileInfo] = useState<any>();

  useEffect(() => {
    const clearSearchParams = () => {
      navigate({
        pathname: location.pathname,
        search: '',
      }, { replace: true });
    };

    if (!characterId && !voiceId && !templateId) {
      getRequest('/integrations/hour-one/avatars/').then((res) => {
        setCharacterId(res.data.character_id);
        setVoiceId(res.data.voice_id);
        setTemplateId(res.data.template_id);
      })
    }

    if (profile && !initialized) {
      setInitialized(true);
      setInitialSignature(profile.html_email_signature);
      setFormValue({
        username: profile.username,
        first_name: profile.first_name,
        last_name: profile.last_name,
        role: profile.role,
        country_iso: profile.country_iso,
        state_iso: profile.state_iso,
        city: profile.city,
        email: profile.email,
        mobile: profile.mobile,
        calendly_calendar: profile.calendly?.calendar ? profile.calendly?.calendar : 'introduction',
        html_email_signature: profile.html_email_signature
      });

      const redirectParameters: any = location.search;
      if (redirectParameters && redirectParameters.startsWith('?state=')) {
        getRequest(googleCallbackUrl + redirectParameters).then((res) => {
          toaster.push(<RequestSuccessMessage message="Candidate outreach with Gmail successfully enabled. Pro tip: Include a Calendly link in your automated outreach emails to make scheduling even easier! Just click Authorize Calendly." toaster={toaster} />);
        }).catch((error) => {
          toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
        }).finally(() => {
          mutate();
          clearSearchParams();
        });
      } else {
        const calendlyCode: any = searchParams.get('code');
        if (calendlyCode) {
          getRequest(authenticationUrl + '?code=' + calendlyCode).then((response) => {
            mutate();
            toaster.push(<RequestSuccessMessage message='Thank you for authorizing Calendly. We are now ready to schedule potential candidates for you.' toaster={toaster} />);
          }).catch((error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
          }).finally(() => {
            clearSearchParams();
          });
        }
      }
    }
  }, [profile, initialized, location, navigate, searchParams, googleCallbackUrl, authenticationUrl, toaster, mutate]);

  if (!profile)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching profile details..." />
      </div>
    );

  function revokeCalendlyAuthorization() {
    postRequest(revokeAuthorizationUrl).then((response) => {
      data.calendly = null;
      mutate();
      toaster.push(<RequestSuccessMessage message='Successfully revoked authorization.' toaster={toaster} />);
      dispatch(authSlice.actions.setUser(profile));
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    });
  }

  function revokeGoogleAuthorization() {
    postRequest(googleRevokeAuthorizationUrl).then((response) => {
      data.email_provider = null;
      mutate();
      toaster.push(<RequestSuccessMessage message='Successfully revoked authorization.' toaster={toaster} />);
      dispatch(authSlice.actions.setUser(profile));
    }).catch((error) => {
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    });
  }

  const { StringType } = Schema.Types;
  const model = Schema.Model({
    username: StringType().isRequired("This field is required"),
    first_name: StringType().isRequired("This field is required"),
    last_name: StringType().isRequired("This field is required"),
    role: StringType().isRequired("This field is required"),
    email: StringType().isEmail().isRequired("This field is required"),
    calendly_calendar: StringType().isRequired("This field is required"),
  });

  const handleSubmit = (event: any) => {
    if (characterId && !voiceId) {
      toaster.push(<RequestSuccessMessage message='You are missing a voice for your avatar! Please choose one before updating profile.' toaster={toaster} />);
      return;
    }

    if (!characterId && voiceId) {
      toaster.push(<RequestSuccessMessage message='Although you already chose a voice, you are missing a character for your avatar! Please choose one before updating profile.' toaster={toaster} />);
      return;
    }

    if (characterId && voiceId && !templateId) {
      toaster.push(<RequestSuccessMessage message='Although you already chose a character and a voice for your avatar, you are missing a template! Please choose one before updating profile.' toaster={toaster} />);
      return;
    }

    if (formRef.current.check()) {
      var profileUpdateRequest: any = null;

      if (fileInfo) {
        profileUpdateRequest = {
          username: formValue.username,
          first_name: formValue.first_name,
          last_name: formValue.last_name,
          role: formValue.role,
          country_iso: formValue.country_iso,
          state_iso: formValue.state_iso,
          city: formValue.city,
          email: formValue.email,
          mobile: formValue.mobile,
          calendly: {
            calendar: formValue.calendly_calendar
          },
          html_email_signature: formValue.html_email_signature,
          image: fileInfo
        };
      } else {
        profileUpdateRequest = {
          first_name: formValue.first_name,
          last_name: formValue.last_name,
          role: formValue.role,
          country_iso: formValue.country_iso,
          state_iso: formValue.state_iso,
          city: formValue.city,
          email: formValue.email,
          mobile: formValue.mobile,
          calendly: {
            calendar: formValue.calendly_calendar
          },
          html_email_signature: formValue.html_email_signature
        };
      }

      const patchPromise = patchFileRequest(`/users/${profile.id}/`, profileUpdateRequest);
      patchPromise.then((response) => {
        mutate();
        toaster.push(<RequestSuccessMessage message='Profile successfully updated' toaster={toaster} />);
      }).catch((error) => {
        toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
      });
    }

    // Get Avatar information
    const payload = {
      character_id: characterId,
      voice_id: voiceId,
      template_id: templateId
    };
    postRequest('/integrations/hour-one/avatars/', payload);
  };

  const handleChange = (record: any) => {
    setFormValue({
      ...formValue,
      username: record.username,
      first_name: record.first_name,
      last_name: record.last_name,
      role: record.role,
      country_iso: record.country_iso,
      state_iso: record.state_iso,
      city: record.city,
      email: record.email,
      mobile: record.mobile,
      calendly_calendar: record.calendly_calendar
    });
  };

  const handleSignatureUpdate = () => {
    setFormValue({ ...formValue, 
      html_email_signature: editorRef.current.getContent()
    });
  };

  const buildImageSource = () => {
    var imageSource = null;
    if (fileInfo) {
      try {
        imageSource = URL.createObjectURL(fileInfo);
      } catch (err) {
        imageSource = profile.image;
      }
    } else {
      imageSource = profile.image;
    }
    return imageSource;
  };

  function contactSales() {
    toaster.push(<RequestSuccessMessage message='Contact customer support under Account->Support for email domain configuration through DNS MX records.' toaster={toaster} />);
  }

  function comingSoon() {
    toaster.push(<RequestSuccessMessage message='Microsoft email send support is coming soon.' toaster={toaster} />);
  }

  function upgradeToPaidPlan() {
    toaster.push(<RequestSuccessMessage message='Upgrade to a paid plan to enable Calendly integration.' toaster={toaster} />);
  }

  const formFieldSize = 200;

  const handleImageClick = () => {
    document.getElementById('file-input')?.click();
  };

  return (
    <Form style={{ width: '100%' }}
      ref={formRef}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onCheck={setFormError}
      formValue={formValue}
      model={model}
      fluid>

      <Stack spacing={50} direction='row' alignItems='flex-start' justifyContent='space-between'>
        <Stack.Item>
          <Stack spacing={50} direction='row' alignItems='flex-start' justifyContent='flex-start'>
            <Stack.Item>
              <Grid fluid>
                <Row style={{ alignContent: "center", position: 'relative' }}>
                  <MDBCardImage loading="lazy" position="top mb-2 rounded-circle shadow-4-strong" style={{ height: '150px', width: '150px', cursor: 'pointer' }} src={buildImageSource()} onClick={handleImageClick} />
                  <IconButton
                    size="lg"
                    circle
                    icon={<PencilIcon style={{ fontSize: '24px', color: 'darkgrey' }}/>}
                    appearance="ghost"
                    style={{ border: '2px solid darkgrey', background: "white", position: 'absolute', bottom: '10px', right: '10px', height: '35px', width: '35px', cursor: 'pointer' }}
                    onClick={handleImageClick}
                  />
                  <input type="file" id="file-input" style={{ display: 'none' }} accept="image/png, image/jpeg" onChange={(e) => setFileInfo(e.target.files && e.target.files.length > 0 ? e.target.files[0] : null)} />
                </Row>
                <Row style={{ textAlign: "center" }}>
                  Profile Photo<br/><span style={{ fontSize: '12px' }}>(Click to change)</span>
                </Row>
              </Grid>
            </Stack.Item>
            <Stack.Item>
              <AvatarBuilderDrawerView
                open={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
                setCharacterUrl={setCharacterUrl}
                setCharacterId={setCharacterId}
                setTemplateId={setTemplateId}
                setVoiceId={setVoiceId}
                characterId={characterId}
                templateId={templateId}
                voiceId={voiceId} />
              <Grid fluid>
                <Row style={{ alignContent: "center", position: 'relative' }}>
                  <Avatar
                    size="lg"
                    circle
                    style={{
                      height: '150px',
                      width: '150px',
                      boxShadow: '0 0 15px rgba(0,0,0,0.4)',
                      overflow: 'hidden'  // Ensure video is clipped to the character shape
                    }}
                    onClick={() => setDrawerOpen(true)}
                  >
                    {characterUrl &&
                      <video
                        key={characterUrl}
                        width="150px"
                        height="150px"
                        autoPlay
                        loop
                        muted
                        style={{
                          display: 'block',
                          objectFit: 'cover'  // Ensure video covers the entire character
                        }}>
                        <source src={characterUrl} type="video/webm" />
                        Your browser does not support the video tag.
                      </video>
                    }
                  </Avatar>
                  <IconButton
                    size="lg"
                    circle
                    icon={<PencilIcon style={{ fontSize: '24px', color: 'darkgrey' }}/>}
                    appearance="ghost"
                    style={{ border: '2px solid darkgrey', background: "white", position: 'absolute', bottom: '10px', right: '10px', height: '35px', width: '35px', cursor: 'pointer' }}
                    onClick={() => setDrawerOpen(true)}
                  />
                </Row>
                <Row style={{ textAlign: "center" }}>
                  Avatar<br/><span style={{ fontSize: '12px' }}>(Click to change)</span>
                </Row>
              </Grid>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Button appearance="primary" type='submit'>Apply Profile Changes</Button>
        </Stack.Item>
      </Stack>

      <Divider />

      <p>Member since: {new Date(profile.created).toLocaleDateString()}</p>
      {profile.last_signin_time ?
        (<p>Last sign in: {new Date(profile.last_signin_time).toLocaleString()}</p>)
        :
        (<p>Welcome to TalentWell. This is the first time you have signed in.</p>)
      }
      <h5>Name</h5>
      <Form.Group controlId="name">
        <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
          <Stack.Item grow={1} basis={formFieldSize.toString()}>
            <Form.ControlLabel>First Name<sup>*</sup></Form.ControlLabel>
            <Form.Control name="first_name" />
          </Stack.Item>
          <Stack.Item grow={1} basis={formFieldSize.toString()}>
            <Form.ControlLabel>Last Name<sup>*</sup></Form.ControlLabel>
            <Form.Control name="last_name" />
          </Stack.Item>
        </Stack>
      </Form.Group>

      <Form.Group controlId="username">
        <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
          <Stack.Item grow={1} basis={formFieldSize.toString()}>
            <Form.ControlLabel>Username<sup>*</sup></Form.ControlLabel>
            <Form.Control name="username" readOnly />
          </Stack.Item>
          <Stack.Item grow={1} basis={formFieldSize.toString()}>
            <Form.ControlLabel>Role/Title<sup>*</sup></Form.ControlLabel>
            <Form.Control name="role" />
          </Stack.Item>
        </Stack>
      </Form.Group>

      <Form.Group controlId="address">
        <h5>Your Location</h5>
        <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
          <Stack.Item grow={1} basis={formFieldSize.toString()}>
            <Form.ControlLabel>City</Form.ControlLabel>
            <Form.Control name="city" />
          </Stack.Item>
          <Stack.Item basis={(formFieldSize / 3).toString()}>
            <Form.ControlLabel>State</Form.ControlLabel>
            <Form.Control name="state_iso" />
          </Stack.Item>
          <Stack.Item basis={(formFieldSize / 3 * 2 - 20).toString()}>
            <Form.ControlLabel>Country</Form.ControlLabel>
            <Form.Control name="country_iso" />
          </Stack.Item>
        </Stack>
      </Form.Group>

      <Form.Group controlId="contact">
        <h5>Contact</h5>
        <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
          <Stack.Item grow={1} basis={formFieldSize.toString()}>
            <Form.ControlLabel>Email<sup>*</sup></Form.ControlLabel>
            <Form.Control name="email" readOnly />
          </Stack.Item>
          <Stack.Item basis={formFieldSize.toString()}>
            <Form.ControlLabel>Mobile</Form.ControlLabel>
            <Form.Control name="mobile" />
          </Stack.Item>
          {!profile?.calendly && (
            <Stack.Item basis={formFieldSize.toString()}>
              <Nav style={{ marginTop: '26px' }}>
                {configuration.features.calendly_support ?
                  <Nav.Item href={authorizationUrl} icon={<img style={{ height: '25px' }} src={CalendlyImage} />}>Authorize Calendly</Nav.Item>
                  :
                  <Nav.Item onClick={upgradeToPaidPlan} icon={<img style={{ height: '25px' }} src={CalendlyImage} />}>Authorize Calendly</Nav.Item>
                }
              </Nav>
            </Stack.Item>
          )}
        </Stack>
      </Form.Group>
      {profile?.calendly && (
        <Form.Group controlId="name">
          <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='flex-start' wrap={true}>
            <Stack.Item basis={formFieldSize.toString()}>
              <Form.ControlLabel>Calendly</Form.ControlLabel>
              <InputGroup inside>
                <InputGroup.Addon>{profile?.calendly.scheduling_url + '/'}</InputGroup.Addon>
                <Form.Control name="calendly_calendar" />
              </InputGroup>
              <Form.HelpText style={{ maxWidth: '400px' }}>Please either create a calendar event called introduction in Calendly or update
                the field with your preferred Calendly calender event. This calendar allows
                candidates you contacted to schedule introduction calls with you.
              </Form.HelpText>
            </Stack.Item>
            <Stack.Item basis={formFieldSize.toString()}>
              <Nav style={{ marginTop: '26px' }}>
                <Nav.Item onClick={revokeCalendlyAuthorization} icon={<img style={{ height: '25px' }} src={CalendlyImage} />}>Revoke Authorization</Nav.Item>
              </Nav>
            </Stack.Item>
          </Stack>
        </Form.Group>
      )}

      <Form.Group controlId="email_outreach">
        <h5 style={{ marginBottom: "15px" }}>Email Outreach</h5>
        {profile?.email_provider ?
          <Stack style={{ marginTop: "10px" }} spacing={50} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
            {profile?.email_provider === 'google' &&
              <Stack.Item basis={formFieldSize.toString()}>
                <Nav style={{ marginTop: '26px' }}>
                  <Nav.Item onClick={revokeGoogleAuthorization} icon={<img style={{ height: '40px' }} src={GoogleImage} />}>Revoke Authorization</Nav.Item>
                </Nav>
              </Stack.Item>
            }
            {profile?.email_provider === 'dns' &&
              <Stack.Item basis={formFieldSize.toString()}>
                <div style={{ textAlign: "center" }}>
                  <span className="dns-mx-record-icon" style={{ blockSize: "40px", boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1" }} />
                  <p>DNS</p>
                </div>
              </Stack.Item>
            }
          </Stack>
          :
          <>
            <Stack style={{ marginTop: "10px" }} spacing={50} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
              <Stack.Item>
                <div style={{ textAlign: "center" }}>
                  <span onClick={contactSales} title="Contact customer support under Account->Support!" className="dns-mx-record-icon" style={{ blockSize: "40px", boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1" }} />
                  <p>DNS</p>
                </div>
              </Stack.Item>
              <Stack.Item>
                <div style={{ textAlign: "center" }}>
                  {process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "TRUE" ?
                    <a href={googleAuthorizationUrl} className="google-icon" style={{ boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1" }} />
                    :
                    <span onClick={comingSoon} title="Coming soon!" className="google-icon" style={{ boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1" }} />
                  }
                  <p>Google</p>
                </div>
              </Stack.Item>
              <Stack.Item>
                <div style={{ textAlign: "center" }}>
                  <span onClick={comingSoon} title="Coming soon!" className="microsoft-icon" style={{ blockSize: "40px", boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.1" }} />
                  <p>Microsoft</p>
                </div>
              </Stack.Item>
            </Stack>
            <Form.HelpText>
              Select your email provider for TalentWell to send emails on your behalf.
            </Form.HelpText>
          </>
        }
      </Form.Group>

      <Form.Group controlId="email_signature">
        <h5>Email Signature</h5>
        <div style={{marginTop: "10px"}}>
          <BundledEditor
                name="body"
                onInit={(evt: any, editor: any) => {
                  editorRef.current = editor;
                }}
                onChange={handleSignatureUpdate}
                initialValue={initialSignature}
                newvValue={formValue.html_email_signature}
                init={{
                  promotion: false,
                  branding: false,
                  height: 400,
                  resize: true,
                  menubar: false,
                  plugins: [
                    'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                    'searchreplace', 'table', 'wordcount', 'preview'
                  ],
                  toolbar: 'undo redo | preview | blocks | ' +
                    'fontfamily fontsize bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-weight:300; font-size:12px }'
                }}
                />        
        </div>   
        <Form.HelpText>
        Create or paste your signature here. Candidates will see this signature in the emails they receive from you.
        </Form.HelpText>
      </Form.Group>

      {profile?.is_staff && (<>
        <h5>Permissions</h5>
        <Checkbox defaultChecked={profile?.is_superuser} readOnly>Superuser</Checkbox>
        <Form.HelpText>
          Designates that this user has all permissions without explicitly assigning them. This can only be configured by TalentWell IT.
        </Form.HelpText>

        <Stack spacing={10} direction='column' alignItems='flex-end' justifyContent='space-between'>
          <Button appearance="primary" type='submit'>Apply Profile Changes</Button>
        </Stack>
      </>)}
    </Form>
  );
};

export default ProfileContent;
