import React from "react";
import { useNavigate } from 'react-router';

import { Panel, Breadcrumb, Stack } from 'rsuite';

import SupportContent from './SupportContent'

const Support = () => {
  const navigate = useNavigate()

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <h3 className="title">Support</h3>
      </Stack>

      <Breadcrumb>
        <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Support</Breadcrumb.Item>
      </Breadcrumb>
    </>}>
    </Panel>

    <Panel className='body-panel'  shaded>
      <SupportContent />
    </Panel>
</>)};

export default Support;