import { Row } from 'rsuite';

interface Props {
    candidate: any;
}

const CandidateRecommendationHistory = ({ candidate }: Props) => {
    const recommendations = candidate?.recommendations

    if (recommendations !== null) {
        return (<>
            {recommendations.map((recommendation: any, index: number) => (
                <Row key={index}>
                  <div className="profile-section-card__contents" style={{marginLeft: '6px', marginBottom: '20px'}}>
                    {recommendation.endorser_url ? (
                          <a className="fw-light fs-6" href={recommendation.endorser_url} target="_blank">{recommendation.full_name}</a>
                        ) : (
                          <span className="fw-normal fs-6">{recommendation.full_name}</span>
                    )}
                    <p className="fw-light">{recommendation.endorsement}</p>
                  </div>
                </Row>              
              ))}      
        </>);
    } else {
        return (<>No known recommendations</>)        
    }
};

export default CandidateRecommendationHistory;
