import React, { useState } from "react";

import { Panel, Breadcrumb, Stack, Button, ButtonGroup } from 'rsuite';

import subDays from 'date-fns/subDays';
import AdminAnalyticsContent from './AdminAnalyticsContent'
import AnalyticsDateRangePicker from 'components/analytics/AnalyticsDateRangePicker'

function setStartTime(date: Date) {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

function setEndTime(date: Date) {
  date.setHours(11)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(999)
  return date
}

const AdminAnalytics = () => {
  const defaultDateRange = [setStartTime((subDays(new Date(), 29))), new Date()]

  const [timeUnit, setTimeUnit] = useState<any>('month')
  const [dateRange, setDateRange] = useState<any>(defaultDateRange)
  const [dateRangeChanged, setDateRangeChanged] = useState<boolean>(true)

  function handleDateChange(newDateRange: any) {
    setDateRange(newDateRange)
    setDateRangeChanged(true)
  }

  function handleUnitChange(newUnit: any) {
    setTimeUnit(newUnit)
    setDateRangeChanged(true)
  }

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <Stack.Item>
          <h3 className="title">Analytics</h3>
        </Stack.Item>
        <Stack.Item>
          <Stack spacing={10} direction='row' justifyContent="space-between">
            <Stack.Item>
              <ButtonGroup>
                <Button active={timeUnit === 'hour'} onClick={() => handleUnitChange('hour')}>Hour</Button>
                <Button active={timeUnit === 'day'} onClick={() => handleUnitChange('day')}>Day</Button>
                <Button active={timeUnit === 'month'} onClick={() => handleUnitChange('month')}>Month</Button>
                <Button active={timeUnit === 'year'} onClick={() => handleUnitChange('year')}>Year</Button>
              </ButtonGroup>
            </Stack.Item>
            <Stack.Item>
              <AnalyticsDateRangePicker  handleDateChange={handleDateChange} dateRange={dateRange} />
            </Stack.Item>
            <Stack.Item>
              <Button appearance="primary"  onClick={() => setDateRangeChanged(true)}>Refresh</Button>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>

      <Breadcrumb>
        <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/'>Admin</Breadcrumb.Item>
        <Breadcrumb.Item active>Analytics</Breadcrumb.Item>
      </Breadcrumb>
    </>}>
    </Panel>
      
    <AdminAnalyticsContent timeUnit={timeUnit} dateRange={dateRange} dateRangeChanged={dateRangeChanged} setDateRangeChanged={setDateRangeChanged}/>
</>)};

export default AdminAnalytics;