import React, { useRef, useState, useEffect } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { postRequest } from "utils/axios";
import {Form, useToaster, ButtonToolbar, Button, IconButton, Grid, Loader, Row, Col, Panel, Stack, Modal} from 'rsuite';

import BundledEditor from 'BundledEditor.js'

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import {useSelector} from "react-redux";
import {RootState} from "store";

import TextArea from 'components/elements/Textarea'
import AIIcon from '@rsuite/icons/legacy/Gear';
import UserIcon from '@rsuite/icons/legacy/User';
import ReturnIcon from '@rsuite/icons/SortUp';
import ClipboardCopyIcon from '@rsuite/icons/legacy/Copy';
import ClipboardPasteIcon from '@rsuite/icons/legacy/Paste';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'


type Props = {
  jobId: string
  activeTabChanged: boolean
  setActiveTabChanged: (value: boolean) => void
};


const AITemplateBuilderTab = ({ jobId, activeTabChanged, setActiveTabChanged }: Props) => {
  const user = useSelector((state: RootState) => state.auth.user)
  const { data: training_history, trigger: triggerHistoryUpdate } = useSWRMutation<any>(`/jobs/${jobId}/campaigns/templates/builder/history/`, fetcher)
  const { data: guidelines, trigger: triggerGuidelinesUpdate } = useSWRMutation<any>(`/jobs/${jobId}/campaigns/templates/guidelines/read/`, fetcher)
   
  const toaster = useToaster();
  const emailTemplateEditorRef: any = useRef();
  
  const [isUserChatMessageEnabled, setIsUserChatMessageEnabled] = useState<boolean>(true);
  const [isWritingSampleEmail, setIsWritingSampleEmail] = useState<boolean>(false);
  const [userChatMessage, setUserChatMessage] = useState<any>();
  const [emailTemplate, setEmailTemplate] = useState<any>();
  const [emailGuidelines, setEmailGuidelines] = useState<any>();

  const [sampleEmailSubject, setSampleEmailSubject] = useState<string>('');
  const [sampleEmailHtmlBody, setSampleEmailHtmlBody] = useState<string>('');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);  

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (guidelines === undefined) {
      triggerGuidelinesUpdate()
    } else {
      if (emailTemplate === undefined) {
        setEmailTemplate(guidelines.template)
        setEmailGuidelines(guidelines.guidelines)
      }
    }
    if (training_history === undefined) {
      triggerHistoryUpdate()
    } else {
      let drawerBody = document.getElementById('historyPanel');
      if (drawerBody) {
        drawerBody.scrollTop = drawerBody.scrollHeight
      }
    }
    if (activeTabChanged) {
      triggerHistoryUpdate()
      triggerGuidelinesUpdate()
      setActiveTabChanged(false)
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);    
  });    

  function handleUserChatMessage() {
    setIsUserChatMessageEnabled(false)
    const training_message = {
      message: userChatMessage
    }
    postRequest(`/jobs/${jobId}/campaigns/templates/builder/`, training_message).then(
      (response) => {
        triggerHistoryUpdate()
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    }).finally(() => {
      setIsUserChatMessageEnabled(true)      
      setUserChatMessage('')
    })        
  }

  function buildGuidelines() {
    setIsUserChatMessageEnabled(false)
    postRequest(`/jobs/${jobId}/campaigns/templates/builder/guidelines/`).then(
      (response) => {
        triggerHistoryUpdate()
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    }).finally(() => {
      setIsUserChatMessageEnabled(true)      
    })        
  }

  function resetChat() {
    setIsUserChatMessageEnabled(false)
    postRequest(`/jobs/${jobId}/campaigns/templates/builder/reset/`).then(
      (response) => {
        triggerHistoryUpdate()
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    }).finally(() => {
      setIsUserChatMessageEnabled(true)      
    })        
  }

  function handleTemplateAndGuidelinesUpdate() {
    const guidelinesRequest = {
      "template": emailTemplateEditorRef.current.getContent(),
      "guidelines": emailGuidelines
    }
    postRequest(`/jobs/${jobId}/campaigns/templates/guidelines/write/`, guidelinesRequest).then(
      (response) => {
        toaster.push( <RequestSuccessMessage message="Template and guidelines updated successfully" toaster={toaster}/> )
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    }).finally(() => {
      setIsUserChatMessageEnabled(true)      
    })            
  }

  const copyFromClipboardToTemplate = async () => {
    const clipboard = navigator.clipboard;
    const text = await clipboard.readText();
    setEmailTemplate(text);
  };

  const copyFromClipboardToGuidelines = async () => {
    const clipboard = navigator.clipboard;
    const text = await clipboard.readText();
    setEmailGuidelines(text);
  };

  function writeSampleEmail(followUpCount: number) {
    const sampleEmailWriteRequest = {
      "follow_up_count": followUpCount,
      "last_follow_up": false
    }    
    setIsWritingSampleEmail(true)
    postRequest(`/jobs/${jobId}/campaigns/templates/sample-email/`, sampleEmailWriteRequest).then(
      (response) => {
        setSampleEmailSubject(response.data.subject)
        setSampleEmailHtmlBody(response.data.html_body)
        setOpen(true)
      }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    }).finally(() => {
      setIsWritingSampleEmail(false)
    })                
  }

  function copySampleEmailToClipboard() {
    const sampleEmail = "Subject: " + sampleEmailSubject + "\n\n" + sampleEmailHtmlBody
    navigator.clipboard.writeText(sampleEmail)
  }
  
  function renderMessageContent(content: any) {
    return <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[[remarkGfm, {singleTilde: false}]]}>{content}</Markdown>
  }

  function renderMessage(message: any) {
    if (message.type === 'ai') {
      return (<>
        <Row>
          <Col xs={2}><AIIcon/></Col>
          <Col xs={22}><strong>Tally</strong></Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>{renderMessageContent(message.content)}</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>
            <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={() => {navigator.clipboard.writeText(message.content)}}/>
          </Col>
        </Row>
      </>)
    } else {
      return (<>
        <Row>
          <Col xs={2}><UserIcon/></Col>
          <Col xs={22}><strong>You</strong></Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>{renderMessageContent(message.content)}</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>
            <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={() => {navigator.clipboard.writeText(message.content)}}/>
          </Col>
        </Row>
      </>)
    }
  }

  return (<>
  <Grid fluid>
    <Row>
      <Col xs={10}>
        <Form id="campaignTemplateForm" onSubmit={handleTemplateAndGuidelinesUpdate} fluid>
          <Grid fluid>
            <Row style={{marginTop: "20px"}}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack.Item grow={1}>
                  <h4>Email Template</h4>
                </Stack.Item>
                <Stack.Item>
                  <Button appearance="primary"  type='submit'>Update</Button>
                  <IconButton circle icon={<ClipboardPasteIcon/ >} appearance="subtle" onClick={copyFromClipboardToTemplate}/>
                </Stack.Item>
              </Stack>
            </Row>
            <Row style={{marginTop: "10px"}}>
              <BundledEditor
                onInit={(evt: any, editor: any) => emailTemplateEditorRef.current = editor}
                initialValue={emailTemplate}
                disabled={false}
                init={{
                  promotion: false,
                  branding: false,
                  height: (windowHeight - 310) *0.8,
                  menubar: true,
                  plugins: [
                    'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                    'searchreplace', 'table', 'wordcount', 'preview'
                  ],
                  toolbar: 'undo redo | preview | link | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                />  
                { /* <Form.Control 
                    onChange={setEmailTemplate}
                    value={emailTemplate}
                    name="emailTemplate" 
                    maxLength={5000}
                    accepter={TextArea}
                    searchable={false}
                    style={{ width: "100%", height: (windowHeight - 310) *.8}}/>
                */ }
            </Row>
            {user?.is_superuser && <>
              <Row style={{marginTop: "30px"}}>
                <Stack direction='row' justifyContent='space-between'>
                  <h4>Email Guidelines</h4>
                  <IconButton circle icon={<ClipboardPasteIcon/ >} appearance="subtle" onClick={copyFromClipboardToGuidelines}/>
                </Stack>
              </Row>
              <Row style={{marginTop: "10px"}}>
                  <Form.Control 
                      onChange={setEmailGuidelines}
                      value={emailGuidelines}
                      name="emailGuidelines" 
                      maxLength={5000}
                      accepter={TextArea}
                      searchable={false}
                      style={{ width: "100%", height: (windowHeight - 310) *.2}}/>
              </Row>
            </>}
          </Grid>
        </Form>
      </Col>
      {user?.is_superuser && <>
      <Col xs={1}>{isWritingSampleEmail && <Loader size="lg" style={{marginTop: "200px"}}/>}</Col>
      <Col xs={13}>
        <Form id="campaignChatForm" onSubmit={handleUserChatMessage} fluid>
          <Stack direction='row' justifyContent='space-between' style={{marginTop: "20px"}}>
            <Stack.Item grow={1}>
              <h4>Assistant Conversation</h4>
            </Stack.Item>
            <Stack.Item>
              <ButtonToolbar>
                <Button appearance="primary"  onClick={() => writeSampleEmail(0)}>Test Initial Email</Button>
                <Button appearance="primary"  onClick={() => writeSampleEmail(1)}>Test Follow Up Email</Button>
                <Button loading={!isUserChatMessageEnabled} appearance="primary"  onClick={buildGuidelines}>Build Guidelines</Button>
                <Button loading={!isUserChatMessageEnabled} appearance="primary"  onClick={resetChat}>Reset</Button>
              </ButtonToolbar>
            </Stack.Item>
          </Stack>
          
          <Panel id="historyPanel" className="overflow-auto" style={{marginTop: "10px", marginBottom: "10px", width: "100%", height: windowHeight - 280}}>
            {training_history === undefined ? (
              <div className='text-center m-3'>
                <Loader size="md" content="Gathering your chat history..."/>
              </div>
            ) : (
              <Grid fluid>
                {training_history.map((message: any, index: number) => (
                  <div style={{marginBottom: "40px"}}>{renderMessage(message)}</div>
                ))}
              </Grid>
            )}
          </Panel>
          <Stack spacing={10} direction='row' justifyContent='space-between'>
            <Stack.Item grow={1} alignSelf='stretch'>
                <Form.Control 
                    onChange={setUserChatMessage}
                    value={userChatMessage}
                    name="userChatMessage" 
                    maxLength={5000}
                    rows={1}
                    accepter={TextArea}
                    searchable={false}
                    style={{ width: "100%"}}/>
              </Stack.Item>
              <Stack.Item>
                <IconButton loading={!isUserChatMessageEnabled} appearance="primary"  type="submit" icon={<ReturnIcon/>} />
              </Stack.Item>
          </Stack>
        </Form>
      </Col>
      </>}
    </Row>
  </Grid>

  <Modal
    size="lg"
    open={open}
    onClose={() => setOpen(false)}
    >
    <Modal.Header>
      <Modal.Title><h4>AI Generated Sample Email</h4></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p><strong>Subject:</strong> {sampleEmailSubject}</p>
      <div dangerouslySetInnerHTML={{__html: sampleEmailHtmlBody}} />
    </Modal.Body>
    <Modal.Footer>
      <Stack justifyContent='space-between'>
        <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={copySampleEmailToClipboard}/>
        <Button onClick={() => setOpen(false)} appearance="primary" >Close</Button>
      </Stack>
    </Modal.Footer>
  </Modal>
</>
  );
};

export default AITemplateBuilderTab;
