import React, { useState, useEffect, useRef } from "react";
import {useSelector} from "react-redux";
import {RootState} from "store";  
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { postRequest, getRequest } from "utils/axios";
import { connectWebsocket } from 'utils/websockets';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import 'styles/chat.css'

import { Panel, Nav, Stack, Col, Row, Grid, IconButton, Button, useToaster, Loader} from 'rsuite';

import { JobResponse } from 'utils/types'
import CandidatePanel from 'components/candidate/CandidatePanel'
import JobSearchCriteriaDetailsModal from 'components/search/JobSearchCriteriaDetailModal'

import UploadIcon from '@rsuite/icons/legacy/FileUpload';
import AIIcon from '@rsuite/icons/legacy/Gear';
import UserIcon from '@rsuite/icons/legacy/User';
import DocumentIcon from '@rsuite/icons/DocPass';
import ClipboardCopyIcon from '@rsuite/icons/legacy/Copy';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import ChatInput from 'components/aichat/chat_input';
import UploadCandidateModal from 'components/import/UploadCandidateModal';

type props = {
  job: JobResponse
  jobReset: boolean
  setJobReset: (value: boolean) => void
}

const JobContent = ({ job , jobReset, setJobReset}: props) => {
  const { data: chatHistory, trigger: triggerHistoryUpdate } = useSWRMutation<any>(`/jobs/${job.id}/chat-messages/`, fetcher)

  const toaster = useToaster();
  const user = useSelector((state: RootState) => state.auth.user)

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchTemplate, setSearchTemplate] = useState('');
  const [isUploadCandidateModalShown, setUploadCandidateModalShown] = useState(false);
  const [detailShown, setDetailShown] = useState(false);
  const toggleShowDetail = () => {
    setDetailShown(!detailShown);
  }

  const [activeTab, setActiveTab] = useState<any>();

  const [updateSearchResults, setUpdateSearchResults] = useState(false);
  const [newCandidateCount, setNewCandidateCount] = useState(0);

  const [browserData, setBrowserData] = useState({
    time: new Date().toISOString(),
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ip: null,
    lan: navigator.language,
  });

  const [isJobChatMessageEnabled, setIsJobChatMessageEnabled] = useState<boolean>(true);
  const [chatLoaderMessage, setChatLoaderMessage] = useState<string>("Thinking ...");  

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);  
  
  const [websocketTracker, setWebsocketTracker] = useState<any>();  

  const inputRef = useRef('');

  let jobId = job.id as string

  useEffect(() => {
    if (!activeTab) {
      const defaultActiveTab: any = searchParams.get('tab') ? searchParams.get('tab') : job.status === 'closed' ? 'resolved' : 'new'
      setActiveTab(defaultActiveTab)
    }

    connectToChatChannel(false)

    if (chatHistory === undefined) {
      triggerHistoryUpdate()
    } else {
      let drawerBody = document.getElementById('historyPanel');
      if (drawerBody) {
        drawerBody.scrollTop = drawerBody.scrollHeight
      }
    }

    if (jobReset && chatHistory && chatHistory.length > 1) {
      setJobReset(false)
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);       
    
    const fetchIPDetails = async () => {
      if (!browserData.ip) {
        try {
          const ipResponse = await axios.get('https://api.ipify.org?format=json');
          const ip = ipResponse.data.ip;
          setBrowserData({ ...browserData, ip });
        } catch (error) {
          console.error('Error fetching IP details:', error);
        }
      }
    };

    fetchIPDetails();    
  })

  function connectToChatChannel(reconnect: boolean) {
    var tracker:any = websocketTracker
    if (!tracker || reconnect || tracker.readyState === WebSocket.CLOSED) {
      disconnectFromChatChannel()
      tracker = connectWebsocket(`${process.env.REACT_APP_WEBSOCKET_URL}/chats/jobs/${job.id}/users/`)
      setWebsocketTracker(tracker)
      tracker.onmessage = function (e:any) {
        const message = JSON.parse(e.data)
        switch (message.action) {
          case "show_progress":
            setChatLoaderMessage(message.message)
            setIsJobChatMessageEnabled(false)            
            break;
          case "hide_progress":
            setIsJobChatMessageEnabled(true)
            triggerHistoryUpdate()
            break;
          case "update_search_results":
            setUpdateSearchResults(true)
            break;
          case "update_history":
            triggerHistoryUpdate()
            break;
        }
      }
    }       
  }

  function disconnectFromChatChannel() {
    var tracker:any = websocketTracker
    if (tracker) {
      tracker.close()
      setWebsocketTracker(null)
      setIsJobChatMessageEnabled(true)
    }
  }

  function resetChatInput() { 
    const inputForm: any = document.getElementById("chat-input")
    if (inputForm) {
      inputForm.value = '';
      inputRef.current = ''
    }
  }

  function showSearchCriteria() {
    getRequest(`/jobs/${jobId}/chat-messages/json-search-criteria/`).then(
      (response) => {
        setSearchQuery(response.data.query)
        setSearchTemplate(response.data.template)
        toggleShowDetail()
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })     
  }

  function searchWithJSONTemplate() {
    if (!inputRef.current) {
      return
    }
    const jsonSearchTemplate = {
      template: inputRef.current.trim()
    }
    postRequest(`/jobs/${jobId}/chat-messages/json-search/`, jsonSearchTemplate).then(
      (response) => {
        setUpdateSearchResults(true)
        resetChatInput()
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })     
  }

  function handleSearchUpdate(response: any) {
    if (response.status === 201) {
      setUpdateSearchResults(true)
    }    
  }

  function handleTabChange(tab: string) {
    setActiveTab(tab)
    setSearchParams(params => {
      params.set('tab', tab);
      return params
    })    
  }

  function renderMessageContent(content: any) {
    return <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[[remarkGfm, {singleTilde: false}]]}>{content}</Markdown>
  }

  function renderMessageDate(time: any) {
    return new Date(time).toLocaleString('en-US', {hour12: true, hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric', year: 'numeric' })  
  }
  
  function renderMessage(message: any) {
    if (message.role === 'assistant') {
      return (<>
        <Row>
          <Col xs={2}><AIIcon/></Col>
          <Col xs={22}>
            <Stack direction='row' alignItems="stretch" justifyContent="space-between">
              <Stack.Item>
                <strong>Tally</strong>
              </Stack.Item>
              <Stack.Item>
                  <span style={{fontSize: "12px"}}>{renderMessageDate(message.created)}</span>
              </Stack.Item>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={2}><div id="typewriter_text"></div></Col>
          <Col xs={22}>{renderMessageContent(message.content)}</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>
            <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={() => {navigator.clipboard.writeText(message.content)}}/>
          </Col>
        </Row>
      </>)
    } else if (message.role === 'user')  {
      return (<>
        <Row>
          <Col xs={2}><UserIcon/></Col>
          <Col xs={22}>
            <Stack direction='row' alignItems="stretch" justifyContent="space-between">
              <Stack.Item>
                <strong>You</strong>
              </Stack.Item>
              <Stack.Item>
                  <span style={{fontSize: "12px"}}>{renderMessageDate(message.created)}</span>
              </Stack.Item>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>{renderMessageContent(message.content)}</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          <Col xs={22}>
            <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={() => {navigator.clipboard.writeText(message.content)}}/>
          </Col>
        </Row>
      </>)
    }
  }  

  return (<>
    <Grid fluid>
      <Row>
        <Col md={12}>
          <Panel 
              id="candidatePanel" 
              className="body-panel" 
              shaded
              style={{marginTop: "10px", marginBottom: "10px", width: "100%", height: windowHeight - 200}}>
            <Stack spacing={10} direction='row' justifyContent='space-between'>
              <Stack.Item grow={1} alignSelf='stretch'>
                <Nav appearance="tabs" activeKey={activeTab} onSelect={handleTabChange}>
                  {job.status !== 'closed' && <Nav.Item eventKey="new">New</Nav.Item>}
                  <Nav.Item eventKey="liked">Liked</Nav.Item>
                  <Nav.Item eventKey="engaged">Engaged</Nav.Item>
                  <Nav.Item eventKey="scheduled">Scheduled</Nav.Item>
                  <Nav.Item eventKey="hidden">Hidden</Nav.Item>
                  <Nav.Item eventKey="resolved">Resolved</Nav.Item>
                </Nav>
              </Stack.Item>
              <Stack.Item>
                <Stack spacing={10} direction='row' justifyContent='space-between'>
                  <Stack.Item>
                    <Button title="Custom candidate upload" appearance="primary" onClick={() => setUploadCandidateModalShown(true)}>Upload Candidates</Button>
                  </Stack.Item>
                  <UploadCandidateModal 
                    jobId={jobId} 
                    open={isUploadCandidateModalShown}
                    onClose={() => setUploadCandidateModalShown(false)}
                  />
                </Stack>
              </Stack.Item>
              <Stack.Item>
              {user?.is_superuser && 
                <Stack spacing={10} direction='row' justifyContent='space-between'>
                  <Stack.Item>
                    <IconButton appearance="primary"  onClick={showSearchCriteria} icon={<DocumentIcon/>} />
                  </Stack.Item>
                  <JobSearchCriteriaDetailsModal query={searchQuery} template={searchTemplate} show={detailShown} setShow={setDetailShown} onClick={toggleShowDetail} />
                </Stack>
              }
              </Stack.Item>
            </Stack>
            <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
              <CandidatePanel 
                activeTab={activeTab} 
                setActiveTab={setActiveTab}
                loading={loading}
                loaded={() => setLoading(false)} 
                jobId={job.id} 
                updateSearchResults={updateSearchResults} 
                updatedSearchResults={() => setUpdateSearchResults(false)}
                setNewCandidateCount={setNewCandidateCount}
              />
            </div>
          </Panel>
        </Col>

        <Col md={12}>
            <Panel 
              id="chatPanel" 
              className="body-panel"
              shaded
              style={{marginTop: "10px", marginBottom: "10px", width: "100%", height: windowHeight - 200}}>
              <Panel 
                id="historyPanel"
                className="overflow-auto"
                style={{height: windowHeight - 270}}>
                {chatHistory === undefined ? (
                <div className='text-center m-3'>
                  <Loader size="md" content="Gathering your chat history...."/>
                </div>
                ) : (
                  <Grid fluid>
                    {chatHistory.map((message: any, index: number) => (
                      <div style={{marginBottom: "40px"}}>{renderMessage(message)}</div>
                    ))}
                  </Grid>
                )}
                {!isJobChatMessageEnabled &&
                  <div className='text-center m-3'>
                    <Loader size="sm" content={<i>{chatLoaderMessage}</i>}/>
                  </div>
                }
              </Panel>
              <ChatInput 
                chatUrl={`/jobs/${jobId}/chat-messages/`}
                triggerHistoryUpdate={triggerHistoryUpdate} 
                connectToChatChannel={() => connectToChatChannel(true)} 
                setIsChatMessageEnabled={setIsJobChatMessageEnabled}
                isChatMessageEnabled={isJobChatMessageEnabled} 
                postProcess={handleSearchUpdate}/>
            </Panel>
        </Col>
      </Row>
    </Grid>
</>)};

export default JobContent;