import Moment from 'moment';
import { Row, Stack } from 'rsuite';
import { timeSpentAtPlace } from 'utils/utils';

interface Props {
    candidate: any;
}

const CandidateJobHistory = ({ candidate }: Props) => {
    const experiences = candidate?.work_experiences

    if (experiences !== null) {
        return (<>
            {experiences.map((experience: any, index: number) => (
                <Row key={index}>
                  <div className="profile-section-card__contents" style={{marginLeft: '6px', marginBottom: '20px'}}>
                    <span className="fw-normal">{experience.title}</span><br/>
                    <Stack wrap spacing={10} direction='row' alignItems='center' justifyContent='space-between'>
                      <Stack.Item>  
                        {experience.linkedin_url ? (
                          <a className="fw-light" href={experience.linkedin_url} target="_blank">{experience.company ? experience.company : 'Institution'}</a>
                        ) : (
                          <span className="fw-light">{experience.company ? experience.company : 'Institution'}</span>
                        )}
                        <br/>
                      </Stack.Item>
                      <Stack.Item>
                        <div className="fw-light">
                          {experience.start_date && Moment(experience.start_date).format('MMM Y') + ' - '}{experience.end_date ? Moment(experience.end_date).format('MMM Y') : "Present"} <span>&middot; {timeSpentAtPlace(experience.start_date, experience.end_date)}</span>
                        </div>
                      </Stack.Item>
                    </Stack>
                    {experience.location && (<span className="fw-light">{experience.location}</span>)}
                  </div>
                </Row>
              ))}
        </>);
    } else {
        return (<>No work experiences known</>)        
    }
};

export default CandidateJobHistory;
