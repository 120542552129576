import React, { useState, useRef } from "react";
import { Modal, Button, Loader, Form, useToaster, Stack, IconButton, Grid, Row, Col } from 'rsuite';
import { MdMic } from 'react-icons/md';
import BundledEditor from 'BundledEditor.js';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage';
import RequestConflictMessage from "components/toasts/RequestConflictMessage";
import EmailPreviewModal from "components/email/EmailPreviewModal";
import TextArea from 'components/elements/Textarea';

import { RootState } from "store";
import { useSelector } from "react-redux";
import { postRequest, patchRequest } from "utils/axios";
import { CandidateResponse, JobCandidateResponse } from 'utils/types';
import { capitalize, trackCandidate } from 'utils/utils';

type props = {
  emailAttachment: any;
  jobCandidate: JobCandidateResponse;
  open: boolean;
  handleClose: () => void;
};

const InitialOutreachEmailModal = ({ emailAttachment, jobCandidate, open, handleClose }: props) => {
  const [isGeneratingEmail, setIsGeneratingEmail] = useState(true);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isGeneratingVoice, setGeneratingVoice] = useState(false);
  const [videoMessageScriptUpdated, setVideoMessageScriptUpdated] = useState(true);
  const [voicePreviewUrl, setVoicePreviewUrl] = useState<any>(null);
  const [initialEmailBody, setInitialEmailBody] = useState('');
  const [hasGeneratedVoiceMessage, setHasGeneratedVoiceMessage] = useState<any>(false);
  const [isShowPreviewModalOpen, setIsShowPreviewModalOpen] = useState<any>(false);
  const [isLearnMoreDialogOpen, setIsLearnMoreDialogOpen] = useState(false); // State for Learn More dialog
  
  const user = useSelector((state: RootState) => state.auth.user);
  const configuration = useSelector((state: RootState) => state.configuration);

  const formRef: any = useRef();
  const toaster = useToaster();
  const editorRef: any = useRef();

  var candidate: CandidateResponse = jobCandidate.candidate;

  function generateOrLoadDraftEmail() {
    trackCandidate(candidate.id);
    setIsGeneratingEmail(true);
    const payload = {
      email_attachment: emailAttachment,
    };
    const patchPromise = postRequest(`/jobs/${jobCandidate.job_id}/candidates/${candidate.id}/emails/`, payload);
    patchPromise.then((response) => {
      updateFields(response.data);
    }).catch((error) => {
      handleClose();
      toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
    }).finally(() => {
      setIsGeneratingEmail(false);
    });
  }

  const [validContent, setValidContent] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    id: '',
    subject: '',
    body: '',
    cc: [],
    bcc: [],
    to: '',
    from: '',
    status: 'Draft',
    type: 'Initial',
    videoMessageScript: ''
  });

  function updateFields(response: any) {
    setHasGeneratedVoiceMessage(response.video_message_script);
    setInitialEmailBody(response.body);
    setFormValue({
      id: response.id,
      subject: response.subject || '',
      body: response.body || '',
      videoMessageScript: response.video_message_script || '',
      cc: response.recipients_cc,
      bcc: response.recipients_bcc,
      to: response.recipient_to,
      from: response.sender,
      status: response.status,
      type: response.type
    });
    setValidContent(response.subject != null && response.body != null && response.subject.length > 0 && response.body.length > 0);
  }

  const handleSubmit = (event: any) => {
    handleFormValueUpdate(true, false);
  };

  const handleButtonUpdate = () => {
    handleFormValueUpdate(false, false);
  };

  const handleFormValueUpdate = (sendEmail: boolean, suppressSuccessMessage: boolean) => {
    updateChangesAndSendEmail(
      sendEmail,
      suppressSuccessMessage,
      formValue.subject,
      formValue.body,
      formValue.videoMessageScript,
      formValue.cc,
      formValue.bcc
    );
  };

  const updateChangesAndSendEmail = (
    sendEmail: boolean,
    suppressSuccessMessage: boolean,
    subject: any,
    body: any,
    videoMessageScript: any,
    cc: any,
    bcc: any
  ) => {
    setValidContent(subject != null && body != null && subject.length > 0 && body.length > 0);
    if (!validContent) {
      toaster.push(<RequestConflictMessage message='Please enter a subject and body' toaster={toaster} />);
      return;
    }
    if (videoMessageScript != null && videoMessageScript.length > 500) {
      toaster.push(<RequestConflictMessage message='The video message must not exceed 500 characters. Please adjust the text.' toaster={toaster} />);
      return;
    }
    if (formRef.current.check()) {
      if (sendEmail) {
        setIsSendingEmail(true);
      }
      const jobCandidateEmailRequest: any = {
        'video_message_script': videoMessageScript,
        'subject': subject,
        'body': body
      };
      if (cc && cc.length > 0) {
        jobCandidateEmailRequest['recipients_cc'] = cc;
      }
      if (bcc && bcc.length > 0) {
        jobCandidateEmailRequest['recipients_bcc'] = bcc;
      }
      if (sendEmail) {
        jobCandidateEmailRequest['status'] = 'scheduled';
      }

      patchRequest(`/jobs/${jobCandidate.job_id}/candidates/${jobCandidate.candidate.id}/emails/${formValue.id}/`, jobCandidateEmailRequest).then(
        (response) => {
          if (sendEmail) {
            handleClose();
            toaster.push(<RequestSuccessMessage message='Started drip campaign' toaster={toaster} />);
          } else if (!suppressSuccessMessage) {
            toaster.push(<RequestSuccessMessage message='Email draft successfully updated' toaster={toaster} />);
          }
        }).catch(
          (error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
          }).finally(() => {
            if (sendEmail) {
              setIsSendingEmail(false);
            }
          });
    }
  };

  const handleVideoMessageScriptChange = (record: any) => {
    setVideoMessageScriptUpdated(true);
    setFormValue({
      ...formValue,
      videoMessageScript: record
    });

    updateChangesAndSendEmail(
      false,
      true,
      formValue.subject,
      editorRef.current.getContent(),
      record,
      formValue.cc,
      formValue.bcc
    );
  };

  const handleSubjectChange = (record: any) => {
    setFormValue({
      ...formValue,
      subject: record
    });

    updateChangesAndSendEmail(
      false,
      true,
      record,
      editorRef.current.getContent(),
      formValue.videoMessageScript,
      formValue.cc,
      formValue.bcc
    );
  };

  const handleEmailCC = (list: any) => {
    setFormValue({
      ...formValue,
      cc: list
    });
    updateChangesAndSendEmail(
      false,
      true,
      formValue.subject,
      editorRef.current.getContent(),
      formValue.videoMessageScript,
      list,
      formValue.bcc
    );
  };

  const handleEmailBCC = (list: any) => {
    setFormValue({
      ...formValue,
      bcc: list
    });
    updateChangesAndSendEmail(
      false,
      true,
      formValue.subject,
      editorRef.current.getContent(),
      formValue.videoMessageScript,
      formValue.bcc,
      list
    );
  };

  const handleBody = () => {
    setFormValue({
      ...formValue,
      body: editorRef.current.getContent()
    });
    updateChangesAndSendEmail(
      false,
      true,
      formValue.subject,
      editorRef.current.getContent(),
      formValue.videoMessageScript,
      formValue.cc,
      formValue.bcc
    );
  };

  function generateVoicePreview() {
    setGeneratingVoice(true);
    const payload = {
      script: formValue.videoMessageScript
    };
    if (videoMessageScriptUpdated || !voicePreviewUrl) {
      postRequest(`/jobs/${jobCandidate.job_id}/candidates/${jobCandidate.candidate.id}/emails/${formValue.id}/voice-preview/`, payload).then(
        (response) => {
          var latestVoicePreviewUrl = voicePreviewUrl;
          if (response.status === 201) {
            setVoicePreviewUrl(response.data.voice_url);
            setVideoMessageScriptUpdated(false);
            latestVoicePreviewUrl = response.data.voice_url;
          }
          setGeneratingVoice(false);
          var audio: any = document.getElementById("voicePreview");
          if (audio) {
            audio.src = latestVoicePreviewUrl;
            audio.addEventListener('canplaythrough', function playAudio() {
              audio.play().then(() => {
                console.log('Audio playing');
              }).catch((error: any) => {
                console.error('Error playing audio:', error);
              });
              // Remove the event listener after the audio starts playing
              audio.removeEventListener('canplaythrough', playAudio);
            });
          }
        }).catch(
          (error) => {
            toaster.push(<RequestErrorMessage error={error} toaster={toaster} />);
          }).finally(() => {
            setGeneratingVoice(false);
          });
    } else {
      setGeneratingVoice(false);
      var audio: any = document.getElementById("voicePreview");
      if (audio) {
        audio.play();
      }
    }
  }

  function learnMore() {
    setIsLearnMoreDialogOpen(true); // Open the Learn More dialog
  }

  const labelFieldSize = "60px";
  const headerFontSize = "12px";

  return (
    <>
      <Modal
        size='lg'
        open={open}
        onClose={handleClose}
        onEntered={generateOrLoadDraftEmail}
        onExit={() => { setIsGeneratingEmail(true); setIsSendingEmail(false); }}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Email Outreach ({capitalize(formValue.type)} {formValue.status} to {candidate?.full_name})</Modal.Title>
        </Modal.Header>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          onCheck={setFormError}
          formValue={formValue}
          layout="vertical"
          fluid
        >
          <Modal.Body style={{ height: 900 }}>
            <p style={{ fontSize: '12px' }}>You can make adjustments to the generated email before sending it to the candidate.
              If you cancel, this email will be saved as a draft until campaign is started.</p>
            <div style={{ fontSize: headerFontSize }}>
              <Form.Group controlId="headers">
                <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between' wrap={true}>
                  <Stack.Item alignSelf='stretch' basis="100%">
                    <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
                      <Stack.Item basis={labelFieldSize}>
                        <Form.ControlLabel>TO</Form.ControlLabel>
                      </Stack.Item>
                      <Stack.Item grow={2} alignSelf='stretch'>
                        <Form.Control name="to" readOnly value={formValue.to} style={{ fontSize: headerFontSize, width: '100%' }} />
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>

                  <Stack.Item alignSelf='stretch' basis="100%">
                    <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
                      <Stack.Item basis={labelFieldSize}>
                        <Form.ControlLabel>From</Form.ControlLabel>
                      </Stack.Item>
                      <Stack.Item grow={1} alignSelf='stretch'>
                        <Form.Control name="from" readOnly value={formValue.from} style={{ fontSize: headerFontSize, width: '100%' }} />
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>

                  <Stack.Item alignSelf='stretch' basis="100%">
                    <Stack spacing={10} direction='row' alignItems='center' justifyContent='flex-start' wrap={true}>
                      <Stack.Item basis={labelFieldSize}>
                        <Form.ControlLabel>Subject</Form.ControlLabel>
                      </Stack.Item>
                      <Stack.Item grow={1} alignSelf='stretch'>
                        <Form.Control
                          name="subject"
                          value={formValue.subject}
                          style={{ fontSize: headerFontSize, width: '100%' }}
                          onChange={handleSubjectChange}
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>

                </Stack>
              </Form.Group>
            </div>
            {isGeneratingEmail || isSendingEmail ? (
              <div className='text-center m-3'>
                {isGeneratingEmail && (<Loader size="lg" content="Writing personalized outreach email..." />)}
                {isSendingEmail && (<Loader size="lg" content="Contacting candidate..." />)}
              </div>
            ) : (
              <div style={{ fontSize: headerFontSize }}>
                {hasGeneratedVoiceMessage && configuration.features.generated_video_message_support && <>
                  <p style={{ marginTop: "10px", fontSize: "14px" }}>
                    <strong>Personalized Video Message </strong>
                    <span style={{ marginTop: "0px", fontSize: "10px" }}><Button appearance="link" onClick={learnMore} style={{ marginTop: 0, fontSize: "10px" }}>Learn More</Button> </span>
                    <IconButton title="Video Message Voice Preview" circle icon={<MdMic />} appearance="subtle" onClick={generateVoicePreview} />
                    <audio id="voicePreview" src={voicePreviewUrl}></audio>
                  </p>
                  <Form.Group>
                    <Form.Control
                      maxLength={500}
                      accepter={TextArea}
                      rows={4}
                      name="videoMessageScript"
                      value={formValue.videoMessageScript}
                      placeholder="No video will be generated if there is no text in this box.  Please delete {VIDEO_MESSAGE} in the email body below."
                      onChange={handleVideoMessageScriptChange}
                      style={{ fontSize: headerFontSize, fontWeight: "500", fontFamily: "Helvetica,Arial,sans-serif" }}
                    />
                  </Form.Group>
                </>}

                <p style={{ marginTop: "10px", fontSize: "14px" }}><strong>Personalized Email Body</strong></p>
                <div style={{ marginTop: "10px" }}>
                  <BundledEditor
                    name="body"
                    onInit={(evt: any, editor: any) => {
                      editorRef.current = editor;
                    }}
                    onChange={handleBody}
                    initialValue={initialEmailBody}
                    newvValue={formValue.body}
                    init={{
                      promotion: false,
                      branding: false,
                      height: 400,
                      resize: true,
                      menubar: false,
                      plugins: [
                        'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                        'searchreplace', 'table', 'wordcount'
                      ],
                      toolbar: 'undo redo | preview | blocks | ' +
                        'fontfamily fontsize bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-weight:300; font-size:12px }'
                    }}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ marginTop: "10px" }}>
              <Grid fluid>
                <Row>
                  <Col xs={8} style={{ fontSize: "10px", textAlign: "left" }}>
                    <Button appearance="primary" onClick={() => setIsShowPreviewModalOpen(true)}>Email Preview</Button>
                  </Col>
                  <Col xs={6} style={{ alignContent: "center" }}>
                    {isGeneratingVoice &&
                      <Loader size="sm" content="Generating voice preview..." />
                    }
                  </Col>
                  <Col xs={10} style={{ alignContent: "right" }}>
                    <Button disabled={isGeneratingEmail || isSendingEmail} type='submit' appearance="primary">Start Campaign</Button>
                    <Button disabled={isGeneratingEmail || isSendingEmail} type='button' appearance="primary" onClick={handleButtonUpdate}>Save Changes</Button>
                  </Col>
                </Row>
              </Grid>
            </div>

            <EmailPreviewModal
              open={isShowPreviewModalOpen}
              onClose={() => setIsShowPreviewModalOpen(false)}
              emailBody={formValue.body}
              isVideoMessage={formValue.videoMessageScript != null && formValue.videoMessageScript.length > 10} />
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Learn More Dialog */}
      <Modal open={isLearnMoreDialogOpen} onClose={() => setIsLearnMoreDialogOpen(false)} size="sm">
        <Modal.Header>
          <Modal.Title>Learn More</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Welcome to your personalized outreach generator! A few notes:</p>
          <ul style={{fontSize: "13px"}}>
            <li style={{padding: "5px"}}>You can update the video script and/or the email text directly in the text boxes.</li>
            <li style={{padding: "5px"}}>To preview how the video will sound, just click the microphone icon. Note that the preview will be read by a default voice rather than the voice you’ve selected in <strong>Account &gt; Profile</strong>. At this time, only audio (not video) can be previewed prior to sending.</li>
            <li style={{padding: "5px"}}>In the body of the email, the video will be placed where &#123;VIDEO_MESAGE&#125; appears. Feel free to move it!</li>
            <li style={{padding: "5px"}}>When you click Start Campaign, the email will be sent and follow-up emails will be scheduled. To adjust the cadence of these follow-ups, click Manage Campaign.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsLearnMoreDialogOpen(false)} appearance="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InitialOutreachEmailModal;
