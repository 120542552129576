import React, { useEffect } from "react";
import { useNavigate } from 'react-router';
import { RootState } from "store";
import { useSelector } from "react-redux";

const Search = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    navigate(`/jobs/${user?.user_id}?tab=new`)
  })
  return <></>
};

export default Search;