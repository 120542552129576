import { createChatBotMessage } from 'react-chatbot-kit';

const botName = 'Tally';

const config = {
    initialMessages: [createChatBotMessage(`Hi! I'm ${botName}`)],
    botName: botName,
    customStyles: {
      botMessageBox: {
        backgroundColor: 'rgb(30,70,58)',
      },
      chatButton: {
        backgroundColor: 'rgb(92,204,157)',
      },
    }
};

export default config;