import React from 'react';

import {
  MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter
} from 'mdb-react-ui-kit';
import { IconButton } from 'rsuite';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import ClipboardCopyIcon from '@rsuite/icons/legacy/Copy';


type Props = {
  query: string,
  template: string,
  onClick: () => void,
  show: boolean,
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
};

function JobSearchCriteriaDetailsModal({ query, template, show, setShow, onClick }: Props) {

  return (
    <MDBModal show={show} setShow={setShow} tabIndex='-1'>
      <MDBModalDialog size='xl'>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Search Criteria Details</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={onClick}></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>
            <h4>JSON Search Template:</h4>
            <JSONPretty id="json-pretty" data={template} />
            <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={() => {navigator.clipboard.writeText(template)}}/>
          </MDBModalBody>

          <MDBModalBody>
            <h4>Opensearch Query:</h4>
            <JSONPretty id="json-pretty" data={query} />
            <IconButton circle icon={<ClipboardCopyIcon/ >} appearance="subtle" onClick={() => {navigator.clipboard.writeText(query)}}/>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color='secondary' onClick={onClick}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default JobSearchCriteriaDetailsModal;