import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { postRequest } from "utils/axios";
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { useSWRConfig } from "swr";

import { useToaster } from 'rsuite';

import { trackCandidate } from 'utils/utils';
import JobCandidateTab from 'components/candidate/JobCandidateTab'
import JobCandidateSearchTab from 'components/candidate/JobCandidateSearchTab'

import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'
import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestConflictMessage from 'components/toasts/RequestConflictMessage'

type candidatePanelProps = {
  activeTab: string
  setActiveTab: (activeTab: string) => void
  loading: boolean
  loaded: () => void
  jobId: string
  updateSearchResults: boolean
  updatedSearchResults: () => void
  setNewCandidateCount: (count: number) => void
}

const CandidatePanel = ({ 
    activeTab, 
    setActiveTab,
    loaded,
    loading,
    jobId, 
    updateSearchResults,
    updatedSearchResults,
    setNewCandidateCount
  }: candidatePanelProps) => {
  const toaster = useToaster();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const { mutate } = useSWRConfig()

  const jobCandidateUrl = `/jobs/${jobId}/searches/candidates/`
  const { data: searchJobCandidates, trigger } = useSWRMutation<any>(jobCandidateUrl, fetcher, { onSuccess: 
    (data, key, config) => {
      loaded()
      setNewCandidateCount(data.true_hits)
    }
  })

  useEffect(() => {
    if ((!initialized && searchJobCandidates === undefined && activeTab === 'new') || updateSearchResults) {
      setActiveTab('new')
      triggerSearchResultUpdate()
    }
  })  

  function triggerSearchResultUpdate() {
    setInitialized(true)
    updatedSearchResults()
    trigger()
  }

  function deleteSearchCandidate(candidateId: string) {
    searchJobCandidates.results = searchJobCandidates.results.filter(function (jobCandidate: any) { return jobCandidate.candidate.id !== candidateId })
    searchJobCandidates.true_hits = searchJobCandidates.true_hits - 1
    searchJobCandidates.count = searchJobCandidates.count - 1
    setNewCandidateCount(searchJobCandidates.true_hits)
    mutate(jobCandidateUrl, searchJobCandidates, false)
  }

  function contactCandidate(candidateId: string, deleteCandidate: () => void) {
    const jobCandidateUpdateRequest = {
      "candidate_id": candidateId,
      "state": 'engaged'
    };
    const responsePromise = postRequest(`/jobs/${jobId}/candidates/`, jobCandidateUpdateRequest)    
    responsePromise.then((response) => {
        const jobCandidateId = response.data.id
        triggerCampaign(jobCandidateId, jobCandidateUpdateRequest, deleteCandidate)
      }).catch((error) => {
      if (error.response.status === 409) {
        toaster.push( <RequestConflictMessage message={error.response.data.message} toaster={toaster}/> )  
      } else {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }
    })   
  }

  function triggerCampaign(jobCandidateId: string, jobCandidateUpdateRequest: any, deleteCandidate: () => void) {
    const responsePromise = postRequest(`/jobs/${jobId}/candidates/${jobCandidateId}/campaigns/`, jobCandidateUpdateRequest)    
    responsePromise.then((response) => {
      deleteCandidate()
      toaster.push( <RequestSuccessMessage message='Started drip campaign for candidate' toaster={toaster}/> )
    }).catch((error) => {
      if (error.response.status === 409) {
        toaster.push( <RequestConflictMessage message={error.response.data.message} toaster={toaster}/> )  
      } else {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }
    })    
  }

  function contactSearchCandidate(candidateId: string) {
    deleteSearchCandidate(candidateId)
  }

  function likeSearchCandidate(candidateId: string) {
    updateJobCandidate(candidateId, 'liked', () => deleteSearchCandidate(candidateId))
  }

  function hideSearchCandidate(candidateId: string) {
    updateJobCandidate(candidateId, 'hidden', () => deleteSearchCandidate(candidateId))
  }

  function likeCandidate(candidateId: string, deleteCandidate: () => void) {
    updateJobCandidate(candidateId, 'liked', deleteCandidate)
  }

  function scheduleCandidate(candidateId: string, deleteCandidate: () => void) {
    updateJobCandidate(candidateId, 'scheduled', deleteCandidate)
  }

  function hideCandidate(candidateId: string, deleteCandidate: () => void) {
    updateJobCandidate(candidateId, 'hidden', deleteCandidate)
  }

  function updateJobCandidate(candidateId: string, state: string, deleteCandidate: () => void) {
    const jobCandidateUpdateRequest = {
      "candidate_id": candidateId,
      "state": state
    };
    const responsePromise = postRequest(`/jobs/${jobId}/candidates/`, jobCandidateUpdateRequest)    
    responsePromise.then((response) => {
      if (response.status === 201) {
        deleteCandidate()
      } 
    }).catch((error) => {
      if (error.status === 409) {
        toaster.push( <RequestConflictMessage message={error.response.data.message} toaster={toaster}/> )  
      } else {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }
    }).finally(() => {
      trackCandidate(candidateId)
    })
  }  

  switch (activeTab) {
    case 'new':
      if (!initialized && searchJobCandidates === undefined && activeTab === 'new')
        triggerSearchResultUpdate()
      return (
        <JobCandidateSearchTab key='new' activeTab={activeTab} setActiveTab={setActiveTab} searchParams={searchParams} loading={loading} jobId={jobId} jobCandidates={searchJobCandidates} likeCandidate={likeSearchCandidate} hideCandidate={hideSearchCandidate} contactCandidate={contactSearchCandidate} />
      )
    case 'liked':
      return (
        <JobCandidateTab key='liked' activeTab={activeTab}  setActiveTab={setActiveTab} searchParams={searchParams} loaded={loaded} loading={loading} state='liked' jobId={jobId} likeCandidate={likeCandidate} hideCandidate={hideCandidate} scheduleCandidate={scheduleCandidate}/>
      )
    case 'engaged':
      return (
        <JobCandidateTab key='engaged' activeTab={activeTab} setActiveTab={setActiveTab} searchParams={searchParams} loaded={loaded} loading={loading} state='engaged' jobId={jobId} likeCandidate={likeCandidate} hideCandidate={hideCandidate} scheduleCandidate={scheduleCandidate}/>
      )
    case 'hidden':
      return (
        <JobCandidateTab key='hidden' activeTab={activeTab}  setActiveTab={setActiveTab} searchParams={searchParams} loaded={loaded} loading={loading} state='hidden' jobId={jobId} likeCandidate={likeCandidate} hideCandidate={hideCandidate} scheduleCandidate={scheduleCandidate}/>
      )
    case 'scheduled':
      return (
        <JobCandidateTab key='scheduled' activeTab={activeTab}  setActiveTab={setActiveTab} searchParams={searchParams} loaded={loaded} loading={loading} state='scheduled' jobId={jobId} likeCandidate={likeCandidate} hideCandidate={hideCandidate} scheduleCandidate={scheduleCandidate}/>
      )
    case 'resolved':
      return (
        <JobCandidateTab key='resolved' activeTab={activeTab}  setActiveTab={setActiveTab} searchParams={searchParams} loaded={loaded} loading={loading} state='resolved' jobId={jobId} likeCandidate={likeCandidate} hideCandidate={hideCandidate} scheduleCandidate={scheduleCandidate}/>
      )
  }
  return (<></>)
};

export default CandidatePanel;