import Moment from 'moment';
import { Row, Stack } from 'rsuite';

interface Props {
    candidate: any;
}

const CandidateAwardHistory = ({ candidate }: Props) => {
    const awards = candidate?.awards

    if (awards !== null) {
        return (<>
            {awards.map((award: any, index: number) => (
                <Row key={index}>
                  <div className="profile-section-card__contents" style={{marginLeft: '6px', marginBottom: '20px'}}>
                  <span className="fw-normal fs-6">{award.title}</span><br/>
                    <Stack wrap spacing={10} direction='row' alignItems='center' justifyContent='space-between'>
                      <Stack.Item>
                        <span className="fw-light fs-6">{award.issuer}</span><br/>
                      </Stack.Item>
                      <Stack.Item>
                        <div className="fw-light">
                          {award.issue_date && Moment(award.issue_date).format('MMM Y')}
                        </div>
                      </Stack.Item>
                    </Stack>
                    <span className="fw-light">{award.description}</span>
                  </div>
                </Row>
              ))}            
        </>);
    } else {
        return (<>No known awards</>)        
    }
};

export default CandidateAwardHistory;
