import React from 'react';
import Chart from 'react-apexcharts';
import { Panel } from 'rsuite';

interface HalfPieChartProps {
  title: string;
  data: any;
  type?: any
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  options?: any;
  labels?: string[];
}

const defaultOptions = {
  dataLabels: {
    enabled: true
  },
  plotOptions: {
    pie: {
      customScale: 1.0,
      offsetY: 0,
      donut: {
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true
          }
        }
      }
    },
  },
  colors: ["#CC3333", "#FFCC00", "#66CC00"],
  legend: {
    position: 'bottom',
    offsetY: 0
  }
};

const HalfPieChart = ({ title, data, type, labels, options }: HalfPieChartProps) => (
  <Panel className="card" bodyFill header={title} style={{height: 300}}>
    <Chart
      series={data}
      type={type}
      height={250}
      options={Object.assign({}, defaultOptions, options, { labels })}
    ></Chart>
  </Panel>
);

export default HalfPieChart;
