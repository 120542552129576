import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Calendar, Whisper, Popover, Badge, Loader } from 'rsuite';

import useSWR from 'swr';
import { fetcher } from "utils/axios";

import 'styles/rsuite.css';

import { JobCandidateListResponse , JobCandidateResponse } from "utils/types";

const CalendarContent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const meetingsFetcher = useSWR<JobCandidateListResponse>('/integrations/calendly/scheduled/', fetcher)
  const meetings = meetingsFetcher.data as JobCandidateListResponse

  useEffect(() => {
    if (!meetings) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [meetings])  

  function getScheduledCandidates(date: Date) {
    const day = date.toLocaleDateString();
    var jobCandidateList: any = []

    if (meetings && meetings.results) {
      const jobCandidates = meetings.results.filter((jobCandidate: JobCandidateResponse) => {
        if (jobCandidate.calendly_candidate_meeting) {
          const start_time = new Date(jobCandidate.calendly_candidate_meeting.start_time)
          return start_time.toLocaleDateString() === day
      }})

      jobCandidateList = jobCandidates.map((jobCandidate: JobCandidateResponse) => {
          if (jobCandidate.calendly_candidate_meeting) {
            const start_time = new Date(jobCandidate.calendly_candidate_meeting.start_time)
            return { 
              time: start_time.toLocaleTimeString(), 
              email: jobCandidate.candidate.email,
              name: jobCandidate.candidate.full_name,
              url: `/jobs/${jobCandidate.job_id}/candidates/${jobCandidate.candidate.id}/?tab=${jobCandidate.state}`
             }
          }
      })
    }

    return jobCandidateList
  }
  
  function renderCell(date: Date) {
    const list = getScheduledCandidates(date);
    
    if (list.length > 0) {
      const displayList = list.filter((item: any, index: number) => index < 3);

      return (
        <ul className="calendar-candidate-list">
           <Whisper
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                {list.map((item: any, index: number) => (
                  <p key={index}>
                    <b>{item.time}</b> - {item.name} ({item.email})
                  </p>
                ))}
              </Popover>
            }
          ><div>
          {displayList.map((item: any, index: number) => (
            <li onClick={() => {navigate(item.url)}} key={index}>
              <Badge /> <b>{item.time}</b> - {item.name}
            </li>
          ))}
          {list.length > 3 && <li>Hover over for more ...</li>}
          </div>
          </Whisper>
        </ul>
      );
    }

    return null;
  }

  return (<>
      <style>
        {`
          .bg-gray {
            background-color: rgba(242, 242, 242, 0.3);
          }
        `}
      </style>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isLoading && <Loader content="Loading meetings..." size="sm"/>}
      </div>
      <Calendar bordered renderCell={renderCell} cellClassName={date => (date.getDay() % 2 ? 'bg-gray' : undefined)}/>
  </>)}

export default CalendarContent
