import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Configuration } from "utils/types";

const initialState: Configuration = { 
  features: {
    can_create_custom_avatar: false,
    calendly_support: false,
    uploaded_video_message_support: false,
    generated_video_message_support: false,
    max_files_permitted_for_upload: 1
  }

}

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setConfiguration(configuration: Configuration, action: PayloadAction<any>) {
      configuration.features = action.payload.features
    }
  }
});

export const { setConfiguration } = configurationSlice.actions;
export default configurationSlice;
