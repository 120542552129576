import { Stack } from 'rsuite';
import { MDBIcon } from 'mdb-react-ui-kit';
import CandidateGithubStar from 'components/candidate/CandidateGithubStar'

interface Props {
    candidate: any;
    showText?: boolean;
    setTwitterUrl?: (twitterUrl: string) => void;
}

const CandidateSocialMedia = ({ candidate, showText, setTwitterUrl }: Props) => {
    const social_media_links = candidate?.url_links

    function renderLink(link: any) {
        switch(link.provider) {
            case 'Personal': return renderProvider('Personal website', link.url, "user-alt", "#55555", false)
            case 'Linkedin': return renderProvider('LinkedIn', link.url, "linkedin", "#3b5998", true)
            case 'Twitter': 
                if (setTwitterUrl) {
                    setTwitterUrl(link.url);
                 } 
                 return renderProvider('Twitter/X', link.url, "x", "#000000", true)
            case 'Github': return renderProvider('Github', link.url, "github", "#000000", true)
            case 'Stackoverflow': return renderProvider('Stackoverflow', link.url, "stack-overflow", "#55acee", true)
            case 'Facebook': return renderProvider('Facebook', link.url, "facebook", "#3b5998", true)
            case 'Instagram': return renderProvider('Instagram', link.url, "instagram", "#ac2bac", true)
            case 'Youtube': return renderProvider('Youtube', link.url, "youtube", "#c4302b", true)
            case 'Patreon': return renderProvider('Patreon', link.url, "patreon", "#f96854", true)
            case 'Resume': return renderProvider('Resume', link.url, "address-card", "#55555", false)
            case 'Githubio': return renderProvider('Github.io', link.url, "github-alt", "#000000", true)
            default: return null
        }
    }

    function renderProvider(name: string, url: string, icon: string, color: string, isFab: boolean) {
        if (showText) {
            name = ' ' + name
        } else {
            name = ''
        }

        if (isFab) 
            return (<a href={url} target="_blank"><MDBIcon fab icon={icon} style={{ color: color }} />{name}</a>)
        else
            return (<a href={url} target="_blank"><MDBIcon icon={icon} style={{ color: color }} />{name}</a>)
    }

    return (
        <Stack wrap spacing={5} direction='row' alignItems='flex-start' justifyContent='flex-start'>
            {social_media_links && social_media_links.map((link: any, index: number) => {
                const social_media_link = renderLink(link)
                if (social_media_link) {
                    return <Stack.Item key={index}>{social_media_link}</Stack.Item>
                }
            })}                  
            <Stack.Item key='githubstar'><CandidateGithubStar candidate={candidate} linkText='Github Star'/></Stack.Item>
        </Stack>   
    )
};

export default CandidateSocialMedia;
