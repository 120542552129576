import React from 'react';
import Chart from 'react-apexcharts';
import { Panel, Stack } from 'rsuite';

// https://apexcharts.com/javascript-chart-demos/mixed-charts/line-column-area/

interface BarChartProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  data: any;
  type?: any
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  options?: any;
  labels?: string[];
}

const defaultOptions = {
  chart: {
    fontFamily: 'inherit',
    parentHeightOffset: 0,
    toolbar: {
      show: false
    },
    animations: {
      enabled: false
    },
    stacked: true
  },
  plotOptions: {
    bar: {
      columnWidth: '60%'
    }
  },
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1
  },
  grid: {
    padding: {
      top: -20,
      right: 0,
      left: -4,
      bottom: -4
    },
    strokeDashArray: 4,
    xaxis: {
      lines: {
        show: true
      }
    }
  },
  xaxis: {
    tooltip: {
      enabled: false
    },
    axisBorder: {
      show: false
    },
    type: 'datetime',
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM \'yy',
        day: 'dd MMM',
        hour: 'HH:mm'
      }
    }
  },
  yaxis: {
    labels: {
      padding: 4
    }
  },
  colors: ["#66CCFF", "#0099FF", "#FFCC00", "#66CC00"],
  legend: {
    show: false
  }
};

const BarChart = ({ title, actions, data, type, labels, options }: BarChartProps) => (
  <Panel style={{overflow: 'visible', position: 'relative'}}
    className="card"
    header={
      <Stack justifyContent="space-between">
        {title}
        {actions}
      </Stack>
    }
  >
    {type === undefined ? 
      <Chart
        series={data}
        height={284}
        options={Object.assign({}, defaultOptions, options, { labels })}
      />
    :
      <Chart
        series={data}
        height={284}
        type={type}
        options={Object.assign({}, defaultOptions, options, { labels })}
      />
    }
  </Panel>
);

export default BarChart;
