import React, { useState } from "react";
import { Panel, Breadcrumb, Stack, DateRangePicker } from 'rsuite';
import AnalyticsContent from './AnalyticsContentDemo';

const Analytics = () => {
  const defaultStartDate = new Date();
  defaultStartDate.setHours(0);
  defaultStartDate.setMinutes(0);
  defaultStartDate.setSeconds(0);

  const defaultEndDate = new Date();
  defaultEndDate.setHours(23);
  defaultEndDate.setMinutes(59);
  defaultEndDate.setSeconds(59);

  const [dateRange, setDateRange] = useState<[Date, Date]>([defaultStartDate, defaultEndDate]);
  const [dateRangeChanged, setDateRangeChanged] = useState<boolean>(false);

  function handleDateChange(newDateRange: [Date, Date] | null) {
    if (newDateRange) {
      setDateRange(newDateRange);
      setDateRangeChanged(true);
    }
  }

  return (
    <>
      <Panel
        className="header-panel"
        shaded
        header={
          <>
            <Stack direction="row" justifyContent="space-between">
              <Stack.Item>
                <h3 className="title">Analytics</h3>
              </Stack.Item>
              <Stack.Item>
                <DateRangePicker
                  format="MM/dd/yyyy hh:mm aa"
                  showMeridian
                  preventOverflow={true}
                  onChange={handleDateChange}
                  value={dateRange}
                />
              </Stack.Item>
            </Stack>

            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Analytics</Breadcrumb.Item>
            </Breadcrumb>
          </>
        }
      ></Panel>

      <AnalyticsContent
        dateRange={dateRange}
        dateRangeChanged={dateRangeChanged}
        setDateRangeChanged={setDateRangeChanged}
      />
    </>
  );
};

export default Analytics;
