import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Grid, Message, Checkbox, Progress, useToaster, Stack } from 'rsuite';

import { putFileRequest, putRequest, postRequest } from "utils/axios";

import CustomAvatarPositioningImage from 'assets/images/custom-avatar-positioning-video-landscape.png';

const acceptedVideoFormats = [
  "video/mp4",        // MP4
  "video/quicktime",  // MOV (QuickTime)
  "video/mpeg"        // MPEG
];

type CreateAvatarModalProps = {
  open: boolean;
  avatarLimitReached: boolean;
  onClose: () => void;
  refreshVideoUploadStatus: () => void;
};

const CreateAvatarModal: React.FC<CreateAvatarModalProps> = ({ open, avatarLimitReached, onClose, refreshVideoUploadStatus }) => {
  const defautlVideoUploadFileSize = '10485760' // 10MB in case it's not defined
  const maxVideoUploadFileSize = parseInt(process.env.REACT_APP_MAX_VIDEO_UPLOAD_FILE_SIZE || defautlVideoUploadFileSize)
  const maxVideoUploadFileSizeInMb = maxVideoUploadFileSize ? Math.round(maxVideoUploadFileSize / 1024 / 1024) : 0;

  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [avatarName, setAvatarName] = useState<string>('');
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<{ percent: number; loadedKb: number; totalKb: number }>({
    percent: 0,
    loadedKb: 0,
    totalKb: 0
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const toaster = useToaster();

  const showToast = () => {
    toaster.push(
      <Message style={{ width: "500px" }} showIcon type='info' closable>
        Your video has been successfully uploaded. It will take a few hours to train your avatar. Once it's ready, you will see it as a new character under the character tab.
      </Message>,
      { placement: 'topEnd', duration: 10000 }
    );
  };

  const handleAvatarVideoUpload = () => {
    if (!videoFile) return;
    setErrorMessage(null);
    setIsUploading(true);

    const config = {
      onUploadProgress: (progressEvent: any) => {
        const loadedKb = Math.round(progressEvent.loaded / 1024);  // Convert bytes to kilobytes
        const totalKb = Math.round(progressEvent.total / 1024);    // Convert bytes to kilobytes
        const percentCompleted = Math.round((progressEvent.loaded * 99) / progressEvent.total);
        setUploadProgress({
          percent: percentCompleted,
          loadedKb: loadedKb,
          totalKb: totalKb
        });
      }
    };

    postRequest(`/avatars/video-uploads/`, { name: avatarName, file_name: videoFile.name, content_type: videoFile.type })
      .then((response) => {
        const { id, url, content_type: contentType } = response.data;

        return putFileRequest(url, videoFile, config.onUploadProgress, { 'Content-Type': contentType })
          .then(() => {
            return putRequest(`/avatars/video-uploads/${id}/`, { status: 'uploaded' });
          })
          .catch((error: any) => {
            // Handle upload failure and update status
            return putRequest(`/avatars/video-uploads/${id}/`, { status: 'failed' }).finally(() => {
              if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
              } else {
                setErrorMessage('Unexpected error occurred. Please try again later.');
              }
            });
          });
      })
      .then(() => {
        setUploadProgress({
          percent: 100,
          loadedKb: videoFile.size / 1024,
          totalKb: videoFile.size / 1024
        });
        setIsUploading(false);
        onClose();
        showToast();
      })
      .catch((error: any) => {
        // Handle any other errors in the promise chain
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('Unexpected error occurred. Please try again later.');
        }
        setIsUploading(false);
      })
      .finally(() => {
        refreshVideoUploadStatus();
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > maxVideoUploadFileSize) {
        setErrorMessage(`File size exceeds the ${maxVideoUploadFileSizeInMb}MB limit. Please upload a smaller file.`);
        return;
      }

      if (acceptedVideoFormats.includes(file.type)) {
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        videoElement.onloadedmetadata = () => {
          if (videoElement.videoWidth > videoElement.videoHeight) {
            setVideoUrl(videoElement.src);
            setVideoFile(file);
            setErrorMessage(null); // Clear any previous error message
          } else {
            setErrorMessage('Only landscape videos are supported. Please upload a video with a 16:9 aspect ratio.');
          }
        };
      } else {
        setErrorMessage('Unsupported file format. Please upload a video in MP4, MOV, or MPEG format.');
      }
    }
  };

  // Reset all inputs when the modal is closed
  useEffect(() => {
    if (!open) {
      setVideoUrl(null);
      setErrorMessage(null);
      setAvatarName('');
      setHasAgreed(false);
      setUploadProgress({ percent: 0, loadedKb: 0, totalKb: 0 });
      setIsUploading(false);
    }
  }, [open]);

  return (
    <Modal 
      open={open} 
      onClose={onClose} 
      size="lg" 
      backdrop="static" 
      style={{ overflowX: 'hidden', maxWidth: '100%' }}
    >
      <Modal.Header>
        <Modal.Title>
          <Stack spacing={70} direction='row' justifyContent='flex-start'>
            <Stack.Item>Create Custom Avatar</Stack.Item>
            {avatarLimitReached && <Stack.Item><span style={{fontSize: "14px", color: "red"}}>Upgrade to a paid plan in order to create a custom avatar or contact sales to increase your avatar limit.</span></Stack.Item> }
          </Stack>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowX: 'hidden' }}>
        <Form fluid>
          <Form.Group>
            <Grid fluid style={{ fontSize: '12px', lineHeight: '1.8' }}>
              <h5 style={{ fontSize: '16px', marginBottom: '10px' }}>Record a 1-Minute Video of Yourself Speaking Naturally</h5>
              <Row>
                <Col xs={3}>
                  <strong style={{ fontSize: "14px" }}>What to say</strong>
                </Col>
                <Col xs={21}>
                  <Row>
                    <Col xs={6}>
                      <strong>Consent Text (first few seconds):</strong>
                    </Col>
                    <Col xs={18} >
                      Clearly state the following sentence: <i>“I, [YOUR_FULL NAME], authorize TalentWell and its service providers to build my avatar.”</i>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <strong>Training Voice (remaining time):</strong>
                    </Col>
                    <Col xs={18}>
                      Speak naturally about any topic you like—weekend plans, a favorite book, or any other subject you’re comfortable with.
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: "4px" }}>
                <Col xs={3}>
                  <strong style={{ fontSize: "14px" }}>Device</strong>
                </Col>
                <Col xs={21}>
                  <strong>Preferred:</strong> Use your phone for higher video quality. <br />
                  <strong>Acceptable:</strong> A computer webcam is also suitable.
                </Col>
              </Row>
              <Row style={{ marginTop: "4px" }}>
                <Col xs={3}>
                  <strong style={{ fontSize: "14px" }}>Orientation</strong>
                </Col>
                <Col xs={21}>
                  Record in Horizontal (landscape) mode.
                </Col>
              </Row>
              <Row style={{ marginTop: "4px" }}>
                <Col xs={3}>
                  <strong style={{ fontSize: "14px" }}>Background</strong>
                </Col>
                <Col xs={21}>
                Choose a quiet location with good lighting, and wear a shirt that is not the same color as the background.
                </Col>
              </Row>
              <Row style={{ marginTop: "4px" }}>
                <Col xs={3}>
                  <strong style={{ fontSize: "14px" }}>Positioning</strong>
                </Col>
                <Col xs={21}>
                  Sit or stand far enough from the camera so that your face and most of your torso are visible (as shown in the silhouette image provided).
                </Col>
              </Row>
              <Row style={{ marginTop: "4px" }}>
                <Col xs={3}>
                  <strong style={{ fontSize: "14px" }}>Gestures</strong>
                </Col>
                <Col xs={21}>
                  Feel free to use natural hand gestures while speaking.
                </Col>
              </Row>
            </Grid>
          </Form.Group>

          <Form.Group>
            <Grid fluid>
              <Row gutter={16} style={{ display: 'flex', justifyContent: 'center' }}>
                <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={CustomAvatarPositioningImage}
                    alt="Sample face and body positioning"
                    style={{ width: '440px', height: 'auto' }}
                  />
                </Col>
                <Col xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div style={{ position: 'relative', width: '433px', height: '245px', border: videoUrl ? '2px dashed #ccc' : '2px dashed #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {videoUrl ? (
                      <video
                        src={videoUrl}
                        style={{ width: '100%', height: '100%', objectFit: 'scale-down' }}
                      />
                    ) : (
                      <div style={{ padding: "30px", fontSize: "12px", textAlign: 'center' }}>
                        <span>Click or drag your video to this area to upload. Use a 16:9 aspect ratio (landscape). Maximum file size is {maxVideoUploadFileSizeInMb}MB. Supported file formats: MP4, MOV, MPEG</span>
                      </div>
                    )}
                    <input
                      type="file"
                      accept={acceptedVideoFormats.join(', ')}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                      onChange={handleFileChange}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>

            {errorMessage && <Message style={{ marginBottom: "10px", marginTop: "10px" }} type="error" showIcon closable={false}>{errorMessage}</Message>}

            <Form.ControlLabel style={{ marginTop: "10px", fontSize: '16px' }}>Avatar Name</Form.ControlLabel>
            <Form.Control 
              name="name" 
              placeholder="Enter avatar name" 
              value={avatarName}
              onChange={(value) => setAvatarName(value)}
              style={{ fontSize: '14px' }}
            />
            {!avatarLimitReached && 
              <Form.ControlLabel>
                <Checkbox 
                  checked={hasAgreed} 
                  onChange={(value: any) => setHasAgreed(!hasAgreed)} 
                  style={{ marginLeft: "-10px", fontSize: '10px', color: 'red' }}>
                  By uploading an avatar training video to TalentWell, I hereby confirm that I have the necessary rights, permissions, and consents to upload and use the video, including all voice, image, and likeness rights. I understand that the content I upload will be used to create a custom avatar, and I affirm that this content is lawful and non-infringing. I agree not to use the platform-generated content for any illegal, fraudulent, or harmful purposes. I reaffirm my obligation to abide by TalentWell's Terms & Conditions and Privacy Policy. I understand that any violation of these terms may result in the suspension or termination of my account, and potential legal action.
                </Checkbox>
              </Form.ControlLabel>
            }
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ overflowX: 'hidden' }}>
        <Grid fluid style={{marginTop: "5px"}}>
          <Row>
            <Col xs={18} style={{ textAlign: "left" }}>
              {isUploading && (
                <div>
                  <Progress.Line
                    percent={uploadProgress.percent}
                    strokeColor="#4caf50"
                    showInfo={true}
                  />
                  <div style={{ marginLeft: "12px", fontSize: "10px" }}>
                    {uploadProgress.loadedKb} KB / {uploadProgress.totalKb} KB uploaded
                  </div>
                </div>
              )}
            </Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              <Button onClick={handleAvatarVideoUpload} appearance="primary" disabled={avatarLimitReached || !videoUrl || !avatarName || !hasAgreed || isUploading}>
                Create Avatar
              </Button>
              <Button onClick={onClose} appearance="subtle">
                Cancel
              </Button>
            </Col>
          </Row>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateAvatarModal;
