import React, { useState } from 'react';

import { Panel, Grid, Row, Col } from 'rsuite';
import 'styles/rsuite.css';

import { CandidateResponse, JobCandidateResponse, CandidateCommentResponse } from 'utils/types'
import CandidateEmailEngagementHistory from 'components/email/CandidateEmailEngagementHistory'
import CandidateMeetingInfo from 'components/meeting/CandidateMeetingInfo';
import CandidateAvatar from 'components/candidate/CandidateAvatar'
import CandidateSocialMedia from "components/candidate/CandidateSocialMedia";
import CandidateCoreSkills from "components/candidate/CandidateCoreSkills";

import { CandidateAwardHistory, CandidateCertificationHistory, CandidateCodeContributionHistory, CandidateEducationHistory, CandidateJobHistory, CandidatePublicationHistory, CandidateRecommendationHistory } from 'components/candidate/history'
import { getCandidateLanguages, getCandidateLastCodeContributions, getCandidateGithubMemberSince, getCandidateContactEmail, getCandidateMostRecentTitle, getCandidateMostRecentDegree, getCandidateCurrentFocus, getCandidateCurrentLocation, getCandidateYearsOfCodingExperience, getCandidateYearsOfWorkExperience, getCandidateCurrentCompany, getCandidateCurrentName } from 'utils/candidate_data_access';

type props = {
  jobCandidate: JobCandidateResponse
  candidateComment: CandidateCommentResponse
  updateEngagementHistory: boolean
  updatedEngagementHistory: () => void  
  editDraft?: () => void  
}

const CandidateDetailContent = ({ jobCandidate, candidateComment, updateEngagementHistory, updatedEngagementHistory, editDraft }: props) => {
  const candidate = jobCandidate.candidate as CandidateResponse
  const [twitterUrl, setTwitterUrl] = useState<any>(null)

  const cellStyles = {
    paddingLeft: '10px',
    fontWeight: '300'
  }

  return (
    <Grid fluid style={{marginLeft: '-5px', marginRight: '-5px'}}>
      <Row>
        {/*<!---- Left Column ---> */}
        <Col md={10}>
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <Row>
              <Col lg={10} xl={8} xxl={7}>
                <CandidateAvatar size="xxl" candidate={candidate}/>
              </Col>
              <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={17}>
              <table>
                <tr>
                  <td align='left' valign='top'>Name:</td>
                  <td style={cellStyles}>{getCandidateCurrentName(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Contact:</td>
                  <td style={cellStyles}>{getCandidateContactEmail(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Location:</td>
                  <td style={cellStyles}>{getCandidateCurrentLocation(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Degree:</td>
                  <td style={cellStyles}>{getCandidateMostRecentDegree(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Company:</td>
                  <td style={cellStyles}>{getCandidateCurrentCompany(candidate, '', '')}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Title:</td>
                  <td style={cellStyles}>{getCandidateMostRecentTitle(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Specialty:</td>
                  <td style={cellStyles}>{getCandidateCurrentFocus(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Languages:</td>
                  <td style={cellStyles}>{getCandidateLanguages(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Work&nbsp;Experience:</td>
                  <td style={cellStyles}>{getCandidateYearsOfWorkExperience(candidate)}</td>
                </tr>
                <tr>
                  <td align='left' valign='top'>Coding&nbsp;Experience:</td>
                  <td style={cellStyles}>{getCandidateYearsOfCodingExperience(candidate)}</td>
                </tr>
              </table>
              </Col>
            </Row>
          </Panel>
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Social Profile</h6>
            <CandidateSocialMedia candidate={candidate} showText={true} setTwitterUrl={setTwitterUrl}/>
          </Panel>

          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Insights</h6>
            <Grid fluid>
                <Row>
                  <Col xs={8}>Last code contributions:</Col><Col style={cellStyles}>{getCandidateLastCodeContributions(candidate)}</Col>
                </Row>                  
                <Row>
                  <Col xs={8}>Github member since:</Col><Col style={cellStyles}>{getCandidateGithubMemberSince(candidate)}</Col>
              </Row>
            </Grid>
          </Panel>

          {candidate?.recommendations?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Recommendations</h6>
            <CandidateRecommendationHistory candidate={candidate}/>      
          </Panel>)}
          
          {candidate?.code_publications?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Code Repositories</h6>
            <CandidateCodeContributionHistory candidate={candidate}/>
          </Panel>)}

          {candidate?.text_publications?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Publications</h6>
            <CandidatePublicationHistory candidate={candidate}/>
          </Panel>)}
        </Col>

        {/*<!---- Middle Column ---> */}
        <Col md={10}>
          <Panel className='body-panel'>
            <h6>Tech Skills</h6>
            <CandidateCoreSkills candidate={candidate}/>
          </Panel>

          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Bio/Summary</h6>
            <p className="fw-light">{candidate.summary}</p>
          </Panel>

          {candidate?.work_experiences?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Job History</h6>
            <CandidateJobHistory candidate={candidate}/>
          </Panel>)}

          {candidate?.educations?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Education</h6>
            <CandidateEducationHistory candidate={candidate}/>
          </Panel>)}

          {candidate?.certifications?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Certifications</h6>
            <CandidateCertificationHistory candidate={candidate}/>
          </Panel>)}

          {candidate?.awards?.length > 0 && (
          <Panel className='body-panel' shaded style={{fontSize: '14px'}}>
            <h6>Awards</h6>
            <CandidateAwardHistory candidate={candidate}/>
          </Panel>)}
        </Col>

        {/*<!---- Right Column ---> */}
        <Col md={4}>
          <Panel className='body-panel' shaded style={{fontSize: '11px'}}>
              <h6>Comments</h6>
              <span>{candidateComment && candidateComment?.text ? candidateComment.text : 'To add comments, use top comment button.'}</span>
          </Panel>
          <Panel className='body-panel' shaded style={{fontSize: '11px'}}>
              <h6>Meeting Info</h6>
              <CandidateMeetingInfo jobCandidate={jobCandidate}/>
          </Panel>
           <Panel className='body-panel' shaded style={{fontSize: '11px'}}>
              <h6>Outreach</h6>
              <CandidateEmailEngagementHistory 
                updateHistory={updateEngagementHistory} 
                updatedHistory={updatedEngagementHistory} 
                jobCandidate={jobCandidate}
                editDraft={editDraft}/>
          </Panel>
        </Col>
      </Row>
    </Grid>
  );
};

export default CandidateDetailContent;