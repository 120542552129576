import React from "react";
import { Stack, IconButton } from 'rsuite';

import PageNextIcon from '@rsuite/icons/PageNext';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';

type Props = {
    numberOfSteps: number
    currentStep: number
    setNextStep: (step: number) => void  
    nextStepLabel?: string
  };
  
  const WizardButtonGroup = ({ nextStepLabel, numberOfSteps, currentStep, setNextStep } : Props) => {
  return (
    <Stack justifyContent="space-between">
    {currentStep !== 0 && (
      <IconButton id='backButton' icon={<PagePreviousIcon />} onClick={() => setNextStep(Math.max(currentStep - 1, 0))}>
        Back
      </IconButton>
    )}

    {currentStep !== numberOfSteps - 1 && (
      <IconButton
        id='continueButton'
        icon={<PageNextIcon />}
        placement="right"
        appearance="primary"
        type='submit'
      >
        {nextStepLabel ? nextStepLabel : 'Continue'}
      </IconButton>
    )}
  </Stack>  
  );
};

export default WizardButtonGroup;  