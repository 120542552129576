import React, { useState, useEffect } from 'react';

import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";

import { Timeline, IconButton } from 'rsuite';

import EmailIcon from '@rsuite/icons/Email';
import TimelineIcon from '@rsuite/icons/legacy/DotCircleO';
import { capitalize } from 'utils/utils'

import EmailDrawerView from 'components/email/EmailDrawerView';

import { JobCandidateResponse, JobCandidateEngagmentStatus, TrackingEmail } from 'utils/types'
import { trackCandidate } from 'utils/utils'

type Props = {
  jobCandidate: JobCandidateResponse
  setLastEmailStatus?: (status: string) => void
  updateHistory: boolean
  updatedHistory: () => void
  editDraft?: () => void
};

const CandidateEmailEngagementHistory = ( { jobCandidate, setLastEmailStatus, updateHistory, updatedHistory, editDraft }: Props) => {
  const [activeDrawer, setActiveDrawer] = useState(-1);

  const { data: engagementStatus, trigger } = useSWRMutation<JobCandidateEngagmentStatus>(`/jobs/${jobCandidate.job_id}/candidates/${jobCandidate.candidate.id}/emails/engagement_history/`, fetcher)

  useEffect(() => {
    if (updateHistory) {
      updatedHistory()
      trigger()
    }
  })

  function handleEditDraft() {
    if (editDraft) {
      editDraft()
    }
  }

  function openDrawer(index: number) {
    trackCandidate(jobCandidate.candidate.id)
    setActiveDrawer(index)
  }

  function closeDrawers() {
    setActiveDrawer(-1)
  }

  if (!engagementStatus) {
    return <div>No engagements found.</div>;
  }

  const emails: any = {}
  if (engagementStatus.emails && engagementStatus.emails.length > 0)
    engagementStatus.emails.map((email) => {emails[email.id] = email})
  else
    return <div>No engagements found.</div>

  function renderEngagementStatusByEmail(email: TrackingEmail, index: number) {
    var event_time = email.processed_time
    switch (email.status) {
      case 'draft':
        event_time = email.created
        break;
      case 'scheduled':
        event_time = email.desired_sent_time
          break;        
      case 'queued':
        event_time = email.desired_sent_time
          break;        
      case 'sent':
        event_time = email.sent_time
        break;
      case 'delivered':
        event_time = email.delivered_time
        break;
      case 'opened':
        event_time = email.opened_time
        break;
      case 'bounced':
        event_time = email.delivered_time
        break;
      case 'failed':
        event_time = email.processed_time
        break;
    }    

    return (<>
      {(email.status === 'scheduled' || email.status === 'queued') ? 
        <Timeline.Item dot={timelineIcon()}>
            {(email.desired_sent_time === undefined || email.desired_sent_time === null) ? 
              <p>Sent {<IconButton size="xs" icon={<EmailIcon onClick={() => openDrawer(index)}/> }/>}</p>
              :
              <p>Scheduled for {new Date(email.desired_sent_time).toLocaleString()} {<IconButton size="xs" icon={<EmailIcon onClick={() => openDrawer(index)}/> }/>}</p>
            }
        </Timeline.Item>
      :
        <Timeline.Item key={index.toString()} dot={timelineIcon()}>
            {new Date(event_time).toLocaleString()}
            <p>{buildTimelineMessage(email.status, email.type)} {
              (email.status === 'draft' ? 
                <IconButton size="xs" icon={<EmailIcon onClick={() => handleEditDraft()}/> }/>
                :
                <IconButton size="xs" icon={<EmailIcon onClick={() => openDrawer(index)}/> }/>
            )}</p>
        </Timeline.Item>
      }
      <EmailDrawerView email={email} open={activeDrawer === index} onClose={closeDrawers} />
  </>)
  }  

  function buildTimelineMessage(status: string, email_type: string) {
    var message: string = 'Unknown event found ' + email_type + ' ' + status
    email_type = capitalize(email_type)

    if (setLastEmailStatus) {
      setLastEmailStatus(status)
    }

    switch (status) {
      case 'draft':
        message = 'Email draft created'
        break;
      case 'scheduled':
          message = email_type + ' email sent'
          break;        
      case 'queued':
          message = email_type + ' email sent'
          break;        
      case 'sent':
        message = email_type + ' email sent'
        break;
      case 'delivered':
        message = email_type + ' email delivered'
        break;
      case 'opened':
        message = email_type + ' email opened'
        break;
      case 'bounced':
        message = email_type + ' email bounced'
        break;
      case 'failed':
        message = email_type + ' email failed to send. Contact customer support under Account->Support.'
        break;
    }    

    return message
  }

  function timelineIcon() {
    return <TimelineIcon style={{ background: '#15b215', color: '#fff' }} />
  }

  return (
  <>
      <Timeline className='custom-timeline'>
        {engagementStatus.emails.map((email, index) => renderEngagementStatusByEmail(email, index) )}
      </Timeline>
  </>
  );
};

export default CandidateEmailEngagementHistory;