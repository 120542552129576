import React, { useRef, useState, useEffect } from 'react';
import { Form, Schema, Stack, InputGroup, Divider, useToaster, Loader } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { VscLock, VscWorkspaceTrusted } from 'react-icons/vsc';
import RadioTile from 'components/elements/RadioTile';
import FormHeader from 'components/elements/FormHeader';
import { postRequest } from "utils/axios";

import { JobRequest } from 'utils/types'
import WizardButtonGroup from 'pages/protected/jobs/wizard/WizardButtonGroup';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'

type Props = {
  jobRequest: JobRequest
  setJobRequest: (job: JobRequest) => void
  numberOfSteps: number
  currentStep: number
  setNextStep: (step: number) => void
};

const JobManualImportForm = ({ jobRequest, setJobRequest, numberOfSteps, currentStep, setNextStep } : Props) => {
  const [visibility, setVisibility] = useState(jobRequest.visibility)
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [formValue, setFormValue] = useState<any>({ name: null, role: null })

  const toaster = useToaster();
  const formRef: any = useRef()
  
  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    name: StringType().isRequired("This field is required"),
    role: StringType().isRequired("This field is required") 
  }) 

  useEffect(() => {
    if (!formValue.name) {
      setFormValue({
        name: jobRequest.name,
        role: jobRequest.role
      })
    }
  })

  const handleSubmit = (event: any) => {
    if (formRef.current.check())  {
      setLoading(true);
      const jobPromise = postRequest('/jobs/', jobRequest)    
      jobPromise.then((response) => {
        updateFields(response.data)
        setNextStep(Math.min(currentStep + 1, 2))
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  function updateFields(response: any) {
    setJobRequest({ ...jobRequest,
      id: response.id,
      name: response.name,
      description: response.description,
      role: response.role    
    })
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      name: record.name,
      role: record.role
    })
   
    setJobRequest({ ...jobRequest,
      name: record.name,
      role: record.role
    })
  }

  const handleVisibility = (value: any) => {
    setVisibility(value)
    setJobRequest({ ...jobRequest,
      visibility: value
    })
  }

  return (<>
    <Form 
        ref={formRef}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        onSubmit={(data: any) => handleSubmit(data)}
        fluid>
      <FormHeader
        title="Adding Manually"
        description="Provide a name and role for this job. More details can be entered later."
      />
      <Form.Group controlId="name">
        <Form.ControlLabel>Job Name</Form.ControlLabel>
        <Form.Control name="name"/>
        <Form.HelpText>
          The name should include any relevant key information about this job.
        </Form.HelpText>
      </Form.Group>

      <Form.Group controlId="role">
        <Form.ControlLabel>Job Role/Title</Form.ControlLabel>
        <Form.Control name="role"/>
        <Form.HelpText>
          This should be the internal role or title used for this job.
        </Form.HelpText>
      </Form.Group>    

      <Form.Group controlId="visibility">
        <Form.ControlLabel>Visibility Level</Form.ControlLabel>
        <Stack spacing={10} direction="row" alignItems={'stretch'}>
          <RadioTile
            icon={<Icon as={VscLock} />}
            title="User"
            value={visibility}
            name="user"
            onChange={handleVisibility}
          >
            Only you will be able to work on this job.
          </RadioTile>
          <RadioTile
            icon={<Icon as={VscWorkspaceTrusted} />}
            title="Business"
            value={visibility}
            name="business"
            onChange={handleVisibility}
          >
            This job will be accessible to everyone in your business.
          </RadioTile>
        </Stack>
      </Form.Group>

      <Divider />

      <WizardButtonGroup nextStepLabel='Build' numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} />
    </Form>
</>);
};

export default JobManualImportForm;
