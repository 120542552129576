import React, { useState, useEffect } from "react";

import { AccessTrackerEvent } from 'utils/types'
import { trackJob } from 'utils/utils'

type props = {
  jobId: string
}

const JobAccessTrackerLongView = ({ jobId }: props) => {
  const [initialized, setInitialized] = useState(false);
  const [accessTrackerEvent, setAccessTrackerEvent] = useState<AccessTrackerEvent>()

  useEffect(() => {
    if (jobId && !initialized) {
      setInitialized(true)
      const patchPromise = trackJob(jobId) 
      patchPromise.then((response) => {
        if (response.status < 300) {
          setAccessTrackerEvent(response.data)
        }
      })
    }
  })

  if (accessTrackerEvent) {
    if (accessTrackerEvent.previous_access_time) {
      return <div style={{fontSize: '12px'}}>Last viewed on {new Date(accessTrackerEvent.previous_access_time).toLocaleString()}. Viewed {accessTrackerEvent.access_count} times since {new Date(accessTrackerEvent.created).toLocaleString()}.</div>
    } else {
      return <div style={{fontSize: '12px'}}>Last viewed on {new Date(accessTrackerEvent.created).toLocaleString()}. Viewed {accessTrackerEvent.access_count} times since {new Date(accessTrackerEvent.created).toLocaleString()}.</div>
    }
  } else {
    return <></>
  }
}

export default JobAccessTrackerLongView;