import React, { useRef, useState, useEffect } from 'react';
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { RootState } from "store";
import { useSelector } from "react-redux";

import { patchRequest } from "utils/axios";

import { Form, Loader, useToaster, Schema, Stack, Button, Checkbox } from 'rsuite';
import Textarea from 'components/elements/Textarea';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

import { Business } from 'types';

const BusinessContent = () => {
  const toaster = useToaster();
  const formRef: any = useRef()
  const user = useSelector((state: RootState) => state.auth.user);

  const { data, error, mutate } = useSWR<any>(`/businesses/${user?.business_id}/`, fetcher)  
  const business: Business = data

  const [initialized, setInitialized] = useState(false);
  const [formError, setFormError] = useState({});    

  const [formValue, setFormValue] = useState({
    name: '',
    name_short: '',
    description: '',
    acts_on_behalf: false,
    email_provider: '',
    product_subscription_display_name: '',
    type: '',
    industry: '',
    address: '',
    country_iso: '',
    state_iso: '',
    city: '',
    postal: '',
    website: '',
    news_url: '',
    blog_url: '',
    medium: '',
    twitter: '',
    facebook: '',
    linkedin: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    reply_email: ''
  })  

  useEffect(() => {
    if (business && !initialized) {
      setInitialized(true)
      setFormValue({
        name: business.name,
        name_short: business.name_short,
        description: business.description,
        acts_on_behalf: business.acts_on_behalf,
        email_provider: business.email_provider,
        product_subscription_display_name: business.product_subscription_display_name,
        type: business.type,
        industry: business.industry,
        address: business.address,
        country_iso: business.country_iso,
        state_iso: business.state_iso,
        city: business.city,
        postal: business.postal,
        website: business.website,
        news_url: business.news_url,
        blog_url: business.blog_url,
        medium: business.medium,
        twitter: business.twitter,
        facebook: business.facebook,
        linkedin: business.linkedin,
        contact_name: business.contact_name,
        contact_email: business.contact_email,
        contact_phone: business.contact_phone,        
        reply_email: business.reply_email
      })
    }
  })  

  if (!business)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching business details..."/>
      </div>
    );

  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    name: StringType().isRequired("This field is required"),
    name_short: StringType().isRequired("This field is required"),
    description: StringType().isRequired("This field is required"),
    contact_email: StringType().isEmail(),
    website: StringType().isURL(),
    news_url: StringType().isURL(),
    blog_url: StringType().isURL(),
    medium: StringType().isURL(),
    twitter: StringType().isURL(),
    facebook: StringType().isURL(),
    linkedin: StringType().isURL()
  })     

  const handleSubmit = (event: any) => {
    if (formRef.current.check()) {
      const businessUpdateRequest = {
        name: formValue.name,
        name_short: formValue.name_short,
        description: formValue.description,
        acts_on_behalf: formValue.acts_on_behalf,
        type: formValue.type,
        industry: formValue.industry,
        address: formValue.address,
        country_iso: formValue.country_iso,
        state_iso: formValue.state_iso,
        city: formValue.city,
        postal: formValue.postal,
        website: formValue.website,
        news_url: formValue.news_url,
        blog_url: formValue.blog_url,
        medium: formValue.medium,
        twitter: formValue.twitter,
        facebook: formValue.facebook,
        linkedin: formValue.linkedin,
        contact_name: formValue.contact_name,
        contact_email: formValue.contact_email,
        contact_phone: formValue.contact_phone,
        reply_email: formValue.reply_email         
      };
  
      const patchPromise = patchRequest(`/businesses/${business.id}/`, businessUpdateRequest)      
      patchPromise.then((response) => {
        toaster.push( <RequestSuccessMessage message='Business successfully updated' toaster={toaster}/> )
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      })
    }
  }

  const handleActOnBehalfChange = (value: any, checked: boolean, event: any) => {
    setFormValue({ ...formValue, acts_on_behalf: checked })
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      name: record.name,
      name_short: record.name_short,
      description: record.description,
      type: record.type,
      industry: record.industry,
      address: record.address,
      country_iso: record.country_iso,
      state_iso: record.state_iso,
      city: record.city,
      postal: record.postal,
      website: record.website,
      news_url: record.news_url,
      blog_url: record.blog_url,
      medium: record.medium,
      twitter: record.twitter,
      facebook: record.facebook,
      linkedin: record.linkedin,
      contact_name: record.contact_name,
      contact_email: record.contact_email,
      contact_phone: record.contact_phone,
      reply_email: record.reply_email
    })
  }  

  const formFieldSize = 400

  return (
    <Form style={{width: '100%'}}
        ref={formRef}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCheck={setFormError}
        formValue={formValue}
        model={model}    
        fluid>
      <Stack spacing={10} direction='column' alignItems='flex-end' justify-content='flex-end'>
        <Button appearance="primary" type='submit'>Update Business</Button>
      </Stack>          

      <Form.Group controlId="name">
        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='flex-end' wrap={true}>
         <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Name<sup>*</sup></Form.ControlLabel>
            <Form.Control name="name" />
            <Form.HelpText>
            Your official business name.
            </Form.HelpText>
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Short Name<sup>*</sup></Form.ControlLabel>
            <Form.Control name="name_short" />
            <Form.HelpText>
            Your Doing Business As (DBA) name that candidates will see in your email communications.
            </Form.HelpText>
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Acts on Behalf of other Companies</Form.ControlLabel>
            <Checkbox onChange={handleActOnBehalfChange} checked={formValue.acts_on_behalf}/>
            <Form.HelpText>
            If you are an agency that acts on behalf of other companies, check this box, and then add those companies under <strong>Account &gt; Companies</strong>. You can edit the job details to associate the job with a specific company. 
            </Form.HelpText>
          </Stack.Item>
        </Stack>          

        <Form.ControlLabel>Description<sup>*</sup></Form.ControlLabel>
        <Form.Control name="description" rows={5} accepter={Textarea} />
        <Form.HelpText>
        Enter a concise overview of your business for potential candidates.
        </Form.HelpText>
      </Form.Group>

      <Form.Group controlId="type">
        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='space-between' wrap={true}>
          <Stack.Item alignSelf='stretch' basis={200}>
            <Form.ControlLabel>Type of Business</Form.ControlLabel>
            <Form.Control name="type" />
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={200}>
            <Form.ControlLabel>Industry</Form.ControlLabel>
            <Form.Control name="industry" />
          </Stack.Item>
        </Stack>
      </Form.Group>

      <Form.Group controlId="news">
        <h4>Social Media</h4>
        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='space-between' wrap={true}>    
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Website</Form.ControlLabel>
            <Form.Control name="website" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>News</Form.ControlLabel>
            <Form.Control name="news_url" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Blog</Form.ControlLabel>
            <Form.Control name="blog_url" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Medium</Form.ControlLabel>
            <Form.Control name="medium" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>X (Twitter)</Form.ControlLabel>
            <Form.Control name="twitter" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Facebook</Form.ControlLabel>
            <Form.Control name="facebook" />
          </Stack.Item>    

          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>LinkedIn</Form.ControlLabel>
            <Form.Control name="linkedin" />
          </Stack.Item>    
        </Stack>
      </Form.Group>

      <Form.Group controlId="address">
        <h4>Address</h4>
        <Form.ControlLabel>Street</Form.ControlLabel>
        <Form.Control name="address" />

        <Stack spacing={20} direction='row' alignItems='flex-start' justify-content='space-between' wrap={true}> 
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>City</Form.ControlLabel>
            <Form.Control name="city" />
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>State</Form.ControlLabel>
            <Form.Control name="state_iso" />
          </Stack.Item>
          <Stack.Item alignSelf='stretch' basis={formFieldSize}>
            <Form.ControlLabel>Country</Form.ControlLabel>
            <Form.Control name="country_iso" />
          </Stack.Item>
        </Stack>
      </Form.Group>      

      <Form.Group controlId="contact">
        <h4>Contact</h4>
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="contact_name" />

        <Form.ControlLabel>Phone</Form.ControlLabel>
        <Form.Control name="contact_phone" />

        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="contact_email" />
      </Form.Group>
     
      <Form.Group controlId="contact">
        <h4>Candidate Replies</h4>
        <Form.ControlLabel>ReplyTo Email</Form.ControlLabel>
        {formValue.email_provider === 'dns' ? 
          <Form.Control name="reply_email" placeholder='Here you can enter an email that candidates will reply to as opposed to using your personal email.'/>
          :
          <Form.Control name="reply_email" readOnly placeholder='To use this feature, contact customer support (Account > Support) to configure email delivery through DNS and MX records. '/>
        }
      </Form.Group>
     
      <Stack spacing={10} direction='column' alignItems='flex-end' justify-content='flex-end'>
        <Button appearance="primary" type='submit'>Update Business</Button>
      </Stack>          
    </Form>
  );
};

export default BusinessContent;
