import React from 'react';
import Chart from 'react-apexcharts';
import { Panel } from 'rsuite';

interface SparklineProps {
  title: string;
  total: number;
  data: any;
  text: any;
  type?: any
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  options?: any;
  labels?: any[];
}

const Sparklines = ({ title, data, total, text, type, labels, options }: SparklineProps) => {
  const defaultOptions = {
    plotOptions: {
      bar: {
        columnWidth: '80%'
      }
    },    
    chart: {
      type: type,
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      fixed: {
        enabled: true,
        offsetY: 30
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return 'Candidates:'
          }
        }
      },
      marker: {
        show: false
      }            
    },
    xaxis: {
      crosshairs: {
        width: 10
      },
    },
    title: {
      text: total,
      offsetX: 0,
      style: {
        fontSize: '24px',
      }
    },
    subtitle: {
      text: title,
      offsetX: 0,
      style: {
        fontSize: '16px',
      }
    },
    colors: ["#0099FF"]
  }

  return (
    <Panel className="card" >
        <div style={{fontSize: "12px", fontWeight: "500", position: "absolute", right: "10px", top: "8px"}}>{text}</div>
        <Chart
          series={data}
          type={type}
          height={150}
          options={Object.assign({}, defaultOptions, options, { labels })}
        ></Chart>
    </Panel>
)};

export default Sparklines;
