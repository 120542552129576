import Moment from 'moment';
import { renderYearsOfExperience, percentageScoreToStars, capitalize } from 'utils/utils'
import { stateIsoToName } from 'utils/option_utils'

const placeholderImage = null
const minImageWidth = 20
const minImageHeight = 20

export function getCandidateCurrentName(candidate: any) {
    return candidate.full_name
}

export function getCandidateContactEmail(candidate: any) {
    return candidate.email
}

export function getCandidateSummary(candidate: any) {
    return candidate.summary
}

export function getCandidateLastCodeContributions(candidate: any) {
    if (candidate.last_social_activity_date) {
        return new Date(candidate.last_social_activity_date).toLocaleDateString()
    } else {
        return 'Unknown'
    }
}

export function getCandidateGithubMemberSince(candidate: any) {
    return Moment(candidate.github.account_created).format('MMM Y')
}

export function getCandidateIndicatesHiringInterest(candidate: any) {
    return candidate.indicates_hiring_interest ? 'Yes' : 'No'
}

export function getCandidateCurrentCompany(candidate: any, prefix: string, alternative: string) {
    var company = null
    if (candidate?.normalized_job_title) {
        company = candidate?.normalized_job_title.company
    } else {
        company = candidate.company
    }

    return company ? prefix + capitalize(company) : alternative
}

export function getLikelyToMoveScore(candidate: any, stars_to_map_to: number) {
    if (candidate?.likely_to_move_score) {
        return percentageScoreToStars(candidate.likely_to_move_score, stars_to_map_to)
    } else {
        return null
    }
}

export function getCandidateMostRecentTitle(candidate: any) {
    var title = null
    if (candidate?.normalized_job_title) {
        title = candidate?.normalized_job_title.title
    } else {
        title = candidate.title
    }

    return title ? title : 'Unknown'
}

export function getCandidateMostRecentDegree(candidate: any) {
    if (candidate?.degree) {
        return candidate?.degree
    } else {
        return 'Unknown'
    }
}

export function getCandidateCurrentFocus(candidate: any) {
    var focus = []
    if (candidate?.normalized_job_title && candidate?.normalized_job_title.focus) {
      focus = candidate?.normalized_job_title.focus
    }

    if (focus.length > 0) {
      return focus.join(', ')      
    } else {
      return 'Unknown'
    }
}

export function getCandidateLanguages(candidate: any) {
    if (candidate?.languages && candidate?.languages.length > 0) {
        return candidate?.languages.join(', ')
    } else {
        return 'Unknown'
    }
}

export function getCandidateCurrentCoreSkills(candidate: any) {
    if (candidate?.core_skills && candidate?.core_skills.length > 0) {
        return candidate?.core_skills.join(', ')
    } else {
        return 'Unknown'
    }
}

function attemptToSetActiveImageUrl(img: any, images: any, setImage: (image: any) => void) {
    console.debug("Found active image " + img.src + " of size " + img.naturalWidth + " x " + img.naturalHeight)
    if (img.naturalWidth < minImageWidth || img.naturalHeight < minImageHeight) {
        console.debug("Required size to small " + img.naturalWidth + "x" + img.naturalHeight)                
        setBackupImageUrl(images, setImage);
    } else {
        console.debug("Has active image within required size")
        setImage(img.src);
    }
    return true
}

function attemptToSetBackupImageUrl(img: any, images: any, setImage: (image: any) => void) {
    console.debug("Found backup image of size " + img.naturalWidth + " x " + img.naturalHeight)
    if (img.naturalWidth < minImageWidth || img.naturalHeight < minImageHeight) {
        console.log("Required backup size to small " + img.naturalWidth + "x" + img.naturalHeight)                
        setImage(getGenericBackupImageUrl());
    } else {
        console.debug("Has backup image within required size")
        setImage(img.src);
    }
}

export function setActiveImageUrl(images: any, setImage: (image: any) => void) {
    if (images) {
        console.log("Has images: " + JSON.stringify(images))
        const activeImage = images.find((image: any) => image && image.is_active);
        if (activeImage) {
            console.debug("Has active image")
            const img = new Image();
            img.src = activeImage.image
            console.debug("Active image: " + img.src)
            img.onload = () => attemptToSetActiveImageUrl(img, images, setImage)
            img.onerror = () => attemptToSetActiveImageUrl(img, images, setImage)
        } else {
            setBackupImageUrl(images, setImage);
        }
    } else {
        setImage(getGenericBackupImageUrl());
    }
}

export function setBackupImageUrl(images: any, setImage: (image: any) => void) {
    const minWidth = 20
    const minHeight = 20

    if (images) {
        console.debug("Has backup images -> " + JSON.stringify(images))
        const activeImage = images.find((image: any) => image && !image.is_active)
        if (activeImage) {
            console.debug("Has backup image")
            const img = new Image();
            img.src = activeImage.image
            img.onload = () => attemptToSetBackupImageUrl(img, images, setImage)
            img.onerror = () => attemptToSetBackupImageUrl(img, images, setImage)
        } else {
            setImage(getGenericBackupImageUrl())
        }
    } else {
        setImage(getGenericBackupImageUrl())
    }
}

export function getGenericBackupImageUrl() {
    console.debug("Falling back to placeholder image")
    return placeholderImage
}

export function getCandidateCurrentLocation(candidate: any) {
    if (candidate?.geo_location) {
        const geoLocation = candidate?.geo_location
        let location = []
        if (geoLocation.city)
            location.push(geoLocation.city)
        if (geoLocation.state_iso)
            location.push(stateIsoToName(geoLocation.state_iso))
        if (geoLocation.country_iso)
            location.push(geoLocation.country_iso)
        return location.join(', ')
    } else {
     return 'Unknown'
    }
  }

  export function getCandidateLatestEducation(candidate: any) {
    if (candidate?.educations && candidate?.educations.length > 0) {
        const educations = candidate?.educations

        let education = educations[0]
        let education_display = []
        if (education.degree)
            education_display.push(education.degree)
        if (education.institution)
            education_display.push(education.institution)
        return education_display.join(', ')
    } else {
        return 'Unknown'
    }
  }  

export function getCandidateYearsOfWorkExperience(candidate: any) {
    const experience = renderYearsOfExperience(candidate.career_start_date) 
    return experience ? experience : 'Unknown'
}

export function getCandidateYearsOfCodingExperience(candidate: any) {
    const experience = renderYearsOfExperience(candidate.coding_start_date) 
    return experience ? experience : 'Unknown'
}