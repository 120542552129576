import iso_states from "us_states_and_ca_regions";

import { getRequest, postRequest } from "utils/axios";

export function extractOptionValues(options: any[]) {
    return options.map(item => (item.value));
  }

export function buildSelectedOption(availableOptions: any[], selectedOptions: any[]) {
    return availableOptions.filter(option => {
      return selectedOptions.includes(option.value)
    });
  }
 
export  function createOptionMap(values: any[]) {
    return values.map(value => ({ label: value, value: value }));
  }

export const loadSkillOptions = (option: string, options: any) =>
  new Promise<any[]>((resolve) => {
    const lowercase_option = option.toLowerCase()
    const availableOptions = options.filter((toBeFilteredOption: any) => {
      toBeFilteredOption.label.toLowerCase().includes(lowercase_option)
    })
    if (availableOptions.length > 0) {
      resolve(availableOptions)
    } else {
      setTimeout(() => {
        const optionPromise = getRequest('/skills/?limit=10&query=' + encodeURIComponent(option))
        optionPromise.then((response: any) => {
          resolve(response.data)
        })
      }, 1000);
    }
  }); 

export function stateIsoToName(iso_state: string) {
  if (iso_state) {
    const states = iso_states.filter((state: any) => state.value === iso_state)
    if (states.length > 0) {
      return states[0].label
    }
  }
  
  return iso_state
}