import Moment from 'moment';
import { Row } from 'rsuite';

interface Props {
    candidate: any;
}

const CandidateCodeContributionHistory = ({ candidate }: Props) => {
    const code_publications = candidate?.code_publications

    if (code_publications !== null) {
        return (<>
            {code_publications.map((publication: any, index: any) => {
              const pub = publication.candidate_publication;
              return (
                <Row key={index}>
                  <div className="profile-section-card__contents" style={{marginLeft: '6px', marginBottom: '20px'}}>
                    <span className="fw-normal fs-6">{pub.publish_date && Moment(pub.publish_date).format('MMM Y') + ': '}</span><a className='fw-normal' href={pub.url} target="_blank"> {pub.title}</a>
                    {pub.content && <span className="fw-light"><br/>{pub.content}</span>}
                    </div>
                </Row>              
              )})}
        </>);
    } else {
        return (<>No known code contributions</>)        
    }
};

export default CandidateCodeContributionHistory;
