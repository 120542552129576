import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router';

import { Panel, Breadcrumb, Stack } from 'rsuite';

import BusinessOnBehalfEditContent from './BusinessOnBehalfEditContent'

const BusinessOnBehalfEdit = () => {
  const navigate = useNavigate()
  const params = useParams()

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <h3 className="title">Company</h3>
      </Stack>

      <Breadcrumb>
        <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" onClick={() => {navigate('/companies')}}>Companies</Breadcrumb.Item>
        <Breadcrumb.Item active>Company</Breadcrumb.Item>
      </Breadcrumb>
    </>}>
    </Panel>

    <Panel className='body-panel'  shaded>
      <BusinessOnBehalfEditContent businessOnBehalfId={params.companyId}/>
    </Panel>
</>)};

export default BusinessOnBehalfEdit;