import React from 'react';
import { Row, Col, ButtonGroup, Button, Grid } from 'rsuite';
import BarChart from 'components/analytics/charts/BarChart';
import HorizontalBarChart from 'components/analytics/charts/HorizontalBarChart';
import PieChart from 'components/analytics/charts/PieChart';
import Sparklines from 'components/analytics/charts/Sparklines';
import DataTable from 'components/analytics/charts/DataTable';

const responseBreakdownOptions = {
    tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: true
    },
    y: {
      title: {
        formatter: function (seriesName: any) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }       
  }     
}

type Props = {
  dateRange: any
  dateRangeChanged: any
  setDateRangeChanged: (hasChanged: boolean) => void
}

const AnalyticsContent = ( { dateRange, dateRangeChanged, setDateRangeChanged } : Props) => {
  return (
    <Grid fluid style={{padding: "0px"}}>
      <Row gutter={10} className="dashboard-header">
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Sparklines
            title="Viewed"
            total={1234}
            text=""
            data={[{data: sparklineData}]}
            labels={[]}
            type="bar"
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Sparklines
            title="Contacted"
            total={521}
            text="Contact Rate: 42.3%"
            data={[{data: sparklineData}]}
            labels={[]}
            type="bar"
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Sparklines
            title="Responded"
            total={74}
            text="Response Rate: 14.2%"
            data={[{data: sparklineData}]}
            labels={[]}
            type="bar"
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
         <Sparklines
            title="Scheduled"
            total={4}
            text="Schedule Rate: 5.0%"
            data={[{data: sparklineData}]}
            labels={[]}
            type="bar"
          />
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6}>
          <PieChart
            title="Response Status"
            data={[443, 74, 4]}
            type="donut"
            labels={['Not Responded', 'Responded', 'Scheduled']}
          />
        </Col>
         <Col xs={24} sm={24} md={12} lg={8} xl={9} xxl={9}>
          <HorizontalBarChart
            title="Number of Candidates who Responded"
            data={[
              {name: "Responded", data: [7, 14, 20, 15, 12, 8]},
              {name: "Scheduled", data: [3, 4, 3, 1, 0, 1]}
            ]}
            type="bar"
            labels={['Initial', 'Follow Up 1', 'Follow Up 2', 'Follow Up 3', 'Follow Up 4', 'Follow Up >4']}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={9} xxl={9}>
          <HorizontalBarChart
            title="Number of Days until Responded"
            data={[
              {name: "Responded", data: [7, 14, 20]}
            ]}
            type="bar"
            labels={['Opened', 'Clicked', 'Scheduled']}
            options={responseBreakdownOptions}
          />
        </Col>        
      </Row>


      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={14}>
          <BarChart
            title="Activity"
            actions={
              <ButtonGroup>
                <Button active>Day</Button>
                <Button>Week</Button>
                <Button>Month</Button>
              </ButtonGroup>
            }
            data={barChartData}
            type="bar"
            labels={contactSummaryLabels}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10}>
          <DataTable />
        </Col>     
      </Row>
    </Grid>
  );
};

export default AnalyticsContent;

const sparklineData = [3, 18, 10, 9, 27, 23, 7, 29, 20, 22, 17, 11, 6, 2, 28, 25, 12, 16, 30, 8, 4, 21, 13, 5, 15, 26, 24, 19, 14, 1]

const barChartData = [
  {
    name: 'Viewed',
    data: [
      11, 8, 9, 10, 3, 11, 11, 11, 12, 13, 2, 12, 5, 8, 22, 6, 8, 6, 4, 1, 8, 24, 29, 51, 40, 47,
      23, 26, 50, 26, 22, 27, 46, 47, 81, 46, 40
    ]
  },
  {
    name: 'Contacted',
    data: [
      7, 5, 4, 3, 3, 11, 4, 7, 5, 12, 12, 15, 13, 12, 6, 7, 7, 1, 5, 5, 2, 12, 4, 6, 18, 3, 5, 2,
      13, 15, 20, 47, 18, 15, 11, 10, 9
    ]
  },
  {
    name: 'Responded',
    data: [
      4, 9, 11, 7, 8, 3, 6, 5, 5, 4, 6, 4, 11, 10, 3, 6, 7, 5, 2, 8, 4, 9, 9, 2, 6, 7, 5, 1, 8, 3,
      12, 3, 4, 9, 7, 11, 10
    ]
  },
  {
    name: 'Scheduled',
    data: [
      4, 9, 11, 7, 8, 3, 6, 5, 5, 4, 6, 4, 11, 10, 3, 6, 7, 5, 2, 8, 4, 9, 9, 2, 6, 7, 5, 1, 8, 3,
      12, 3, 4, 9, 7, 11, 10
    ]
  }
];

const contactSummaryLabels = [
  '2022-01-20',
  '2022-01-21',
  '2022-01-22',
  '2022-01-23',
  '2022-01-24',
  '2022-01-25',
  '2022-01-26',
  '2022-01-27',
  '2022-01-28',
  '2022-01-29',
  '2022-01-30',
  '2022-02-01',
  '2022-02-02',
  '2022-02-03',
  '2022-02-04',
  '2022-02-05',
  '2022-02-06',
  '2022-02-07',
  '2022-02-08',
  '2022-02-09',
  '2022-02-10',
  '2022-02-11',
  '2022-02-12',
  '2022-02-13',
  '2022-02-14',
  '2022-02-15',
  '2022-02-16',
  '2022-02-17',
  '2022-02-18',
  '2022-02-19',
  '2022-02-20',
  '2022-02-21',
  '2022-02-22',
  '2022-02-23',
  '2022-02-24',
  '2022-02-25',
  '2022-02-26'
]