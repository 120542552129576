import React, { useEffect } from "react";
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { Panel, Breadcrumb, Stack } from 'rsuite';

import ProfileContent from './ProfileContent'
import sideMenuSlice from "store/slices/sidemenu";


const Profile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(sideMenuSlice.actions.setActiveItem('/profile'));
  })

  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <h3 className="title">Profile</h3>
      </Stack>

      <Breadcrumb>
        <Breadcrumb.Item href="#" onClick={() => {navigate('/')}}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
      </Breadcrumb>
    </>}>
    </Panel>

    <Panel className='body-panel'  shaded>
      <ProfileContent />
    </Panel>
</>)};

export default Profile;