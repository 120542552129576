import React, { useEffect, useState } from 'react';

import { postRequest } from "utils/axios";

import { Row, Col, Grid, Loader, Panel } from 'rsuite';
import BarChart from 'components/analytics/charts/BarChart';

const responseBreakdownOptions = {
    tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: true
    },
    y: {
      title: {
        formatter: function (seriesName: any) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }       
  }     
}

type Props = {
  timeUnit: any
  dateRange: any
  dateRangeChanged: boolean
  setDateRangeChanged: (hasChanged: boolean) => void
}

const AdminAnalyticsContent = ( { timeUnit, dateRange, dateRangeChanged, setDateRangeChanged } : Props) => {
  const [usCandidatesBarChart, setUsCandidatesBarChart] = useState<any>()
  const [caCandidatesBarChart, setCaCandidatesBarChart] = useState<any>()
  const [candidatesBarChart, setCandidatesBarChart] = useState<any>()
  const [candidateUrlLinksBarChart, setCandidateUrlLinksBarChart] = useState<any>()
  const [githubSnapshotsBarChart, setGithubSnapshotsBarChart] = useState<any>()
  const [githubCandidatesBarChart, setGithubCandidatesBarChart] = useState<any>()
  const [linkedinSnapshotsBarChart, setLinkedinSnapshotsBarChart] = useState<any>()
  const [linkedProfilesBarChart, setLinkedProfilesBarChart] = useState<any>()
  
  useEffect(() => {
    if (dateRange && timeUnit && (dateRangeChanged)) {
      const options = {
        "time_unit": timeUnit,
        "min_time": dateRange[0],
        "max_time": dateRange[1]
      }

      lookupBarChartData('candidates', candidatesBarChart, setCandidatesBarChart, options)
      lookupBarChartData('candidates/us', usCandidatesBarChart, setUsCandidatesBarChart, options)
      lookupBarChartData('candidates/ca', caCandidatesBarChart, setCaCandidatesBarChart, options)
      lookupBarChartData('candidate-url-links', candidateUrlLinksBarChart, setCandidateUrlLinksBarChart, options)
      lookupBarChartData('github-snapshots', githubSnapshotsBarChart, setGithubSnapshotsBarChart, options)
      lookupBarChartData('github-candidates', githubCandidatesBarChart, setGithubCandidatesBarChart, options)
      lookupBarChartData('linkedin-snapshots', linkedinSnapshotsBarChart, setLinkedinSnapshotsBarChart, options)
      lookupBarChartData('linkedin-profiles', linkedProfilesBarChart, setLinkedProfilesBarChart, options)

      if (dateRangeChanged) {
        setDateRangeChanged(false)
      }
    }
  })

  function lookupBarChartData(name: string, data: any, setter: ({}: any) => void, options: any) {
    if (!data || dateRangeChanged) {
      if (dateRangeChanged) {
        setter(null)
      }
      postRequest(`/system/analytics/${name}/barcharts/`, options).then((response: any) => {
        setter({
          data_sets: response.data.data_sets,
          labels: response.data.labels
        })
      })     
    }    
  }

  function renderBarChart(title: string, data: any) {
    if (!data) {
      return <Panel className="card" ><div className='text-center m-3'><Loader size="md" content="Loading..."/></div></Panel>
    } else {
      if (data.data_sets.length > 1) {
        return <BarChart
          title={title}
          data={data.data_sets}
          labels={data.labels}
        />
      } else {
        return <BarChart
          title={title}
          type='bar'
          data={data.data_sets}
          labels={data.labels}
      />        
      }
  }
}

  return (
    <Grid fluid style={{padding: "0px"}}>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('US Candidates', usCandidatesBarChart)}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('Canada Candidates', caCandidatesBarChart)}
        </Col>     
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('Candidates', candidatesBarChart)}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('Providers', candidateUrlLinksBarChart)}
        </Col>     
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('LinkedIn Profiles', linkedProfilesBarChart)}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('LinkedIn Snapshots', linkedinSnapshotsBarChart)}
        </Col>     
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('Github Candidates', githubCandidatesBarChart)}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {renderBarChart('Github Snapshots', githubSnapshotsBarChart)}
        </Col>     
      </Row>
    </Grid>
  );
};

export default AdminAnalyticsContent;