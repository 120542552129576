import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { RootState } from "store";
import { useSelector } from "react-redux";
import { IconButton, Button, Col, Loader, Panel, Grid, Row, Stack, useToaster, Dropdown } from 'rsuite';
import 'styles/rsuite.css';

import ThumbsUpIcon from '@rsuite/icons/legacy/ThumbsUp';
import ThumbsDownIcon from '@rsuite/icons/legacy/ThumbsDown';

import CandidateEmailEngagementHistory from 'components/email/CandidateEmailEngagementHistory';
import CandidateMeetingInfo from 'components/meeting/CandidateMeetingInfo';
import CandidateAccessTracker from "components/tracker/CandidateAccessTracker";
import ResolveJobCandidateModal from "components/candidate/ResolveJobCandidateModal";
import InitialOutreachEmailModal from "components/email/InitialOutreachEmailModal";
import { CandidateResponse } from 'utils/types';
import CandidateAvatar from 'components/candidate/CandidateAvatar'
import CandidateSocialMedia from "components/candidate/CandidateSocialMedia";
import CandidateJobHistory from 'components/candidate/history/CandidateJobHistory'
import CandidateSummary from 'components/candidate/CandidateSummary'
import { getCandidateSummary, getCandidateMostRecentDegree, getCandidateMostRecentTitle, getCandidateCurrentFocus, getCandidateCurrentLocation, getCandidateYearsOfWorkExperience, getCandidateCurrentCompany, getCandidateCurrentName, getCandidateCurrentCoreSkills } from 'utils/candidate_data_access';
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

type Props = {
  jobId?: string  
  searchParams?: string
  jobCandidate: any
  searchContext: string
  activeTab: string
  setActiveTab?: (activeTab: string) => void
  likeCandidate?: () => void
  hideCandidate?: () => void  
  contactCandidate?: () => void  
  resolveCandidate?: () => void
  scheduleCandidate?: () => void
  updateCandidateCard?: boolean
  setUpdateCandidateCard?: (update: boolean) => void
};

function SelectableScheduledCandidateCard({ jobId, searchParams, updateCandidateCard, setUpdateCandidateCard, jobCandidate, searchContext, activeTab, setActiveTab, likeCandidate, hideCandidate, contactCandidate, scheduleCandidate, resolveCandidate }: Props) {
  const navigate = useNavigate()
  const toaster = useToaster();
  const configuration = useSelector((state: RootState) => state.configuration);

  const [updateEngagementHistory, setUpdateEngagementHistory] = useState(true);
  
  const [lastEmailStatus, setLastEmailStatus] = useState<any>(null);
  const [detailShown, setDetailShown] = useState(false);
  const [updateAccessTracker, setUpdateAccessTracker] = useState(false);
  const [emailAttachment, setEmailAttachment] = useState<any>(null);

  
  const [isCandidateEmailModalOpen, setCandidateEmailModalOpen] = React.useState(false);  
  const handleOpenCandidateEmailModal = (emailAttachment: any) => {
    if (emailAttachment === 'personalized video' && !configuration.features.generated_video_message_support) {
      toaster.push( <RequestSuccessMessage message='Upgrade to a paid plan to enable personalized video generation.' toaster={toaster}/> )
      return
    }
    if (emailAttachment === 'video' && !configuration.features.uploaded_video_message_support) {
      toaster.push( <RequestSuccessMessage message='Upgrade to a paid plan to enable video message upload.' toaster={toaster}/> )
      return
    }
    setEmailAttachment(emailAttachment)
    setCandidateEmailModalOpen(true);  
  }
  const handleCloseCandidateEmailModal = () => {
    setCandidateEmailModalOpen(false);  
    if (setActiveTab && (activeTab === 'liked' || activeTab === 'new')) {
      setActiveTab('engaged')
    }
    setUpdateEngagementHistory(true)
    if (contactCandidate) {
      contactCandidate()
    }
  }

  const [isResolveCandidateModalOpen, setResolveCandidateModalOpen] = React.useState(false);  
  const handleOpenResolveCandidateModal = () => setResolveCandidateModalOpen(true);  
  const handleCloseResolveCandidateModal = () => {
    setResolveCandidateModalOpen(false);  
    if (resolveCandidate)
      resolveCandidate()
  }

  var candidate: CandidateResponse = jobCandidate.candidate

  useEffect(() => { 
    if (setUpdateCandidateCard && updateCandidateCard) {
      setUpdateEngagementHistory(true)
      setUpdateCandidateCard(false)
    }
  })

  if (!candidate) {
    return (<Panel className='body-panel'><div className='text-center m-3'><Loader size="md" content="Fetching candidate details..."/></div></Panel>)
  }  

  function handleDetailRequest(candidate_id: string) {
    var targetLink = '/candidates/' + candidate_id
    if (jobId) {
      targetLink = '/' + searchContext + '/' + jobId + targetLink
    }

    if (searchParams) {
      targetLink = targetLink + '?' + searchParams
    }
    navigate(targetLink)
  }  

  function renderHighlights(jobCandidate: any) {
    if (!jobCandidate.highlights) {
      return <></>
    }
    return (
      <span style={{fontSize: "10px"}}>
        {jobCandidate.highlights.map((highlight: string, index: number) => (
          <><em key={index}>{highlight}</em>&nbsp;</>
        ))}
      </span>
    );
  }

  return (
    <Panel shaded>
        <Row>
          <Col lg={6} xl={4}>
            <CandidateAvatar size="lg" onClick={() => handleDetailRequest(candidate.id)} candidate={candidate}/>
            <CandidateSocialMedia candidate={candidate} showText={false}/>
          </Col>

          <Col md={24} lg={18} xl={20}>
              <Row>
                <Col md={20}>
                  <Button style={{marginLeft: '-10px', fontSize: '18px', fontWeight: '500', color: 'black'}}
                        appearance="link" 
                        onClick={() => handleDetailRequest(candidate.id)}>
                        {getCandidateCurrentName(candidate)}
                  </Button>
                  {getCandidateCurrentCompany(candidate, '@ ', '')}
                </Col>
                <Col md={2}>
                  {(jobCandidate.state === 'new' || jobCandidate.state === 'hidden') && likeCandidate &&
                    <div title="Like Candidate"><IconButton appearance="primary" size="sm" circle onClick={likeCandidate} icon={<ThumbsUpIcon />}/></div>
                  }
                </Col>
                <Col md={2}>
                  {(jobCandidate.state === 'new' || jobCandidate.state === 'liked') && hideCandidate &&
                    <div title="Hide Candidate"><IconButton appearance="primary" size="sm" circle onClick={hideCandidate} icon={<ThumbsDownIcon />}/></div>
                  }        
                </Col>        
              </Row>

              <Grid fluid style={{fontSize: '13px'}}>
                <table className="candidate_tab">
                  <tr>
                    <td className="candidate_tab_field_title">Title:</td>
                    <td className="candidate_tab_field_value-value">{getCandidateMostRecentTitle(candidate)}</td>
                  </tr>
                  <tr>
                    <td className="candidate_tab_field_title">Location:</td>
                    <td className="candidate_tab_field_value">{getCandidateCurrentLocation(candidate)}</td>
                  </tr>
                  <tr>
                    <td className="candidate_tab_field_title">Degree:</td>
                    <td className="candidate_tab_field_value">{getCandidateMostRecentDegree(candidate)}</td>
                  </tr>
                  <tr>
                    <td className="candidate_tab_field_title">Specialty:</td>
                    <td className="candidate_tab_field_value">{getCandidateCurrentFocus(candidate)}</td>
                  </tr>
                </table>

                <Row style={{marginTop: "10px"}}>
                  <Col>
                    <CandidateSummary summary={getCandidateSummary(candidate)}/>
                  </Col>
                </Row>
              </Grid>
              <Grid fluid style={{fontSize: '15px', marginTop: "10px"}}>
                {(jobCandidate.state === 'new' || jobCandidate.state === 'liked') &&
                  <CandidateJobHistory candidate={candidate}/>
                }

                <Row style={{marginTop: "10px"}}>
                  <Col>
                    {renderHighlights(jobCandidate)}
                  </Col>
                </Row>
              </Grid>

            <Stack direction='row' alignItems='flex-start' justifyContent='flex-start' spacing={100}>
              {(jobCandidate.state === 'engaged' || jobCandidate.state === 'scheduled') &&
                <Stack.Item>
                  <CandidateEmailEngagementHistory 
                    updateHistory={updateEngagementHistory} 
                    updatedHistory={() => setUpdateEngagementHistory(false)} 
                    setLastEmailStatus={setLastEmailStatus} 
                    jobCandidate={jobCandidate} 
                    editDraft={() => handleOpenCandidateEmailModal(null)}/>
                </Stack.Item>
              }
              {jobCandidate.state === 'scheduled' &&
                <Stack.Item>
                  <h5>Meeting Info</h5>
                  <CandidateMeetingInfo jobCandidate={jobCandidate}/>
                </Stack.Item>}
              {jobCandidate.state === 'resolved' && 
                <Stack.Item>
                  <h6>Resolution: {jobCandidate.resolution_code}</h6>
                </Stack.Item>}
              {jobCandidate.state === 'resolved' &&                 
                <Stack.Item>
                  <h6>Comments:</h6>
                  {jobCandidate.resolution_comment}
                </Stack.Item>
              }
            </Stack>
          </Col>
        </Row>

        <Stack style={{marginTop: "10px"}} direction='row' alignItems='flex-start' justifyContent='space-between' spacing={10}>
          <Stack.Item>
            <CandidateAccessTracker 
              candidateId={jobCandidate.candidate.id} 
              update={updateAccessTracker} 
              setUpdate={setUpdateAccessTracker}
              pastAccessTrackerEvent={jobCandidate.candidate.access_tracker_event}/>
          </Stack.Item>
          <Stack.Item>
            <InitialOutreachEmailModal emailAttachment={emailAttachment} jobCandidate={jobCandidate} open={isCandidateEmailModalOpen} handleClose={handleCloseCandidateEmailModal}/>              
            <ResolveJobCandidateModal jobCandidate={jobCandidate} open={isResolveCandidateModalOpen} handleClose={handleCloseResolveCandidateModal}/>   


            <Stack direction='row' alignItems='flex-start' justifyContent='space-between' spacing={10}>
              <Stack.Item>
                {jobCandidate.state === 'engaged' &&
                    <Button appearance="primary" className="rounded-5" size="sm" onClick={scheduleCandidate}>Schedule</Button>
                }
              </Stack.Item>

              <Stack.Item>
                {(jobCandidate.state === 'engaged' || jobCandidate.state === 'scheduled') &&
                    <Button appearance="primary" className="rounded-5" size="sm" onClick={handleOpenResolveCandidateModal}>Resolve ...</Button>
                }
              </Stack.Item>
            
              {searchContext !== 'search' && (jobCandidate.state === 'new' || jobCandidate.state === 'liked' || !lastEmailStatus) &&
              <Stack.Item>
                 {(jobCandidate.state === 'new' || jobCandidate.state === 'liked') && 
                  <Dropdown placement="topEnd" onSelect={(emailAttachment: any) => handleOpenCandidateEmailModal(emailAttachment)} trigger="click" appearance="primary" className="rounded-5" size="sm"  title="Draft Email">
                    <Dropdown.Item eventKey="personalized video">Text + Video</Dropdown.Item>
                    { /* TODO: Coming soon <Dropdown.Item eventKey="video">Email + Generic Video</Dropdown.Item> */ }
                    <Dropdown.Item eventKey="none">Text Only</Dropdown.Item>
                  </Dropdown>
                  }
              </Stack.Item>
            }
            </Stack>
          </Stack.Item>
        </Stack>

    </Panel>
  );
}

export default SelectableScheduledCandidateCard;