import React, { useState, useRef, useEffect } from "react";

import { putRequest } from "utils/axios";

import { Modal, Button, Form, useToaster } from 'rsuite';
import Textarea from 'components/elements/Textarea';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

import { CandidateCommentResponse } from 'utils/types'

type props = {
  candidateId: string
  candidateComment: any
  setCandidateComment: (candidateComment: CandidateCommentResponse) => void
  open: boolean
  handleClose: (updatedCandidateComment: CandidateCommentResponse | null) => void
}

const CandidateCommentModal = ({ candidateId, candidateComment, setCandidateComment, open, handleClose }: props) => {

  const formRef: any = useRef()
  const toaster = useToaster();

  const [formError, setFormError] = useState({});  
  const [formValue, setFormValue] = useState<any>({ text: null })

  useEffect(() => {
    if (!formValue.text && candidateComment?.text) {
      updateFormValue()
    }
  })

  function updateFormValue() {
    setFormValue({ ...formValue,
      text: candidateComment.text
    })
  }

  const handleSubmit = (event: any) => {
      const candidateCommentRequest: any = {
        'text': formValue.text
      }
      putRequest(`/comments/candidates/${candidateId}/`, candidateCommentRequest).then(
        (response) => {
          setCandidateComment({ ...candidateComment,
            text: formValue.text
          })
          toaster.push( <RequestSuccessMessage message='Comment successfully updated' toaster={toaster}/> )
          handleClose(response.data)
      }).catch(
        (error) => { 
          toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      })
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      text: record.text
    })
  }  

  return (
    <>
      <Modal
        size='sm'
        onEnter={updateFormValue}
        open={open}
        onClose={() => handleClose(null)}
      >
        <Modal.Header>
          <Modal.Title>Add or Update Candidate Comments</Modal.Title>
        </Modal.Header>
        <Form 
            ref={formRef}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onCheck={setFormError}
            formValue={formValue}
            layout="vertical"
            fluid
            >
          <Modal.Body>
            <Form.Group controlId="text">
              <Form.Control name="text" rows={10} accepter={Textarea}/>
              <Form.HelpText>
                This is a private comment only you can see.
              </Form.HelpText>
            </Form.Group>  
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" type='submit'>Update</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CandidateCommentModal;
