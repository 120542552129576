import React, { useState } from 'react';

import { postRequest } from "utils/axios";

import { Schema, Stack, IconButton, Form, useToaster } from 'rsuite';

import ReturnIcon from '@rsuite/icons/SortUp';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'

type Props = {
    chatUrl: string
    triggerHistoryUpdate: () => void
    connectToChatChannel: () => void
    isChatMessageEnabled: boolean
    setIsChatMessageEnabled: (isChatMessageEnabled: boolean) => void
    postProcess?: (response: any) => void
}

const ChatInput = ({ chatUrl, triggerHistoryUpdate, connectToChatChannel, isChatMessageEnabled, setIsChatMessageEnabled, postProcess }: Props) => {
    const toaster = useToaster();

    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState<any>({
        chatInputMessage: ''
    })

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        chatInputMessage: StringType().isRequired("Please enter your request or instruction"),
    })

    function resetChatInput() {
        setFormValue({
            chatInputMessage: '',
        })
    }

    function handleChatMessage() {
        connectToChatChannel()
        setIsChatMessageEnabled(false)
        const chatMessage = {
            message: formValue.chatInputMessage,
            meta: {
                time: new Date().toString(),
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                ip: null,
                lan: navigator.language,
            }
        }
        postRequest(chatUrl, chatMessage).then(
            (response) => {
                if (response.status === 203) {
                    toaster.push(<RequestErrorMessage error="Unable to contact Tally. Please try again later. If this persists, please contact customer support under Account > Support." toaster={toaster} />)
                    triggerHistoryUpdate()
                } else {
                    triggerHistoryUpdate()
                    resetChatInput()
                    if (postProcess) {
                        postProcess(response)
                    }
                }
            }).catch(
                (error) => {
                    toaster.push(<RequestErrorMessage error={error} toaster={toaster} />)
                }).finally(() => {
                    setTimeout(() => setIsChatMessageEnabled(true), 3);
                })
    }

    return (
        <Form
            onSubmit={handleChatMessage}
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            model={model}
            fluid>
            <Stack spacing={10} direction='row' justifyContent='space-between'>
                <Stack.Item grow={1} alignSelf='stretch'>
                    <Form.Control
                        name="chatInputMessage"
                        placeholder="Please enter your request or instruction"
                        errorPlacement="topStart"
                        maxLength={5000}
                        searchable={false}
                        style={{ width: "100%" }} />
                </Stack.Item>
                <Stack.Item>
                    <IconButton loading={!isChatMessageEnabled} appearance="primary" type="submit" icon={<ReturnIcon />} />
                </Stack.Item>
            </Stack>
        </Form>)
};

export default ChatInput;