import React, { useEffect, useState, useRef } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { putRequest } from "utils/axios";
import { Nav, useToaster, Drawer, Loader, Form, Toggle, Stack, Grid } from 'rsuite';

import { CandidateDripCampaignTemplateListResponse } from 'utils/types'

import ScheduleTab from 'components/campaign/ScheduleTab'
import AITemplateBuilderTab from 'components/campaign/AITemplateBuilderTab'

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'
import { JobResponse } from 'utils/types'

type Props = {
  open: boolean
  onClose: () => void
  job: JobResponse
};


const DripCampaignDrawerView = ({ open, onClose, job }: Props) => {
  const { data: campaignTemplates, trigger } = useSWRMutation<CandidateDripCampaignTemplateListResponse>(`/jobs/${job.id}/campaigns/templates/`, fetcher)

  const toaster = useToaster();

  const [initialized, setInitialized] = useState(false);

  const [candidateSample, setCandidateSample] = useState<any>();
  
  const [activeTab, setActiveTab] = useState<string>('schedule');
  const [activeTabChanged, setActiveTabChanged] = useState(false);
  const [expertMode, setExpertMode] = useState(false);

  const [campaignStepTemplates, setCampaignStepTemplates] = useState<any>([])
  const [campaignTemplate, setCampaignTemplate] = useState<any>()

  useEffect(() => {
    if (open) {
      if (!campaignTemplates) {
        trigger()
      }
      if (campaignTemplates && campaignTemplates.results.length > 0 && !initialized) {
        setCampaignTemplate(campaignTemplates.results[0])

        const stepTemplates = campaignTemplates.results[0].candidate_drip_campaign_step_templates
        setCampaignStepTemplates(stepTemplates)
        setInitialized(true)
      }
    }
  });    

  function handleExpertModeChange(expertMode: any) {
    if (expertMode)
      setActiveTab('ai_trainer')
    else
      setActiveTab('schedule')
    setExpertMode(expertMode)
  }

  function handleClose() {
    trigger()
    setInitialized(false)
    setActiveTab('schedule')
    onClose()
  }
  
  if (!campaignTemplates) {
    return (
      <Drawer backdrop={true} size="md" placement="right" open={open} onClose={onClose}>
      <Drawer.Header>
        <Drawer.Title><h3>Drip Campaign Details</h3></Drawer.Title>
      </Drawer.Header>

      <Drawer.Body>
        <div className='text-center m-3'>
          <Loader size="md" content="Fetching campaign details..."/>
        </div>
      </Drawer.Body>
    </Drawer>        
    );
  }

  const handleStepAdd = () => {
    const campaignStepTemplatesCopy: any = [...campaignStepTemplates]
    const lastStepIndex = campaignStepTemplatesCopy.length - 1
    const endCampaignStep = campaignStepTemplatesCopy[lastStepIndex]
    campaignStepTemplatesCopy.splice(lastStepIndex, lastStepIndex)
    const newCampaignStep = {
      "step_number": 0,
      "trigger_criteria": {
        "time_after_previous_email_opened": {
            "unit": "minutes",
            "value": 1
        },
        "time_after_previous_email_delivered": {
            "unit": "minutes",
            "value": 5
        },
      },
      "action": "send_email"
    }

    campaignStepTemplatesCopy.push(newCampaignStep)
    campaignStepTemplatesCopy.push(endCampaignStep)

    campaignStepTemplatesCopy.map((stepTemplate: any, index: number) => {
      stepTemplate.step_number = index
    })    

    setCampaignStepTemplates(campaignStepTemplatesCopy)
  }   

  function updateCampaign(generateSampleEmail: (step_number: any) => void, step_number: any) {
    const campaignTemplateRequest: any = {...campaignTemplate}
    campaignTemplateRequest.candidate_drip_campaign_step_templates = campaignStepTemplates
    putRequest(`/jobs/${job.id}/campaigns/templates/${campaignTemplateRequest.id}/`, campaignTemplateRequest).then(
      (response) => {
        trigger()
        if (step_number !== null && step_number >= 0) {
          generateSampleEmail(step_number)
        } else {
          toaster.push( <RequestSuccessMessage message='Drip campaign successfully updated' toaster={toaster}/> )
        }
    }).catch(
      (error) => { 
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
        generateSampleEmail(null)
    })

  }

  const handleSubmit = (formBoolean: any, event: any) => {
    updateCampaign((step_number: any) => {}, null)
  }  

  function handleTabChange(value: any) {
    setActiveTab(value)
    setActiveTabChanged(true)
  }

  function renderTab() {
    switch(activeTab) {
      case 'ai_trainer': return (
        <AITemplateBuilderTab jobId={job.id} activeTabChanged={activeTabChanged} setActiveTabChanged={setActiveTabChanged}/>
      ); break;
      default: return (
        <ScheduleTab handleSubmit={handleSubmit} handleStepAdd={handleStepAdd} activeTabChanged={activeTabChanged} setActiveTabChanged={setActiveTabChanged} campaignStepTemplates={campaignStepTemplates} setCampaignStepTemplates={setCampaignStepTemplates}/>
      )
    }
  }

  return (
    <Drawer backdrop={false} size="full" placement="right" open={open} onClose={handleClose} >
        <Drawer.Header>
          <Drawer.Title><h3>Drip Campaign Details {job ? '- ' + job.name : ''} </h3></Drawer.Title>
          <Stack spacing={10}>
            <Stack.Item>
              AI Template Builder: <Toggle checked={expertMode} onChange={(value: any) => handleExpertModeChange(!expertMode)}/>
            </Stack.Item>
          </Stack>
        </Drawer.Header>

        <Drawer.Body id="emailDrawer">
          <Nav appearance="tabs" activeKey={activeTab} onSelect={handleTabChange}>
            <Nav.Item eventKey="schedule">Schedule</Nav.Item>
            {expertMode && (
                <Nav.Item eventKey="ai_trainer">Template</Nav.Item>
            )}
          </Nav>
          {renderTab()}
        </Drawer.Body>
      </Drawer>
  );
};

export default DripCampaignDrawerView;
