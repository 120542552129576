import React, { useState, useEffect } from "react";

import { AccessTrackerEvent } from 'utils/types'
import { trackCandidate } from 'utils/utils'

type props = {
  candidateId: string
  update: boolean
  setUpdate: (updated: boolean) => void
  pastAccessTrackerEvent: AccessTrackerEvent
}

const CandidateAccessTracker = ({ pastAccessTrackerEvent, candidateId, update, setUpdate }: props) => {
  const [accessTrackerEvent, setAccessTrackerEvent] = useState<AccessTrackerEvent>()

  useEffect(() => {
    if (candidateId && update) {
      setUpdate(false)
      const patchPromise = trackCandidate(candidateId) 
      patchPromise.then((response) => {
        if (response.status < 300) {
          setAccessTrackerEvent(response.data)
        }
      })
    } else if (!accessTrackerEvent && pastAccessTrackerEvent) {
      setAccessTrackerEvent(pastAccessTrackerEvent)
    }
  })

  if (accessTrackerEvent) {
    if (accessTrackerEvent.previous_access_time) {
      return <div style={{fontSize: '12px'}}>Last viewed: {new Date(accessTrackerEvent.previous_access_time).toLocaleDateString()}<br></br>Viewed {accessTrackerEvent.access_count} times since {new Date(accessTrackerEvent.created).toLocaleDateString()}</div>
    } else {
      return <div style={{fontSize: '12px'}}>Last viewed: {new Date(accessTrackerEvent.created).toLocaleDateString()}<br></br>Viewed {accessTrackerEvent.access_count} times since {new Date(accessTrackerEvent.created).toLocaleDateString()}</div>
    }
  } else {
    return <></>
  }
}

export default CandidateAccessTracker;