import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

const maxStartDate = new Date()
maxStartDate.setFullYear(2023)

const maxEndDate = new Date()

function setStartTime(date: Date) {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

function setEndTime(date: Date) {
  date.setHours(11)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(999)
  return date
}

const predefinedRanges: any = [
  {
    label: 'Today',
    value: [setStartTime(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [setStartTime(addDays(new Date(), -1)), setEndTime(addDays(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [setStartTime(subDays(new Date(), 6)), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [setStartTime((subDays(new Date(), 29))), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [setStartTime((startOfMonth(new Date()))), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [setStartTime((startOfMonth(addMonths(new Date(), -1)))), setEndTime(endOfMonth(addMonths(new Date(), -1)))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [setStartTime((new Date(new Date().getFullYear(), 0, 1))), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [setStartTime((new Date(new Date().getFullYear() - 1, 0, 1))), setEndTime(new Date(new Date().getFullYear(), 0, 0))],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [maxStartDate, maxEndDate],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];

interface Props {
    handleDateChange: (newDateRange: any) => void
    dateRange: any
}

const AnalyticsDateRangePicker = ({ handleDateChange, dateRange }: Props) => (
    <DateRangePicker 
    format="MM/dd/yyyy" 
    preventOverflow={true} 
    showMeridian
    onChange={handleDateChange} 
    defaultValue={dateRange}
    ranges={predefinedRanges}
    placeholder="Select range"
    size="md"
    />
  );

export default AnalyticsDateRangePicker;


