import React, { useState, useRef } from "react";

import { postRequest } from "utils/axios";

import { Modal, Button, Form, useToaster, Schema, SelectPicker } from 'rsuite';
import Textarea from 'components/elements/Textarea';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestConflictMessage from 'components/toasts/RequestConflictMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

import { JobCandidateResponse } from 'utils/types'
import { trackCandidate } from 'utils/utils'

type props = {
  jobCandidate: JobCandidateResponse,
  open: boolean
  handleClose: () => void
}

const resolutionOptions = [
  { label: 'Hired', value: 'hired' }, 
  { label: 'Not Interested', value: 'not-interested'},
  { label: 'Not Qualified', value: 'not-qualified'},
  { label: 'Unresponsive', value: 'unresponsive'},
  { label: 'Other', value: 'other'}
]

const CandidateCommentModal = ({ jobCandidate, open, handleClose }: props) => {

  const formRef: any = useRef()
  const toaster = useToaster();

  const [formError, setFormError] = useState({});  
  const [formValue, setFormValue] = useState({
    resolution_code: null,
    resolution_comment: null
  })

  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    resolution_code: StringType().isRequired("You should specify a reason why you want to close out this candidate."),
    resolution_comment: StringType()
  })  

  const handleSubmit = (event: any) => {
    const jobCandidateUpdateRequest = {
      "candidate_id": jobCandidate.candidate.id,
      "state": 'resolved',
      "resolution_code": formValue.resolution_code,
      "resolution_comment": formValue.resolution_comment
    };

    const responsePromise = postRequest(`/jobs/${jobCandidate.job_id}/candidates/`, jobCandidateUpdateRequest)    
    responsePromise.then(
      (response) => {
        toaster.push( <RequestSuccessMessage message="Resolved candidate" toaster={toaster}/> )
        handleClose()
    }).catch((error) => {
      if (error.status === 409) {
        toaster.push( <RequestConflictMessage message={error.response.data.message} toaster={toaster}/> )  
      } else {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }
    })
  }

  const handleChange = (record: any) => {
    setFormValue({ ...formValue,
      resolution_comment: record.resolution_comment,
      resolution_code: record.resolution_code    
    })    
  }  

  const onEnter = () => {
    trackCandidate(jobCandidate.candidate.id)
  }

  return (
    <>
      <Modal
        size='sm'
        open={open}
        onEntered={onEnter}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Resolve Candidate</Modal.Title>
        </Modal.Header>
        <Form 
            ref={formRef}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onCheck={setFormError}
            formValue={formValue}
            layout="vertical"
            fluid
            >
          <Modal.Body>
              <Form.Group controlId="resolution_code">
                  <Form.ControlLabel>Resolution</Form.ControlLabel>
                  <Form.Control style={{ width: '100%' }}
                    name="resolution_code" 
                    accepter={SelectPicker} 
                    data={resolutionOptions} 
                    searchable={false}
                    placeholder="Choose a resolution ..."/>
                  <Form.HelpText>
                  Choose a category that best describes the reason you are resolving this candidate.
                  </Form.HelpText>
                </Form.Group>            
              <Form.Group controlId="resolution_comment">
                <Form.Control name="resolution_comment" rows={10} accepter={Textarea}/>
                <Form.HelpText>
                  Leave a comment that helps with future reference.
                </Form.HelpText>
            </Form.Group>  
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" type='submit'>Resolve</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CandidateCommentModal;
