import React, { useState } from "react";

import { Grid, Row, Col, Panel, ButtonGroup, Button, useToaster } from 'rsuite';
import CheckIcon from '@rsuite/icons/legacy/CheckCircle';
import CrossIcon from '@rsuite/icons/legacy/CloseCircle';

import { postRequest } from "utils/axios";
import RequestErrorMessage from 'components/toasts/RequestErrorMessage'

type props = {
    isSignedIn: boolean
  }

const SubscriptionOptions = ({ isSignedIn }: props) => {
  const toaster = useToaster();
  const [billingCycle, setBillingCycle] = useState('yearly');

  const handleStandardPlanCheckout = () => {
    postRequest("integrations/stripe/checkouts/", { 
        plan: "standard",
        billing_cycle: billingCycle
    }).then((res) => {
        window.location.href = res.data.checkout_url
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      })
  }

  const handleProfessionalPlanCheckout = () => {
    postRequest("integrations/stripe/checkouts/", { 
        plan: "professional",
        billing_cycle: billingCycle
    }).then((res) => {
        window.location.href = res.data.checkout_url
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      })
  }
 // <Image src={professionalLogo} loading="lazy" style={{ width: "50px" }}/>
  return (
    <Grid style={{fontFamily: "Poppins, sansSerif", width: "100%"}} className="center-container">
      <Row>
        <Col>
          <Panel style={{paddingLeft: "20px", paddingRight: "20px", width: "800px"}}>
            <div style={{ textAlign: 'center' }}>
            { /* <div style={{ backgroundColor: '#ffeb3b', color: '#333', borderRadius: '3px', padding: '3px 10px', fontSize: '12px', marginBottom: '10px', display: 'inline-block' }}>TEST MODE</div> */ }
              <p>
                <ButtonGroup>
                  <Button style={{height: "40px", alignContent: "center", fontSize: "16px"}} appearance={billingCycle === 'monthly' ? "primary" : "default"} onClick={() => setBillingCycle('monthly')}>Monthly</Button>
                  <Button style={{height: "40px", alignContent: "center", fontSize: "16px"}} appearance={billingCycle === 'yearly' ? "primary" : "default"} onClick={() => setBillingCycle('yearly')}>Yearly</Button>
                </ButtonGroup>
              </p>
            </div>
          </Panel>
        </Col>
      </Row>
      <Row>
        {!isSignedIn &&
        <Col className="product-pricing-border">
          <Panel style={{paddingLeft: "20px", paddingRight: "20px", width: "400px"}}>
            <div className="ribbon ribbon-top-right"><span>Free Forever</span></div>
            <div style={{ textAlign: 'left' }}>
              <div style={{height: "110px",  fontSize: '15px', fontWeight: "300"}}>
                <h3>Free</h3>
                <p>For individual recruiters to get started.</p>
              </div>
              
              <div style={{height: "80px"}}>
                  <p style={{ textAlign: 'center', fontSize: '40px', fontWeight: "800", color: '#007BFF' }}>
                    $0
                  </p>
              </div>
              <Button target="_top" href='https://app.talentwell.ai/signup' appearance="primary" block style={{height: "45px", alignContent: "center", fontSize: "18px"}}>Get Started</Button>                        
              <div style={{ padding: 0, marginTop: '20px', textAlign: 'left', fontWeight: 'lighter', color: '#777' }}>
                  <p style={{fontWeight: "800"}}></p>
                  <ul style={{ listStyle: 'none' }}>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 1 user</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 1 job per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 30 outreaches per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 3 videos</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI assistant Tally</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI generated personalized videos</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI generated personalized emails</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Automated drip campaigns</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Candidate import</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Scheduling integration</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Limited customer support</li>
                      <li style={{ margin: '10px 0' }}>&nbsp;</li>
                  </ul>
              </div>
            </div>
          </Panel>
        </Col> 
        } 
        <Col className="product-pricing-border">
          <Panel style={{paddingLeft: "20px", paddingRight: "20px", width: "400px"}}>
            {billingCycle === 'yearly' && <div className="ribbon ribbon-top-right"><span>$360 in Savings</span></div>}
            <div style={{ textAlign: 'left' }}>
              <div style={{height: "110px",  fontSize: '15px', fontWeight: "300"}}>
                <h3>Standard</h3>
                <p>For individual recruiters with up to 4 positions to fill per month.</p>
              </div>
              <div style={{height: "80px"}}>
                  <p style={{ textAlign: 'center', fontSize: '40px', fontWeight: "800", color: '#333' }}>
                  {billingCycle === 'monthly' ? <span style={{ color: "#007BFF" }}>$79</span> : <><del>$79</del> <span style={{ color: "#007BFF" }}>$49</span></>} <sup style={{ verticalAlign: "super", fontSize: "16px", fontWeight: "300"}}>per month</sup>
                  </p>
              </div>
              {isSignedIn ?
                  <Button onClick={handleStandardPlanCheckout} appearance="primary" block style={{height: "45px", alignContent: "center", fontSize: "18px"}}>Checkout</Button>
                  :
                  <Button target="_top" href='https://app.talentwell.ai/signup' appearance="primary" block style={{height: "45px", alignContent: "center", fontSize: "18px"}}>Get Started</Button>                        
              }
              <div style={{ padding: 0, marginTop: '20px', textAlign: 'left', fontWeight: 'lighter', color: '#777' }}>
              <p style={{fontWeight: "800"}}></p>
                  <ul style={{ listStyle: 'none' }}>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 1 user</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 4 jobs per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 120 outreaches per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 25 videos per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI assistant Tally</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> <span className="jiggle" style={{ color: "green", fontWeight: "900" }}>Custom Avatar</span>&nbsp;<span style={{ color: "green", verticalAlign: "super", fontSize: "10px"}}>Now Available!</span></li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI generated personalized videos</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI generated personalized emails</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Automated drip campaigns</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Candidate import</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Scheduling integration</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Customer support</li>
                  </ul>
              </div>
            </div>
          </Panel>
        </Col>
        <Col className="product-pricing-border">
          <Panel style={{paddingLeft: "20px", paddingRight: "20px", width: "400px"}}>
            {billingCycle === 'yearly' ?
             <div className="ribbon ribbon-top-right"><span>$600 in Savings<br/>Most Popular</span></div>
             :
             <div className="ribbon ribbon-top-right"><span>Most Popular</span></div>
            }
            <div style={{ textAlign: 'left' }}>
              <div style={{height: "110px",  fontSize: '15px', fontWeight: "300"}}>
                <h3>Professional</h3>
                <p style={{paddingRight: "10px"}}>For individual recruiters with up to 12 positions to fill per month.</p>
              </div>
              <div style={{height: "80px"}}>
                  <p style={{ textAlign: 'center', fontSize: '40px', fontWeight: "800", color: '#333' }}>
                  {billingCycle === 'monthly' ? <span style={{ color: "#007BFF" }}>$199</span> : <><del>$199</del> <span style={{ color: "#007BFF" }}>$149</span></>} <sup style={{ verticalAlign: "super", fontSize: "16px", fontWeight: "300"}}>per month</sup>
                  </p>
              </div>              
              {isSignedIn ?
                  <Button onClick={handleProfessionalPlanCheckout} appearance="primary" block style={{height: "45px", alignContent: "center", fontSize: "18px"}}>Checkout</Button>
                  :
                  <Button target="_top" href='https://app.talentwell.ai/signup' appearance="primary" block style={{height: "45px", alignContent: "center", fontSize: "18px"}}>Get Started</Button>                        
              }
              <div style={{ padding: 0, marginTop: '20px', textAlign: 'left', fontWeight: 'lighter', color: '#777' }}>
                  <p style={{fontWeight: "800"}}></p>
                  <ul style={{ listStyle: 'none' }}>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 1 user</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 12 jobs per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 360 outreaches per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> 75 videos per month</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI assistant Tally</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> <span className="jiggle" style={{ color: "green", fontWeight: "900" }}>Custom Avatar</span>&nbsp;<span style={{ color: "green", verticalAlign: "super", fontSize: "10px"}}>Now Available!</span></li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI generated personalized videos</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> AI generated personalized emails</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Automated drip campaigns</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Candidate import</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Scheduling integration</li>
                      <li style={{ margin: '10px 0' }}><CheckIcon /> Customer support</li>
                  </ul>
              </div>
            </div>
          </Panel>
        </Col>
        {!isSignedIn && 
        <Col className="product-pricing-border">
          <Panel style={{paddingLeft: "20px", paddingRight: "20px", width: "400px"}}>
          <div className="ribbon ribbon-top-right"><span>Top Solution<br/>for Teams</span></div>
          <div style={{ textAlign: 'left' }}>
              <div style={{height: "110px",  fontSize: '15px', fontWeight: "300"}}>
                <h3>Enterprise</h3>
                <p style={{paddingRight: "15px"}}>For recruiting teams that need a centralized data platform and have many positions to fill per month.</p>
              </div>
              <div style={{height: "80px"}}>
                  <p style={{ textAlign: 'center', fontSize: '40px', fontWeight: "800", color: '#007BFF' }}>
                   Custom
                  </p>
              </div>
              <Button target="_top" href='https://talentwell.ai/contact' appearance="primary" block style={{height: "45px", alignContent: "center", fontSize: "18px"}}>Contact Us</Button>
              <div style={{ padding: 0, marginTop: '20px', textAlign: 'left', fontWeight: 'lighter', color: '#777' }}>
                  <p style={{fontWeight: "800"}}></p>
                  <ul style={{ listStyle: 'none' }}>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> Customized plan</li>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> Team collaboration</li>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> SAML/SSO</li>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> DNS email integration</li>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> ATS Integration</li>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> Performance Reports</li>
                    <li style={{ margin: '10px 0' }}><CheckIcon /> Dedicated Account Manager</li>
                    <li style={{ margin: '10px 0' }}>&nbsp;</li>
                    <li style={{ margin: '10px 0' }}>&nbsp;</li>
                    <li style={{ margin: '10px 0' }}>&nbsp;</li>
                    <li style={{ margin: '10px 0' }}>&nbsp;</li>
                    <li style={{ margin: '10px 0' }}>&nbsp;</li>
                  </ul>
              </div>
            </div>
          </Panel>
        </Col>
      }
      </Row>
    </Grid>
  );
}

export default SubscriptionOptions;
