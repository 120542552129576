import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate } from "react-router";
import { Sidenav, Nav } from 'rsuite';
import AssistantIcon from '@rsuite/icons/legacy/Search';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import AdminIcon from '@rsuite/icons/Admin';
import AnalyticsIcon from '@rsuite/icons/legacy/LineChart';
import JobFilterIcon from '@rsuite/icons/PeoplesCostomize';
import PeopleFilterIcon from '@rsuite/icons/PeopleFliter';
import CalendarIcon from '@rsuite/icons/Calendar';
import sideMenuSlice from "store/slices/sidemenu";

type Props = {
  menuExpanded: boolean;
  setMenuExpand: (expanded: boolean) => void;
};

const SideNavBar = ({ menuExpanded, setMenuExpand }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const sideMenu = useSelector((state: RootState) => state.sideMenu);

  const handleNavigation = (event: any) => {
    const destination = event.currentTarget.getAttribute('target');
    dispatch(sideMenuSlice.actions.setActiveItem(destination));
    navigate(destination);
  };

  return (
    <Sidenav expanded={menuExpanded} className="sidenav-container">
      <Sidenav.Toggle onToggle={setMenuExpand} />
      <Sidenav.Body style={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto', paddingBottom: '50px' }}>
        <Nav>
          <Nav.Item eventKey="assistant" target='/' active={sideMenu.activeItem === '/'} icon={<AssistantIcon />} onClick={handleNavigation}>
            Assistant
          </Nav.Item>
          <Nav.Item eventKey="jobs" target='/jobs' active={sideMenu.activeItem === '/jobs'} icon={<JobFilterIcon />} onClick={handleNavigation}>
            Jobs
          </Nav.Item>
          <Nav.Item eventKey="candidates" target='/candidates' active={sideMenu.activeItem === '/candidates'} icon={<PeopleFilterIcon />} onClick={handleNavigation}>
            My Candidates
          </Nav.Item>
          <Nav.Item eventKey="calendar" target='/calendar' active={sideMenu.activeItem === '/calendar'} icon={<CalendarIcon />} onClick={handleNavigation}>
            Calendar
          </Nav.Item>
          <Nav.Item eventKey="analytics" target='/analytics' active={sideMenu.activeItem === '/analytics'} icon={<AnalyticsIcon />} onClick={handleNavigation}>
            Analytics
          </Nav.Item>
          <Nav.Menu eventKey="account" title="Account" icon={<GearCircleIcon />}>
            <Nav.Item eventKey="profile" target='/profile' active={sideMenu.activeItem === '/profile'} onClick={handleNavigation}>
              Profile
            </Nav.Item>
            <Nav.Item eventKey="business" target='/business' active={sideMenu.activeItem === '/business'} onClick={handleNavigation}>
              Business
            </Nav.Item>
            <Nav.Item eventKey="businessOnBehalf" target='/companies' active={sideMenu.activeItem === '/companies'} onClick={handleNavigation}>
              Companies
            </Nav.Item>
            <Nav.Item eventKey="billing" target='/billing' active={sideMenu.activeItem === '/billing'} onClick={handleNavigation}>
              Billing
            </Nav.Item>
            <Nav.Item eventKey="support" target='/support' active={sideMenu.activeItem === '/support'} onClick={handleNavigation}>
              Support
            </Nav.Item>
            <Nav.Item eventKey="help" target='/help' active={sideMenu.activeItem === '/help'} onClick={handleNavigation}>
              Help
            </Nav.Item>
          </Nav.Menu>
          {user?.is_superuser && (
            <Nav.Menu eventKey="admin" title="Admin" icon={<AdminIcon />}>
              <Nav.Item eventKey="admin_accounts" target='/admin/accounts' active={sideMenu.activeItem === '/admin/accounts'} onClick={handleNavigation}>
                Accounts
              </Nav.Item>
              <Nav.Item eventKey="admin_analytics" target='/admin/analytics' active={sideMenu.activeItem === '/admin/analytics'} onClick={handleNavigation}>
                Analytics
              </Nav.Item>
              <Nav.Item eventKey="admin_system" target='/admin/system' active={sideMenu.activeItem === '/admin/system'} onClick={handleNavigation}>
                System
              </Nav.Item>
              <Nav.Item eventKey="admin_deployments" target='/admin/deployments' active={sideMenu.activeItem === '/admin/deployments'} onClick={handleNavigation}>
                Deployment
              </Nav.Item>
            </Nav.Menu>
          )}
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  );
};

export default SideNavBar;
