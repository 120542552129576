import React, { useState } from "react";

import { Panel, Breadcrumb, Stack } from 'rsuite';

import AssistantContent from './AssistantContent'

const Assistant = () => {
  return (<>
    <Panel className='header-panel' shaded
    header={<>
      <Stack direction='row' justifyContent="space-between">
        <h3 className="title">Assistant</h3>
      </Stack>

      <Breadcrumb>
        <Breadcrumb.Item active>Home</Breadcrumb.Item>
      </Breadcrumb>
    </>}>
    </Panel>

    <AssistantContent /> 
</>)};

export default Assistant;