
import React, { useState, useEffect } from "react";
import { List } from 'rsuite';

import { getRequest } from "utils/axios";

const AdminSystemContent = () => {
  const [backendBuildVersion, setBackendBuildVersion] = useState('Unspecified')

  function getFrontendBuildVersion() {
    const buildVersion = document.getElementById('right-one-app-build-version') as HTMLMetaElement
    const buildTime = document.getElementById('right-one-app-build-time') as HTMLMetaElement
    return [buildVersion.content, 'build: ' + new Date(buildTime.content).toLocaleString()].join(', ')
  }

  useEffect(() => {
    const configurationPromise = getRequest('/system/info/')
    configurationPromise.then((response: any) => {
        setBackendBuildVersion([response.data.version, 'build: ' + new Date(response.data.build_time).toLocaleString()].join(', '))
    })   
  })

  return (<>
      <h5>Release Updates</h5>
      <p>Last frontend release: {getFrontendBuildVersion()}</p>
      <p>Last backend release: {backendBuildVersion}</p>
      <p>Upcoming work: <a href='https://trello.com/b/vipF0C1a/rightone-saas-web-application' target='_blank'>Trello Board</a></p>
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>06/10/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Redesigned candidate profile in search results.</List.Item>
            <List.Item>Includes summary but with expansion option</List.Item>
            <List.Item>Important keywords that matched are highlighted in yellow</List.Item>
            <List.Item>Major matching improvements with boosting and phrase searches</List.Item>
            <List.Item>Complete rewrite of matching prompt instructions for improved expansion</List.Item>
            <List.Item>Support for exclusion in search</List.Item>
            <List.Item>Experimental generative AI boosting adjustments</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>05/31/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Moved from rightone.ai to talentwell.ai</List.Item>
            <List.Item>Major shift to move towards natural language interaction with Vynari assistant</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>04/06/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Improvements to satisfy follow up email writing.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>04/05/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Major overhaul to support building email templates with AI assistance.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>04/02/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Added safeguards and made email generation from new guidelines more robust.</List.Item>
            <List.Item>Upgraded OpenAI API.</List.Item>
            <List.Item>Various minor fixes.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>04/01/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Major rework of email template guidelines to support building incremental instructions.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>03/19/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Moved from rightone.io to rightone.ai</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>02/12/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Taking out sample styles.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>02/11/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Adapted to new OpenAI model that changed requirements when calling functions and expecting JSON responses.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>02/10/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Phase 1 to support candidate replies. If configured in the business section, we now use the group reply to email.</List.Item>
            <List.Item>Various smaller fixes to address continuouss candidate generation.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>02/09/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Reworked job description prompt to only use a single mandatory field of a programming language.</List.Item>
            <List.Item>Reworked email generation prompts to be more responsive towards style and custom guidelines. 
              Split email generation into key paragraph parts.</List.Item>
            <List.Item>Upgraded to latest OpenAI GPT3 model 1125 and set temperature back to 1 from experiemental 0.3.</List.Item>
            <List.Item>Removed likely to move UI exposure.</List.Item>
            <List.Item>Improved search to boost by most recently added candidates and highest likely to move score.</List.Item>
            <List.Item>Split Redis into two clusters, one for regular UI caching and the other one for Celery scheduling.</List.Item>
            <List.Item>Added read only Postgres database instance to overcome UI responsiveness challenges while scraping candidates.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>02/07/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fixed admin analytics data set building. Broke down urls by providers.</List.Item>
            <List.Item>Change wording from Expert Mode to AI Fine Tuning.</List.Item>
            <List.Item>Show tooltip when email Generate button is disabled.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>02/06/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Implemented Expert Mode for campaign management.</List.Item>
            <List.Item>Support to copy sample email as example email.</List.Item>
            <List.Item>Fine tuned email prompt.</List.Item>
            <List.Item>Email editor resizes to screen size on enter.</List.Item>
            <List.Item>Improved campaign wording on configuration page.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/30/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fine tuned email generation based on feedback and additional experiements.</List.Item>
            <List.Item>Subject example is also supported now and can be included in the example email.</List.Item>
            <List.Item>Campaigns now use the new template campaign strategy.</List.Item>
            <List.Item>Fixed candidate tracker 1969 date issue.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/29/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>First round of new prompts allowing to generate emails for preview.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/28/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Completed redefined drip campaign UI management portion.</List.Item>
          </List>
        </List.Item>
      </List>
      
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/23/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Completed drip campaign support for search and jobs.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/21/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Initial drip campaign dialog as drawer for jobs and search to manage.</List.Item>
            <List.Item>Various display fixes, e.g., make navbar scrollable, define bigger workspace. Moved styles into CSS.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/19/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fix profile page previously leading to blank page result until refreshed again.</List.Item>
            <List.Item>Minor fixes to complete drip campaign flow.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/18/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Implemented drip campaign when using Contact... button.</List.Item>
            <List.Item>Added feature switch to enable/disable cache on the fly.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/16/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fixed responded count jobs.</List.Item>
            <List.Item>Removed new count on jobs but added scheduled and resolved.</List.Item>
            <List.Item>Fixed response delay caused by long cache invalidation call leading to backend restarts due to unhealthy checks.</List.Item>
            <List.Item>Moved away from SQS queue processing to Django Celery for scaling backend candidate acquisitions.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/13/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fix 1969 date on candidate detail page and in engagement tab.</List.Item>
            <List.Item>Remove Indicates Hiring Interest from candidate detail page.</List.Item>
            <List.Item>Removed redundant recommendation name.</List.Item>
            <List.Item>Removed social media and commented resume on file search option for now.</List.Item>
            <List.Item>Rename last social activity to Last Code Contributions.</List.Item>
            <List.Item>Remove contacted candiates from dashboard.</List.Item>
            <List.Item>Rename Code Contributions to Code Repositories to avoid conflicts with Last Code Contributions.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/10/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Show delivery status on email detail page as scheduled or sent with proper dates.</List.Item>
            <List.Item>Suppress contact button on candidate detail page when not permitted to use due to candidate state.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/09/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Introduced Celery and Beat messaging and scheduling framework to allow for application mass scaling.</List.Item>
            <List.Item>Fixed misleading message when email hasn't actually been delivered.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/06/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Search criteria panel can be opened at any time on any tab.</List.Item>
            <List.Item>Various font size changes to enhance UI.</List.Item>
            <List.Item>Fixed more caching updates when creating and editing jobs.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/05/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Replaced Quill Editor with TinyMCE Editor to edit emails and job descriptions.</List.Item>
            <List.Item>Fixed a few caching updates.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/04/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Introduced Redis caching to improve page response times.</List.Item>
            <List.Item>Take newly created custom skills into account as boosted keywords behind the scenes.</List.Item>
            <List.Item>More admin search capabilities to help with feedback analysis and data research.</List.Item>
            <List.Item>Added missing ChatGPT conversation context as display in model.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>01/03/24</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Improved auto-tuner to return when either 10+ results are found or 10 attempts have been made.</List.Item>
            <List.Item>Fixed broken skills search options.</List.Item>
            <List.Item>Removed number of search hits.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/19/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Implemented query auto fine tuner.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/18/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Support for dynamic skills coming from server with option to create new ones 
              that require RightOne approval to get onto the master list. This is in preparation for 
              continuous skill extraction when new skills are discovered. Skills are currently listed by
              occurrence across all candidates.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/16/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>More consistent data change experience throughout app.</List.Item>
            <List.Item>Support for all actions with Candidate anywhere.</List.Item>
          </List>
        </List.Item>
      </List>
      
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/15/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Freeform search now acts exactly like a job. You can like, engage, etc. candidates everywhere in the system now.</List.Item>
            <List.Item>Freeform search can still be used as a template for real jobs and real jobs can now be cloned as an added bonus.</List.Item>
            <List.Item>Major performance overhaul to speed up search and job tab views.</List.Item>
            <List.Item>Fixed comments to show actual comment in text field when editing.</List.Item>
            <List.Item>Fixed navigation from dashboard to candidate view.</List.Item>
            <List.Item>Allow to see email right after it was queued instead of when it was successfully sent. Indicate that fact in email detail page as well.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/14/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Job open/close now supported.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/06/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>User profile image upload is now supported.</List.Item>
          </List>
        </List.Item>
      </List>
      
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>12/05/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Implemented candidate resolution also seen in new job tab.</List.Item>
            <List.Item>Exposed more fine grained role search options for engineering positions.</List.Item>
            <List.Item>Renamed search fields, Specialty to Focus and Departments to Teams.</List.Item>
            <List.Item>Watch feature allows users to add/remove candidates from a watchlist. Currently, there is nothing
              happening on the backend other than tracking the user desire. Candidates can be watched/unwatched from their detail page. The
              button acts as a toggle. To see watched candidates go to candidates view and select watched checkbox. Note, that any candidate,
              irrespective of being linked to a job can be watched. Watches are private to the user only.</List.Item>
            <List.Item>Like/unlike can now be chosen on candidate detail page.</List.Item>
            <List.Item>User can write and edit private comments to any candidate on their detail page.</List.Item>
            <List.Item>Remapped job lists to follow newly discused rules. Candidates can only be in one department. When multiple departmentes are
              identified, a priority list of departments will decide where the candidate goes. Leadership is first on the list with engineering last.
              Additional information fron speciality and roles are used to determine department association. Added additional departments, essentially
              splitting the single Research and Development department into more fine grained specialty departments.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/30/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Introduced coding experience to overcome misleading work experience. For now coding experience reflects Github account registration
              and work experience is the end of someone's education date.</List.Item>
            <List.Item>Switched from multiple current roles to single current role. It will be populated from either ChatGPT as part of an extraction from
              the Github summary or last LinkedIn job title (preferred). This is necessary preparation work to improve job title taxonomy work.</List.Item>
            <List.Item>Exposed available awards available from LinkedIn data as well non-code publications.</List.Item>
            <List.Item>Fixed misleading date rendering issues across various history items on candidate detail page.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/29/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fixed calendar view to show all Calendly scheduled candidates except when they canceled.</List.Item>
            <List.Item>Dashboard panels (open by default) and search panel (closed by default) are now collapsable.</List.Item>
            <List.Item>Now search result count is updated with actual true hits instead of showing 0 when candidates are present.</List.Item>
            <List.Item>Creating a new job from search now carries over search criteria.</List.Item>
            <List.Item>Removed job review page and directly navigate to job search showing candidates with a collapsed search option panel.</List.Item>
            <List.Item>Support for custom cities and skills that now are kept. 
              These are currently not available as options in other jobs and stay 
              custom for the job where they are added.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/28/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Exposed over 500 technical skills to choose from.</List.Item>
            <List.Item>Render actual state name instead of ISO code.</List.Item>
            <List.Item>Hide contact button in non job related search.</List.Item>
            <List.Item>Support custom city input in search.</List.Item>
            <List.Item>Stabilized and improved url based job creation.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/24/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Implemented skills mapping to collect all available skills across all available candidate text from roles, 
              summary, code contributions (Github), and work history details (LinkedIn).</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/23/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Fixed search which broke as part of the previous release.</List.Item>
            <List.Item>As part of the job title work, slightly redesigned and optimized candidate cards and candidate detail page.</List.Item>
            <List.Item>Added Github Star link if applicable as first item in Social Profile list.</List.Item>
            <List.Item>Cleaned up fake profile data under Insights and populate true information.</List.Item>
          </List>
        </List.Item>
      </List>
      
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/22/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Released first cut of the title search work.</List.Item>
          </List>
        </List.Item>
      </List>
      
      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/16/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Add Calendar to show all scheduled candidates.</List.Item>
            <List.Item>Fixed candidate access on dashboard to allow rendering outreach and meeting schedules.</List.Item>
            <List.Item>Exclude draft status from Contacted Candidates on dashboard.</List.Item>
            <List.Item>Fixed navigation to job candidate anchor.</List.Item>
            <List.Item>Minor cosmetic fix on scheduling page to separate change reason from status.</List.Item>
            <List.Item>Switched to ChatGPT functions for job descriptions and email generations to improve data consistency and quality.</List.Item>
            <List.Item>Added education degree to job descsription as part of ChatGPT function calling experiementation.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/15/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Completed Calendly integration. Testing mode is now fully functional and we also track and show past schedules.</List.Item>
            <List.Item>Meetings can be clicked to route user to Calendly scheduling page for potential further action within Calendly, e.g.,
              to set no-show, or manually cancel/reschedule. Any action from Calendly will be sync'd and reflected in application.</List.Item>
            <List.Item>Calendly updates are sync'd in 2 ways: Either through webhooks in real-time if customer uses Standard Calendly license
               or alternatively whenever customer logs in to the platform.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/14/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Added Calendly schedule info details to candidate detail page and scheduled tab in job.</List.Item>
            <List.Item>Fixed strange disappearance of tab data.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/13/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Completed first round trip of Calendly integration work. Calendly can now be linked to an account and
            used to have candidates schedule within the application. A <i>Standard</i> Calendly subscription is required to
            benefit from webhook callbacks. The dashboard lists scheduled candidates in a separate list. Jobs have a new
            <i>Scheduled</i> tab for candidates scheduled through Calendly.</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>
        <List.Item><em>11/09/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Started Calendly integration work</List.Item>
          </List>
        </List.Item>
      </List>

      <List bordered={true} style={{marginBottom: '20px'}}>      
      <List.Item><em>11/08/23</em><br/>
          <List style={{marginLeft: '50px', fontSize: '12px'}}>
            <List.Item>Added release notes page for superusers to see. This page.</List.Item>
            <List.Item>Fixed numerous issues now allowing to fully process all the way from Github scrape to LinkedIn profile update.</List.Item>
            <List.Item>Fixed issues preventing rendering of some candidate detail pages</List.Item>
            <List.Item>Support for newly added search cities/locations</List.Item>
            <List.Item>Started ongoing LinkedIn profile processing, leading to more new profiles over time</List.Item>
            <List.Item>Track candidate when liked or engaged with to see in candidate list as first afterwards</List.Item>
            <List.Item>Ran likely to move model now searchable. High scores should exclude founders and CEOs</List.Item>
            <List.Item>Fixed consistency issue with 2 jobs, same criteria, but different candidates. It was an order problem 
            that is addressed. Candidates now come back in the order of highest search match first.</List.Item>
            <List.Item>Support for backup and placeholder image. If active image does not exist, fall back to other alternatives
            or if not available, use RightOne placeholder (currently a green moonwalker)</List.Item>
            <List.Item>Fixed issue of blank page when zero results came back</List.Item>
          </List>
        </List.Item>
      </List>



      </>);
};

export default AdminSystemContent;
