import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from "utils/axios";
import { useToaster, Loader } from 'rsuite';
import { RootState } from "store";
import { useSelector } from "react-redux";

import { getRequest } from 'utils/axios';
import RequestErrorMessage from 'components/toasts/RequestErrorMessage';
import { Business } from 'types';


const BillingContent = () => {
  const toaster = useToaster();
  const [productSubscriptionName, setProductSubscriptionName] = useState<any>(null);  
  const [hasBillingPortal, setHasBillingPortal] = useState(true);  
  const [billingPortalUrl, setBillingPortalUrl] = useState<any>(null);  
  const user = useSelector((state: RootState) => state.auth.user);

  const { data, error, mutate } = useSWR<any>(`/businesses/${user?.business_id}/`, fetcher)  
  const business: Business = data

  useEffect(() => {
    if (business == null) return;
      setProductSubscriptionName(business.product_subscription_display_name)

    if (billingPortalUrl != null) return;

    getRequest("integrations/stripe/billings/").then((res) => {
      if (res.status === 200) {
        setBillingPortalUrl(res.data.billing_portal_url)
      } else {
        setHasBillingPortal(false)
      }
    }).catch((error) => {
      toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })
  })

  if (!business)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching billing details..."/>
      </div>
    );  

  return (
    <div>
      {productSubscriptionName ?
        <p>Your business is currently on the <strong>{productSubscriptionName}</strong> subscription plan.</p>
        :
        <p>The business does not have a subscription plan.</p>
      }
      {hasBillingPortal ?
        <p>Manage your subscription in the <a href={billingPortalUrl} >Stripe Billing Portal.</a></p>
        :
        <p>To upgrade your plan, click <strong>Upgrade Now</strong> in the header above.</p>
      }
    </div>
  );
};

export default BillingContent;
