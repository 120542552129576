import React, { useRef, useState } from 'react';
import { Form, Schema, Stack, Divider, useToaster, Loader } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { VscLock, VscWorkspaceTrusted } from 'react-icons/vsc';
import RadioTile from 'components/elements/RadioTile';
import FormHeader from 'components/elements/FormHeader';
import { postRequest } from "utils/axios";

import BundledEditor from 'BundledEditor.js'

import { JobRequest } from 'utils/types'
import WizardButtonGroup from 'pages/protected/jobs/wizard/WizardButtonGroup';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'

type Props = {
  jobRequest: JobRequest
  setJobRequest: (job: JobRequest) => void
  numberOfSteps: number
  currentStep: number
  setNextStep: (step: number) => void
};

const JobCutAndPasteImportForm = ({ jobRequest, setJobRequest, numberOfSteps, currentStep, setNextStep } : Props) => {
  const [visibility, setVisibility] = useState(jobRequest.visibility)
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    description: jobRequest.description
  })

  const toaster = useToaster();
  const editorRef: any = useRef();

  const { StringType } = Schema.Types;
  const model = Schema.Model({ 
    description: StringType() 
        .minLength(2000, 'The description needs to have at least 2000 characters')
  })

  const formRef: any = useRef()
  const handleSubmit = (event: any) => {
    handleDescription()

    if (formRef.current.check())  {
      setLoading(true);
      const jobPromise = postRequest(`/jobs/?evaluate_description=text`, jobRequest)    
      jobPromise.then((response) => {
        updateFields(response.data)
        setNextStep(Math.min(currentStep + 1, 2))
      }).catch((error) => {
        toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
      }).finally(() => {
        setLoading(false);
      })
    } else {
      const message = JSON.parse(JSON.stringify(formError))?.description
      toaster.push( <RequestErrorMessage error={message} toaster={toaster}/> )
    }
  }

  function updateFields(response: any) {
    jobRequest.id = response.id
    jobRequest.name = response.name
    jobRequest.role = response.role
    setJobRequest(jobRequest)
  }

  const handleDescription = () => {
    setFormValue({ ...formValue,
      description: editorRef.current.getContent()
    })

    jobRequest.description = editorRef.current.getContent()
    setJobRequest(jobRequest)    
  }

  const handleVisibility = (value: any) => {
    setVisibility(value)
    jobRequest.visibility = value
    setJobRequest(jobRequest)
  }

  return (<>
    <Form 
        ref={formRef}
        onCheck={setFormError}
        formValue={formValue}
        model={model}
        onSubmit={(data: any) => handleSubmit(data)}
        fluid>
      <FormHeader
        title="Importing through cut and paste from external website or document"
        description="We will attempt to extract all relevant information from the description provided."
      />
      <Form.Group controlId="description">
        <Form.ControlLabel>Job Description</Form.ControlLabel>
        <BundledEditor
                    onInit={(evt: any, editor: any) => editorRef.current = editor}
                    initialValue={formValue.description}
                    init={{
                      promotion: false,
                      branding: false,
                      height: 1000,
                      menubar: false,
                      plugins: [
                        'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                        'searchreplace', 'table', 'wordcount', 'preview'
                      ],
                      toolbar: 'undo redo | preview | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                    />  
        <Form.HelpText>
          Detailed information about this job. Make sure it contains job name, job title, and sufficient information about the role.
        </Form.HelpText>        
      </Form.Group> 

      <Form.Group controlId="visibility">
        <Form.ControlLabel>Visibility Level</Form.ControlLabel>
        <Stack spacing={10} direction="row" alignItems={'stretch'}>
          <RadioTile
            icon={<Icon as={VscLock} />}
            title="User"
            value={visibility}
            name="user"
            onChange={handleVisibility}
          >
            Only you will be able to work on this job.
          </RadioTile>
          <RadioTile
            icon={<Icon as={VscWorkspaceTrusted} />}
            title="Business"
            value={visibility}
            name="business"
            onChange={handleVisibility}
          >
            This job will be accessible to everyone in your business.
          </RadioTile>
        </Stack>
      </Form.Group>

      <Divider />
      {loading && <div className='text-center m-3'>
        <Loader size="lg" content="Crafting your job search..."/>
      </div>}

      <WizardButtonGroup nextStepLabel='Build' numberOfSteps={numberOfSteps} currentStep={currentStep} setNextStep={setNextStep} />
    </Form>
</>);
};

export default JobCutAndPasteImportForm;
