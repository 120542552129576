import React, { useState, useEffect } from 'react';
import useSWRMutation from 'swr/mutation'
import { fetcher } from "utils/axios";
import { useNavigate } from "react-router";

import { patchRequest } from "utils/axios";

import { JobListResponse , JobResponse } from "utils/types";

import {
  Input, InputGroup, Table, Checkbox, Stack, SelectPicker, CheckPicker, Avatar, Loader, Popover, Dropdown, Whisper, IconButton, useToaster, CellProps as TableCellProps
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from '@rsuite/icons/legacy/More';
import { CapitalizedStringCell, NumberCell } from 'components/utils/Cells';
import { sortJsonObject } from 'utils/utils';

import RequestErrorMessage from 'components/toasts/RequestErrorMessage'
import RequestSuccessMessage from 'components/toasts/RequestSuccessMessage'

const { Column, HeaderCell, Cell } = Table;

const visibilityOptions = [
  {
    value: 'user',
    label: 'User'
  },
  {
    value: 'business',
    label: 'Business'
  }

]

const statusOptions = [
  {
    value: 'open',
    label: 'Open'
  },
  {
    value: 'closed',
    label: 'Closed'
  }

]

type Props = {
  showMenu?: boolean
  enableScroll?: boolean  
  filter?: string  
  jobCounts: { filtered: number, total: number }
  setJobCounts: ( jobCounts: any  ) => void
}

function JobList( { showMenu, enableScroll, filter, jobCounts, setJobCounts } : Props ) {
  if (enableScroll === undefined) {
    enableScroll = true
  }  
  if (showMenu === undefined) {
    showMenu = true
  }  
  var defaultJobFilter = '&status=open'
  if (filter === undefined) {
    filter = ''
    defaultJobFilter = '?status=open'
  }
  const navigate = useNavigate();

  const [jobFilter, setJobFilter] = useState(filter + defaultJobFilter);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [visibilityFilter, setVisibilityFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState(['open']);
  const [criteraChanged, setCriteraChanged] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { data: jobListResponse, trigger } = useSWRMutation<JobListResponse>(`/jobs/${jobFilter}`, fetcher)

  useEffect(() => {
    if (jobListResponse === undefined || criteraChanged) {
      setCriteraChanged(false)
      trigger()
    }

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
  })

  if (!jobListResponse)
    return (
      <div className='text-center m-3'>
        <Loader size="md" content="Fetching jobs..."/>
      </div>
    )

  var jobs: JobResponse [] = jobListResponse.results;
  
  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === jobs.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < jobs.length) {
    indeterminate = true;
  }

  function handleClearStatusFilter(value: any) {
    setStatusFilter([])
    if (filter === undefined)
      setJobFilter(filter + "&status=open,closed")
    else
      setJobFilter("?status=open,closed")
    setCriteraChanged(true)
  }

  function handleStatusFilter(value: any) {
    setStatusFilter(value)
    if (value !== null && value.length > 0) {
      if (filter && filter.length > 0)
        setJobFilter(filter + "&status=" + value)
      else
        setJobFilter("?status=" + value)
    } else {
      if (filter && filter.length > 0)
        setJobFilter(filter + "&status=open,closed")
      else
        setJobFilter("?status=open,closed")
    }
    setCriteraChanged(true)
  }

  function handleCheckAll(_value: any, checked: boolean) {
    const keys = checked ? jobs.map(item => item.id) : [];
    setCheckedKeys(keys);
  }

  function handleCheck(value: any, checked: any) {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(keys);
  }

  function handleSortColumn(sortColumn: any, sortType: any) {
    setSortColumn(sortColumn);
    setSortType(sortType);
  }

  const filteredData = () => {
    const filtered = jobs.filter(job => {
      if (!job.name.toLowerCase().includes(searchKeyword.toLowerCase()) && !job.recruiter.full_name.toLowerCase().includes(searchKeyword.toLowerCase())) {
        return false;
      }

      if (visibilityFilter && job.visibility !== visibilityFilter) {
        return false;
      }

      if (statusFilter.length > 0 && !statusFilter.includes(job.status)) {
        return false;
      }

      return true;
    });

    if (filtered.length != jobCounts.filtered) {
      setJobCounts( { filtered: filtered.length, total: jobs.length } )
    }

    return sortJsonObject(filtered, sortColumn, sortType)
  }

  return (
    <>
      {showMenu && (
        <Stack justifyContent="flex-start" spacing={10} style={{paddingBottom: '10px'}}>
          <SelectPicker style={{width: '165px'}}
            label="Visibility"
            data={visibilityOptions}
            searchable={false}
            value={visibilityFilter}
            onChange={setVisibilityFilter}
          />
          <CheckPicker style={{width: '180px'}}
            countable={false}
            label="Status"
            data={statusOptions}
            defaultValue={statusFilter}
            searchable={false}
            onSelect={handleStatusFilter}
            onClean={handleClearStatusFilter}
          />
          <InputGroup inside>
            <Input placeholder="Search" value={searchKeyword} onChange={setSearchKeyword} />
            <InputGroup.Addon>
              <SearchIcon />
            </InputGroup.Addon>
          </InputGroup>
        </Stack>
      )}

      <Table 
        wordWrap="break-word"
        height={windowHeight - 300}
        data={filteredData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        bordered
        autoHeight={!enableScroll}
        rowHeight={45}
        style={{fontSize: '14px'}}        
      >

        <Column width={50} fixed>
          <HeaderCell style={{ padding: 0 }}>
              <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
          </HeaderCell>
          <Cell dataKey="id" style={{ padding: 0 }}>{(rowData: any) => (
             <Checkbox
              value={rowData.id}
              inline
              onChange={handleCheck}
              checked={checkedKeys.some(item => item === rowData.id)}
              style={{height: '10px'}}
              />
          )}
          </Cell>
        </Column>

        <Column minWidth={300} flexGrow={4} sortable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name">{(rowData: any) => { return (
            <div  onClick={() => {navigate('/jobs/' + rowData.id)}}>
              {rowData.name}
            </div>
            )}}
          </Cell>
        </Column>

        <Column minWidth={120} sortable flexGrow={1}>
          <HeaderCell>Recruiter</HeaderCell>
          <Cell dataKey="recruiter.full_name">{(rowData: any, rowIndex: any) => `${rowData.recruiter.full_name}`}</Cell>
        </Column>

        <Column width={50} align="center">
          <HeaderCell> </HeaderCell>
          <Cell dataKey='image'>{(rowData: any) => (
          <div style={{height: '10px'}}>
            <Avatar style={{height: '35px', width: '35px', marginTop: '-6px'}} circle src={rowData.recruiter.image && rowData.recruiter.image.startsWith('http') ? rowData.recruiter.image : process.env.REACT_APP_ROOT_URL + rowData.recruiter.image}/>
          </div>
            )}
          </Cell>
        </Column>  

        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>Visibility</HeaderCell>
          <CapitalizedStringCell dataKey="visibility" />
        </Column>

        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>Days Open</HeaderCell>
          <NumberCell dataKey="days_open"/>
        </Column>

        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>Screened</HeaderCell>
          <NumberCell dataKey="screened" />
        </Column>       

        <Column minWidth={100} sortable flexGrow={1}>
          <HeaderCell>Contacted</HeaderCell>
          <NumberCell dataKey="contacted" />
        </Column>       

        <Column minWidth={110} sortable flexGrow={1}>
          <HeaderCell>Responded</HeaderCell>
          <NumberCell dataKey="responded" />
        </Column>              

        <Column minWidth={110} sortable flexGrow={1}>
          <HeaderCell>Scheduled</HeaderCell>
          <NumberCell dataKey="scheduled" />
        </Column> 

        <Column minWidth={110} sortable flexGrow={1}>
          <HeaderCell>Resolved</HeaderCell>
          <NumberCell dataKey="resolved" />
        </Column> 

        <Column minWidth={80} sortable flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <CapitalizedStringCell dataKey="status" />
        </Column>

        <Column minWidth={50}>
          <HeaderCell>
            <MoreIcon />
          </HeaderCell>
          <ActionCell dataKey="id" refresh={() => setCriteraChanged(true)} />
        </Column>
      </Table>
    </>
  );
};

export default JobList;

interface ActionProps<T = any> extends Omit<TableCellProps<any>, 'rowData' | 'dataKey'> {
  dataKey?: string | keyof T;
  rowData?: T;
  refresh: () => void  
}

const ActionCell = ({ rowData, dataKey, refresh, ...props }: ActionProps) => {
  const navigate = useNavigate();
  const toaster = useToaster();  

  function changeJobStatus(status: any, jobId: any) {
    const jobUpdateRequest = {
      status: status
    };

    let text_status: any = null
    if (status === 'closed')
      text_status = 'closed'
    else
      text_status = 'opened'

    const patchPromise = patchRequest(`/jobs/${jobId}/`, jobUpdateRequest)      
    patchPromise.then((response) => {
      toaster.push( <RequestSuccessMessage message={'Job successfully ' + text_status} toaster={toaster}/> )
      refresh()
    }).catch((error) => {
      toaster.push( <RequestErrorMessage error={error} toaster={toaster}/> )
    })
  }      

  function handleSelect(eventKey: any) {
    switch (eventKey) {
      case 'manage':
        navigate('/jobs/' + rowData.id);
        break;
      case 'edit':
        navigate('/jobs/' + rowData.id + '/edit');
        break;
      case 'close':
        changeJobStatus('closed', rowData.id);
        break;
      case 'open':
        changeJobStatus('open', rowData.id);
        break;
      }
  };
    
  return (
    <Cell {...props} className="link-group">
      <div style={{height: '10px'}}>
      <Whisper placement="autoVerticalEnd" trigger="click" speaker={
            <Popover full>
            <Dropdown.Menu onSelect={handleSelect}>
              <Dropdown.Item eventKey='manage'>Manage</Dropdown.Item>
              <Dropdown.Item eventKey='edit'>Edit</Dropdown.Item>
              {rowData.status === 'open' && <Dropdown.Item eventKey='close'>Close</Dropdown.Item>}
              {rowData.status === 'closed' && <Dropdown.Item eventKey='open'>Open</Dropdown.Item>}
            </Dropdown.Menu>
          </Popover>
      }>
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
      </div>
    </Cell>
  );
};

