import React, { useState, useEffect } from 'react';
import { getRequest } from "utils/axios";
import {
  Drawer,
  Grid,
  Row,
  Col,
  Divider,
  Nav,
  IconButton,
  SelectPicker,
  Stack,
  Button,
  Avatar,
  Loader,
  useToaster,
  Modal
} from 'rsuite';
import { MdMic } from 'react-icons/md'; // Assuming you're using react-icons for icons
import PlayIcon from '@rsuite/icons/legacy/PlayCircle';
import AvatarVideoUploadModal from 'components/video/AvatarVideoUploadModal'; // Import the new component
import { FaPlus } from 'react-icons/fa'; // Import the icon from react-icons
import AvatarPlaceholderImage from 'assets/images/avata-placeholder.png';

export type Character = {
  id: string;
  provider_id: string;
  preview_url: string;
  name: string;
  gender: string;
  quality: string;
};

export type Voice = {
  id: string;
  provider_id: string;
  preview_url: string;
  name: string;
  gender: string;
  accent: string; 
  quality: string;
};

export type VideoMessageTemplate = {
  id: string;
  name: string;
  thumbnail_url: string;
  video_preview_url: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  setCharacterUrl: (url: string) => void;
  setTemplateId: (id: string | null) => void;
  setCharacterId: (id: string | null) => void;
  setVoiceId: (id: string | null) => void;
  characterId: string | null;
  templateId: string | null;
  voiceId: string | null;
};

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

const AvatarBuilderDrawerView = ({ open, onClose, setCharacterUrl, setTemplateId, setCharacterId, setVoiceId, characterId, templateId, voiceId }: Props) => {
  const toaster = useToaster();
  
  const [activeKey, setActiveKey] = useState('character');
  const [filter, setFilter] = useState<any>('all');
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [avatarTrainingInProgress, setAvatarTrainingInProgress] = useState(false);
  const [createAvatarModalOpen, setCreateAvatarModalOpen] = useState(false);
  const [avatarLimitReached, setAvatarLimitReached] = useState(true);
  
  const [characters, setCharacters] = useState<any>();
  const [voices, setVoices] = useState<any>();
  const [templates, setTemplates] = useState<any>();

  useEffect(() => {
    if (!templates) {
      getRequest(`/avatars/video-message-templates/`).then((res) => {
        setTemplates(res.data);
        setTemplateId(templateId);
      });
    }
    if (!characters) {
      getRequest(`/avatars/characters/`).then((res) => {
        setCharacters(res.data);
        updateCharacter(characterId, res.data);
      });
      refreshVideoUploadStatus();
    } 
    if (!voices) {
      getRequest(`/avatars/voices/`).then((res) => {
        setVoices(res.data);
        updateVoice(voiceId, res.data);
      });
    }
  }, [characterId, voiceId]);

  function refreshVideoUploadStatus() {
    getRequest(`/avatars/video-uploads/`).then((res) => {
      if (res.data.training_in_progress !== undefined) {
        setAvatarTrainingInProgress(res.data.training_in_progress);
      }
      if (res.data.avatar_limit_reached !== undefined) {
        setAvatarLimitReached(res.data.avatar_limit_reached);
      }
    });
  }

  const filterCharacters = (characters: Character[]) => {
    return characters.filter(character => (filter === 'all' || character.gender === filter));
  };

  const filterVoices = (voices: Voice[]) => {
    return voices.filter(voice => (filter === 'all' || voice.gender === filter));
  };

  const handleCreateCharacter = () => {
    setCreateAvatarModalOpen(true);
  };
  
  const handleMouseEnter = (event: React.MouseEvent<HTMLVideoElement>) => {
    const video = event.currentTarget;
    video.loop = true;
    video.play();
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLVideoElement>) => {
    const video = event.currentTarget;
    video.loop = false;
    video.pause();
    video.currentTime = 0; // Reset the video to the beginning
  };

  const playVideo = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    setVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setVideoModalOpen(false);
    setVideoUrl(null);
  };

  const closeCreateAvatarModal = () => {
    setCreateAvatarModalOpen(false);
  };

  const playVoice = (id: any) => {
    if (voices) {
      const audio = new Audio(`${voices.find((voice: any) => voice.provider_id === id)?.preview_url}`);
      audio.play();
    }
  };

  const updateCharacter = (id: any, characters: any) => {
      setCharacterId(id ? `${characters.find((character: any) => character.provider_id === id)?.provider_id}` : '');
      setCharacterUrl(id ? `${characters.find((character: any) => character.provider_id === id)?.preview_url}` : '');
  };

  const handleChooseCharacter = (id: any) => {
    updateCharacter(id, characters);
  };

  const updateVoice = (id: any, voices: any) => {
    setVoiceId(id ? `${voices.find((voice: any) => voice.provider_id === id)?.provider_id}` : '');
  };

  const handleChooseVoice = (id: any) => {
    updateVoice(id, voices);
  };

  const handleTemplate = (id: any) => {
    setTemplateId(id);
  };

  const handleDeselectAvatar = () => {
    setCharacterId(null);
    setVoiceId(null);
    setTemplateId(null);
    setCharacterUrl('');
  };

  return (
    <>
      <Drawer backdrop={true} size="lg" placement="right" open={open} onClose={onClose}>
        <Drawer.Header>
          <Drawer.Title><h3>Avatars</h3></Drawer.Title>
        </Drawer.Header>

        <Drawer.Body>
          <span>Your avatar will be used to generate personalized video messages to candidates. Choose an avatar below, or click <strong>Create Avatar</strong> to create your own custom avatar.</span>
          <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <Nav appearance="tabs" activeKey={activeKey} onSelect={setActiveKey}>
              <Stack spacing={10} direction='row' justifyContent='space-between'>
                <Stack.Item>
                  <Stack spacing={10} direction='row' alignItems='flex-start' justifyContent='space-between'>
                    <Stack.Item>
                      <Nav.Item eventKey="character">Characters</Nav.Item>
                    </Stack.Item>
                    <Stack.Item>
                      <Nav.Item eventKey="voice">Voices</Nav.Item>
                    </Stack.Item>
                    <Stack.Item>
                      <Nav.Item eventKey="templates">Layout</Nav.Item>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <Button appearance="primary" onClick={handleDeselectAvatar} style={{marginRight: "10px"}}>Deselect Avatar</Button>
                  <SelectPicker
                    data={filterOptions}
                    value={filter}
                    searchable={false}
                    onChange={value => setFilter(value)}
                    cleanable={false}
                    style={{ margin: '20px 0', width: 100, border: '1px solid #ccc', borderRadius: '7px' }}/>
                </Stack.Item>
              </Stack>
            </Nav>
          </div>

          {(characters == undefined || voices == undefined || templates == undefined) ?   
            <div className='text-center m-3'>
              <Loader size="md" content="Fetching configuration options..."/>
            </div>
            :
          <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '70vh', marginTop: '20px' }}>
            {activeKey === 'character' && (
              <>
                <h5>Select a Character</h5>
                <Grid fluid>
                  <Row gutter={16} style={{ margin: "10px" }}>
                    <Col key="custom_avatar" xs={6}>
                      <Avatar
                        size="lg"
                        style={{
                          marginTop: '10px',
                          height: '150px',
                          width: '150px',
                          boxShadow: '0 0 3px rgba(0,0,0,0.4)',
                          overflow: 'hidden'  // Ensure video is clipped to the character shape
                        }}
                        onClick={() => !avatarTrainingInProgress && handleCreateCharacter()}
                      >
                        <img
                          src={AvatarPlaceholderImage} // Replace with your image URL
                          alt="Avatar"
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover'  // Ensure the image covers the entire avatar
                          }}
                        />
                        {!avatarTrainingInProgress && (
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)', // Center the icon
                            color: '#fff', // Icon color
                            fontSize: '6px', // Icon size
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add a background color with transparency
                            borderRadius: '50%', // Optional: Make it circular
                            padding: '10px' // Optional: Add some padding
                          }}>
                            <FaPlus style={{width: "10px", height: "10px"}} />
                          </div>
                        )}
                      </Avatar>
                      <div style={{marginTop: "5px", alignContent: "center"}}>
                        {avatarTrainingInProgress ? 'Training in Progress...' : 'Create Avatar'}
                      </div>
                    </Col>

                    {filterCharacters(characters).map(character => (
                      <Col key={character.provider_id} xs={6}>
                      <Avatar
                        size="lg"
                        style={{
                          marginTop: '10px',
                          boxShadow: characterId === character.provider_id ? '0 0 15px rgba(0,0,0,0.6)' :  '0 0 0px rgba(0,0,0,0.0)',
                          backgroundColor: 'white',
                          height: '150px',
                          width: '150px',
                          overflow: 'hidden'  // Ensure video is clipped to the character shape
                        }}
                        onClick={() => handleChooseCharacter(character.provider_id)}
                      >
                          <video
                            width="150px"
                            height="150px"
                            autoPlay
                            loop
                            muted
                            style={{
                              display: 'block',
                              objectFit: 'cover'  // Ensure video covers the entire character
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <source src={`${character.preview_url}`} type="video/webm" />
                            Your browser does not support the video tag.
                          </video>
                        </Avatar>
                        <div style={{marginTop: "5px", alignContent: "center"}}>{character.name}</div>
                      </Col>
                    ))}
                  </Row>
                </Grid>
              </>
            )}

            {activeKey === 'voice' && (
              <>
                <h5>Select a Voice</h5>
                <Grid fluid>
                  <Row gutter={16} style={{ margin: 0 }}>
                    {filterVoices(voices).map(voice => (
                      <Col key={voice.provider_id} xs={6}>
                        <div
                          onClick={() => handleChooseVoice(voice.provider_id)}
                          style={{
                            backgroundColor: voiceId === voice.provider_id ? 'rgba(173, 216, 230, 0.3)' : 'transparent',
                            padding: '10px',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            textAlign: 'center'
                          }}
                        >
                          <Stack direction="row" alignItems="center" spacing={5}>
                            <IconButton icon={<MdMic/>} onClick={() => playVoice(voice.provider_id)}/>
                            {voice.accent === 'american' && <div>🇺🇸</div>}
                            {voice.accent === 'british' && <div>🇬🇧</div>}
                            {voice.accent === 'unspecified' && <div>Unspecified</div>}
                            <div style={{ marginTop: 5 }}>{voice.name}</div>
                          </Stack>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Grid>
              </>
            )}

            {activeKey === 'templates' && (
              <>
                <h5>Select a Layout</h5>
                <Grid fluid>
                  <Row gutter={16} style={{ margin: "10px" }}>
                    {templates && templates.map((template: any) => (
                      <Col key={template.id} xs={6}>
                      <Avatar
                        size="lg"
                        style={{
                          boxShadow: templateId === template.id ? '0 0 15px rgba(0,0,0,0.6)' :  '0 0 0px rgba(0,0,0,0.0)',
                          backgroundColor: 'white',
                          height: '150px',
                          width: '150px',
                          overflow: 'hidden'  // Ensure video is clipped to the character shape
                        }}
                        onClick={() => handleTemplate(template.id)}
                      >
                          <video
                            width="150px"
                            height="150px"
                            autoPlay
                            loop
                            muted
                            style={{
                              display: 'block',
                              objectFit: 'cover'  // Ensure video covers the entire character
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <source src={`${template.video_preview_url}`} type="video/webm" />
                            Your browser does not support the video tag.
                          </video>
                          <IconButton
                            size="lg"
                            icon={<PlayIcon style={{ fontSize: '24px', color: 'darkgrey' }}/>}
                            appearance="ghost"
                            style={{ boxShadow: 'none', outline: 'none', border: '0px transparent', background: "transparent", position: 'absolute', bottom: '0px', right: '0px', height: '40px', width: '40px', cursor: 'pointer' }}
                            onClick={() => playVideo(template.video_preview_url)}
                          />
                        </Avatar>
                        <div style={{marginTop: "10px", alignContent: "center"}}>{template.name}</div>
                      </Col>
                    ))}
                  </Row>
                </Grid>
              </>
            )}          
          </div>
          }
          <Divider />
        </Drawer.Body>
      </Drawer>

      <Modal open={videoModalOpen} onClose={closeVideoModal} size="lg">
        <Modal.Header>
          <Modal.Title>Video Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videoUrl && (
            <video
              width="100%"
              controls
              autoPlay
              style={{ display: 'block', maxHeight: '70vh', margin: '0 auto' }}
            >
              <source src={videoUrl} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeVideoModal} appearance="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>      

      <AvatarVideoUploadModal 
        open={createAvatarModalOpen} 
        onClose={closeCreateAvatarModal} 
        refreshVideoUploadStatus={refreshVideoUploadStatus} 
        avatarLimitReached={avatarLimitReached} />
    </>
  );
};

export default AvatarBuilderDrawerView;
